import React from "react";
import { connect } from "react-redux";
import { checkMantisBusinessUserPermission } from "../../utils/businessUtils";
import "../../MantisBusiness/styles/Archivo.css";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    user: state.auth.user,
  };
};

function ArchiveContextMenu(props) {
  const top = props.top;
  const left = props.left;
  const elementType = props.elementType;
  const hasEditPermission = checkMantisBusinessUserPermission(
    props.user,
    elementType === "FOLDER" ? "EDIT_FOLDERS" : "EDIT_FILES"
  );
  const hasDeletePermission = checkMantisBusinessUserPermission(
    props.user,
    elementType === "FOLDER" ? "DELETE_FOLDERS" : "DELETE_FILES"
  );
  const hasMoveFilesPermission = checkMantisBusinessUserPermission(
    props.user,
    "MOVE_FILES"
  );

  return (
    <>
      {!hasEditPermission && !hasDeletePermission && !hasMoveFilesPermission ? (
        <></>
      ) : (
        <div className="archive-context-menu" style={{ top, left: left - 50 }}>
          <div className="archive-context-menu-content">
            {hasEditPermission && (
              <div
                className="archive-context-menu-item edit"
                onClick={() => {
                  props.handleNameChange();
                }}
              >
                <i className="fas fa-edit"></i>
                Editar
              </div>
            )}
            {hasDeletePermission && (
              <div
                className="archive-context-menu-item delete"
                onClick={() => {
                  props.handleDeleteElement();
                }}
              >
                <i className="fas fa-trash"></i>
                Eliminar
              </div>
            )}
            {hasMoveFilesPermission && elementType === "DOCUMENT" && (
              <div
                className="archive-context-menu-item select"
                onClick={() => {
                  props.handleSelectElement();
                }}
              >
                <i className="fas fa-check"></i>
                Seleccionar
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default connect(select)(ArchiveContextMenu);
