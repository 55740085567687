"use client"

import { useState, useEffect } from "react"
import { CheckCircle2, Circle, Loader2 } from "lucide-react"

const steps = [
  "Analizando documento...",
  "Identificando estructura...",
  "Extrayendo metadatos...",
  "Generando conexiones...",
  "Optimizando flujo de trabajo...",
  "Preparando visualización...",
]

const ProgressiveLoader = ({ onComplete, duration = 10000 }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [completedSteps, setCompletedSteps] = useState([])
  const stepDuration = duration / steps.length

  useEffect(() => {
    if (currentStep >= steps.length) {
      // Todos los pasos completados
      setTimeout(() => {
        onComplete && onComplete()
      }, 1000)
      return
    }

    const timer = setTimeout(() => {
      // Marcar el paso actual como completado
      setCompletedSteps((prev) => [...prev, currentStep])

      // Avanzar al siguiente paso
      setCurrentStep((prev) => prev + 1)
    }, stepDuration)

    return () => clearTimeout(timer)
  }, [currentStep, onComplete, stepDuration])

  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-sm p-6 border border-gray-100">
      <h3 className="text-lg font-medium text-gray-800 mb-4 flex items-center">
        <Loader2 className="h-5 w-5 text-orange-500 mr-2 animate-spin" />
        Procesando documento
      </h3>

      <div className="space-y-3">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`flex items-center transition-all duration-500 ${
              index <= currentStep ? "opacity-100" : "opacity-0 h-0 overflow-hidden"
            }`}
          >
            {completedSteps.includes(index) ? (
              <CheckCircle2 className="h-5 w-5 text-green-500 mr-3 animate-in fade-in-0 zoom-in-95" />
            ) : index === currentStep ? (
              <Circle className="h-5 w-5 text-orange-500 mr-3 animate-pulse" />
            ) : (
              <Circle className="h-5 w-5 text-gray-300 mr-3" />
            )}

            <span
              className={`text-sm ${
                completedSteps.includes(index)
                  ? "text-gray-500"
                  : index === currentStep
                    ? "text-gray-800 font-medium"
                    : "text-gray-400"
              }`}
            >
              {step}
            </span>

            {index === currentStep && (
              <div className="ml-auto bg-orange-100 h-1 w-16 rounded-full overflow-hidden">
                <div
                  className="bg-orange-500 h-full animate-pulse"
                  style={{
                    width: `${((Date.now() % stepDuration) / stepDuration) * 100}%`,
                    transition: "width 0.1s linear",
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-4 pt-3 border-t border-gray-100">
        <div className="flex justify-between items-center">
          <span className="text-xs text-gray-500">Progreso total</span>
          <span className="text-xs font-medium text-gray-700">
            {Math.min(Math.round((currentStep / steps.length) * 100), 100)}%
          </span>
        </div>
        <div className="mt-2 bg-gray-100 h-1.5 rounded-full overflow-hidden">
          <div
            className="bg-orange-500 h-full transition-all duration-300 ease-in-out"
            style={{ width: `${(currentStep / steps.length) * 100}%` }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProgressiveLoader

