import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import ChatHistory from "../../Extras/ChatHistory";
import { ArrowLeft, ArrowRight } from "lucide-react";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function HelpPanel(props) {
  const [functionalitiesShown, setFunctionalitiesShown] = useState({
    voiceToText: false,
    imagesToPDF: false,
    documentRedaction: false,
    documentSearch: false,
  });

  return (
    <div
      className="assistant-sidebar-container"
      style={{
        width: props.expanded
          ? props.helpPanelExpandedWidth
          : props.helpPanelCollapsedWidth,
        transition: `width ${props.helpPanelTransitionTime}s`,
      }}
    >
      {!props.expanded ? (
        <>
          <div
            className="help-panel-arrow-container"
            onClick={() => {
              props.setExpanded(true);
            }}
          >
            <ArrowLeft className="help-panel-arrow-left" />
          </div>
        </>
      ) : (
        <>
          {props.expanded && (
            <div
              className="help-panel-arrow-container"
              onClick={() => {
                props.setExpanded(false);
              }}
            >
              <ArrowRight className="help-panel-arrow-right" />
            </div>
          )}
          <ChatHistory />
        </>
      )}
    </div>
  );
}

export default connect(select)(HelpPanel);
