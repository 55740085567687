"use client";
import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {motion, AnimatePresence} from "framer-motion";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Input } from "../../components/ui/input";
import { Skeleton } from "../../components/ui/skeleton";
import {
  FileUp,
  Download,
  Folder,
  RefreshCw,
  Search,
  ArrowLeft,
  X,
  Plus,
  FileText,
  Clock,
  Database,
  ChevronRight,
  Eye,
  Mail,
  Send,
  Phone,
  Edit,
  PlusCircle,
  Filter,
  FilterX,
} from "lucide-react";
import {
  fetchFlows,
  runManualFlow,
  sendEmailReport,
  listenToDocumentProcessing,
  getFlowResultsFilterOptions,
  getPreSignedUrl,
} from "../../../ApiCalls/mantisFlows";
import { connect } from "react-redux";
import {
  getBusinessFlowResults,
  getNewBusinessFlowResults,
  exportFlowResults,
  getAllFilteredBusinessFlowResults,
} from "../../../ApiCalls/mantisDocuments";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Badge } from "../../components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import FlowWhatsappContactsManager from "./FlowWhatsappContactsManager";
import { updateFlowResultFieldByColumnName } from "../../../ApiCalls/mantisBusiness";
import "../../styles/Documents.css";
import { Spinner } from "reactstrap";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function FlowResults(props) {
  const navigate = useNavigate();
  const [flow, setFlow] = useState(props.flow);
  const [flowResults, setFlowResults] = useState(props.flowResults || []);
  const [flowRunsAmount, setFlowRunsAmount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});
  const [activeFilters, setActiveFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [runningWorkflow, setRunningWorkflow] = useState(false);
  const [allColumns, setAllColumns] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [loadingSignedUrl, setLoadingSignedUrl] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showingWhatsappContactsManager, setShowingWhatsappContactsManager] =
    useState(false);
  const [filters, setFilters] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailForm, setEmailForm] = useState({
    toEmails: "",
    subject: "",
    message: "",
    recipientName: "",
  });
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [processingDocuments, setProcessingDocuments] = useState([]);
  const [listeningToProcessing, setListeningToProcessing] = useState(false);
  const [processingData, setProcessingData] = useState(false);
  const [processingRows, setProcessingRows] = useState(new Set());

  //Edit fields
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [currentColumn, setCurrentColumn] = useState("");
  const [updatingField, setUpdatingField] = useState(false);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const pageSizeOptions = [10, 20, 50, 100, 500, 1000];
  const [lastDate, setLastDate] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const t = props.translations[props.language] || props.translations.en;

  const hasRun = useRef(false); // Create a ref to track if the effect has run

  useEffect(() => {
    if (listeningToProcessing) {
      setListeningToProcessing(false);
    }
    const eventSource = getNewBusinessFlowResults(
      props.mantisBusiness._id,
      props.flow._id,
      activeFilters,
      lastDate
    );
    setListeningToProcessing(true);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.success) {
        let newResults = data.flowResults;
        //insert new results at the beginning
        // Apply the same filter logic as the table
        newResults = newResults.filter((result) =>
          result.columns.every(
            ({ name, value }) =>
              !activeFilters[name] ||
              activeFilters[name] === "all" ||
              activeFilters[name] === value
          )
        );

        if (newResults.length > 0) {
          setFlowResults((prevResults) => {
            const updatedResults = [...newResults, ...prevResults];

            // Ensure pagination limit is respected
            return updatedResults.slice(0, resultsPerPage * currentPage);
          });

          setFilteredResults((prevResults) => {
            const updatedResults = [...newResults, ...prevResults];

            // Keep the UI updated
            return updatedResults.slice(0, resultsPerPage * currentPage);
          });

          setLastDate(newResults[0].dateCreated);
          const runs = new Set();
          newResults.forEach((result) => {
            runs.add(result.flowRunId);
          });
          setFlowRunsAmount((prev) => prev + runs.size);
        }
        setIsLoading(false);
      }
    };

    eventSource.onerror = (error) => {
      console.error("Error en la conexión SSE:", error);
      eventSource.close(); // Close the SSE connection on error
    };

    // Clean up: Close SSE connection when component unmounts or filters change
    return () => {
      eventSource.close();
      setListeningToProcessing(false);
    };
  }, [activeFilters, resultsPerPage, currentPage]);

  useEffect(() => {
    if (!hasRun.current) {
      handleGetFilterOptions();
      handleGetInitialResults();
      hasRun.current = true; // Set the flag to true after running
    }
  }, []);

  useEffect(() => {
    setAllColumnsNames(flowResults);
  }, [flowResults]);

  const handleFilterChange = (column, value) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  const handleGetFilterOptions = async () => {
    const optionsRes = await getFlowResultsFilterOptions(
      flow._id,
      props.mantisBusiness._id
    );
    if (!optionsRes.success) return;
    setFilterOptions(optionsRes.filters);
    setLoadingFilters(false);
  };

  const handleGetInitialResults = async () => {
    setIsLoading(true);
    const resultsRes = await getBusinessFlowResults(
      props.mantisBusiness._id,
      flow._id,
      currentPage,
      resultsPerPage,
      activeFilters
    );
    if (!resultsRes.success) {
      setIsLoading(false);
      alert("Error fetching flow results");
      return;
    }
    setFlowResults(resultsRes.flowResults);
    setFilteredResults(resultsRes.flowResults);
    setLastDate(resultsRes.flowResults[0]?.dateCreated);
    setTotalPages(resultsRes.totalPages);
    setFlowRunsAmount(resultsRes.completedRuns);
    setIsLoading(false);
  };

  const fetchFilteredFlowResults = async () => {
    try {
      setIsLoading(true);
      const resultsRes = await getBusinessFlowResults(
        props.mantisBusiness._id,
        flow._id,
        currentPage,
        resultsPerPage,
        activeFilters
      );
      if (!resultsRes.success) {
        setIsLoading(false);
        alert("Error fetching flow results");
        return;
      }
      setFlowResults(resultsRes.flowResults);
      setFilteredResults(resultsRes.flowResults);
      setTotalPages(resultsRes.totalPages);
    } catch (error) {
      alert("Error fetching flow results");
    } finally {
      setIsLoading(false);
    }
  };

  const setAllColumnsNames = (flowResults) => {
    //Get all columns
    const columns = [];
    flowResults.forEach((result) => {
      result.columns.forEach((column) => {
        if (!columns.includes(column.name)) {
          columns.push(column.name);
        }
      });
    });
    setAllColumns(columns);
  };

  useEffect(() => {
    if (isLoading) return;
    fetchFilteredFlowResults();
  }, [activeFilters, currentPage, resultsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeFilters]);

  const handleClearFilters = () => {
    setActiveFilters({});
  };

  /* useEffect(() => {
    if (!flow) return;
    if (listeningToProcessing) return;
    setListeningToProcessing(true);

    const eventSourceHandler = listenToDocumentProcessing(flow._id, {
      onProcessing: (data) => {
        console.log("Recibida notificación:", data);

        if (data.type === "DOCUMENT_PROCESSING") {
          if (data.status === "STARTED" && data.files) {
            // Activar skeleton para cada archivo
            setProcessingData(true);
            const newRows = new Set();
            data.files.forEach((file) => {
              newRows.add(file.name);
              toast.info(`Procesando: ${file.name}`, {
                position: "top-right",
                autoClose: 3000,
                theme: "dark",
              });
            });
            setProcessingRows(newRows);
          }

          if (data.status === "COMPLETED") {
            toast.success(data.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "dark",
            });

            // Esperar un momento antes de limpiar el skeleton
            setTimeout(() => {
              setProcessingData(false);
              setProcessingRows(new Set());
              // Recargar resultados
              props.handleFlowSelect(flow);
            }, 1000);
          }
        }
      },
      onError: (error) => {
        console.error("Error en la conexión SSE:", error);
        setProcessingData(false);
        setProcessingRows(new Set());
      },
    });

    return () => eventSourceHandler.close();
  }, [flow]); */

  const handleDownloadExcel = async () => {
    if (!flow || !flowResults.length) return;
    setIsDownloading(true);

    try {
      // Apply the same filters as the table view
      const filteredResultsRes = await getAllFilteredBusinessFlowResults(
        props.mantisBusiness._id,
        flow._id,
        activeFilters
      );
      if (!filteredResultsRes.success) {
        setIsDownloading(false);
        alert("Error exportando resultados");
        return;
      }
      // Convert filtered data to export format
      const dataToExport = filteredResultsRes.flowResults.map((result) => {
        let currentRow = {};
        currentRow["Fecha"] = new Date(result.dateCreated);
        allColumns.map((column) => {
          currentRow[column] =
            result.columns.find((c) => c.name === column)?.value || "-";
        });

        return currentRow;
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");
      XLSX.writeFile(wb, `${flow.name}_resultados.xlsx`);
    } catch (error) {
      console.error("Error al generar Excel:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleFileUpload = useCallback((event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setShowFilePreview(true);
  }, []);

  const removeFile = useCallback((fileToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  }, []);

  const handleAddMoreFiles = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleCancelUpload = useCallback(() => {
    setSelectedFiles([]);
    setShowFilePreview(false);
  }, []);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRunFlow = useCallback(async () => {
    if (runningWorkflow) return;
    setRunningWorkflow(true);
    let files = [];
    for (const file of selectedFiles) {
      let base64 = await fileToBase64(file);
      files.push({
        filename: file.name,
        mimeType: file.type,
        base64: base64.split(",")[1],
        size: file.size,
      });
    }
    const runRes = await runManualFlow(
      flow._id,
      props.mantisBusiness._id,
      files
    );
    console.log("Run flow response:", runRes);
    if (runRes.success) {
      setSelectedFiles([]);
      setShowFilePreview(false);
      props.handleFlowSelect(flow);
      alert("Flow executed successfully. Check the results in a few momments.");
    } else {
      alert("Error running the flow. Please try again.");
    }
    setRunningWorkflow(false);
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedRecord && selectedRecord.fileUrl && !loadingSignedUrl) {
      handleGetPreSignedUrl(selectedRecord.fileUrl);
    }
  }, [selectedRecord]);

  const handleGetPreSignedUrl = async (url) => {
    if (!selectedRecord.fileUrl.includes("https://")) {
      setLoadingSignedUrl(true);
      const signedUrlRes = await getPreSignedUrl(url);
      if (signedUrlRes.success) {
        setSelectedRecord((prev) => ({ ...prev, fileUrl: signedUrlRes.url }));
      } else {
        alert("Error al obtener el archivo");
      }
      setLoadingSignedUrl(false);
    }
  };

  const handleRowClick = (record) => {
    console.log("1. Registro completo:", record);
    console.log("2. URL del archivo:", record.fileUrl);
    setSelectedRecord(record);
    setShowDetails(true);
  };

  const handleSendEmail = async () => {
    if (!flow || !flowResults.length) return;
    setIsSendingEmail(true);

    try {
      // Filtrar los resultados como antes
      const filteredResultsRes = await getAllFilteredBusinessFlowResults(
        props.mantisBusiness._id,
        flow._id,
        activeFilters
      );
      if (!filteredResultsRes.success) {
        setIsDownloading(false);
        alert("Error exportando resultados");
        return;
      }

      // Preparar los datos para el Excel
      const dataToExport = filteredResultsRes.flowResults.map((result) => {
        let currentRow = {};
        currentRow["Fecha"] = new Date(result.dateCreated);
        allColumns.map((column) => {
          currentRow[column] =
            result.columns.find((c) => c.name === column)?.value || "-";
        });

        return currentRow;
      });
      // Convertir a Excel y obtener base64
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "Resultados");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "base64" });

      // Preparar datos para el envío
      const emailData = {
        toEmails: emailForm.toEmails.split(",").map((email) => email.trim()),
        subject: emailForm.subject,
        message: emailForm.message,
        attachments: [
          {
            mimeType:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            base64: excelBuffer,
            filename: `${flow.name}_resultados.xlsx`,
          },
        ],
      };

      // Obtener los IDs de los registros filtrados
      const recordIds = filteredResults.map((result) => result._id);

      // Enviar correo y actualizar estado
      await sendEmailReport(emailData, flow._id, recordIds);

      setShowEmailModal(false);
      setEmailForm({
        toEmails: "",
        subject: "",
        message: "",
      });

      toast.success("Correo enviado exitosamente");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al enviar el correo");
    } finally {
      setIsSendingEmail(false);
    }
  };

  const ProcessingRowSkeleton = ({ fileName, index }) => (
    <TableRow className="relative">
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-8 animate-pulse">
          {index + 1}
        </div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-48 animate-pulse">
          {fileName}
        </div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <motion.div
          className="h-6 px-3 bg-blue-600/20 rounded-full flex items-center justify-center"
          animate={{
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <span className="text-blue-400 text-sm">Procesando...</span>
        </motion.div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-20 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-16 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <TableCell>
        <div className="h-4 bg-[#2a2a2a] rounded w-24 animate-pulse"></div>
      </TableCell>
      <motion.div
        className="absolute bottom-0 left-0 h-0.5 bg-blue-600"
        initial={{ width: "0%" }}
        animate={{ width: "100%" }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
    </TableRow>
  );

  // Agregar el componente LoadingSkeleton
  const LoadingSkeleton = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {[1, 2, 3].map((index) => (
        <motion.div
          key={index}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="bg-[#1a1a1a] rounded-lg p-4 animate-pulse">
            <div className="flex items-start justify-between mb-3">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-[#2a2a2a] rounded-md">
                  <div className="h-5 w-5 bg-[#3a3a3a] rounded"></div>
                </div>
                <div>
                  <div className="h-4 bg-[#2a2a2a] rounded w-32 mb-2"></div>
                  <div className="h-3 bg-[#2a2a2a] rounded w-48"></div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <div className="flex items-center gap-2">
                <div className="h-4 bg-[#2a2a2a] rounded w-24"></div>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-4 bg-[#2a2a2a] rounded w-32"></div>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );

  const paginatedResults = filteredResults;

  const handleEditClick = (column) => {
    setCurrentValue(column.value);
    setCurrentColumn(column.name);
    setShowEditModal(true);
  };

  const handleEditColumnValue = async () => {
    // Logic to save the updated value
    setUpdatingField(true);
    const res = await updateFlowResultFieldByColumnName(
      props.token,
      selectedRecord._id,
      props.mantisBusiness._id,
      currentColumn,
      currentValue
    );
    if (res.success) {
      // Update the value in the selectedRecord
      const updatedRecord = { ...selectedRecord };
      updatedRecord.columns = updatedRecord.columns.map((column) => {
        if (column.name === currentColumn) {
          column.value = currentValue;
        }
        return column;
      });
      setSelectedRecord(updatedRecord);
      // Update the flowResults
      const updatedResults = flowResults.map((result) => {
        if (result._id === selectedRecord._id) {
          result.columns = updatedRecord.columns;
        }
        return result;
      });
      setFlowResults(updatedResults);
      setShowEditModal(false);
      setCurrentValue("");
      setCurrentColumn("");
    } else {
      alert("Error al actualizar el campo");
    }

    setUpdatingField(false);
  };

  // Actualizar el contador de filtros activos
  useEffect(() => {
    const count = Object.values(activeFilters).filter(
      (value) => value !== "all"
    ).length;
    setFilterCount(count);
  }, [activeFilters]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <ToastContainer />
      <div className="document-content">
        <Card className="document-card">
          <CardHeader className="document-card-header">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="document-header-title">
                  {flow.name}
                </CardTitle>
                {flowRunsAmount > 0 && (
                  <CardDescription className="document-header-description">
                    {flowRunsAmount} ejecuciones completadas
                  </CardDescription>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent className="p-0">
            <div className="document-toolbar">
              <div className="document-actions">
                <div className="document-action-group ml-auto">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setShowFilters(!showFilters)}
                    className="document-button secondary"
                  >
                    <Filter className="h-4 w-4 mr-2" />
                    Filter {filterCount > 0 && `(${filterCount})`}
                  </Button>
                  {Object.keys(activeFilters).length > 0 && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleClearFilters}
                      className="document-button secondary"
                    >
                      <FilterX className="h-4 w-4 mr-2" />
                      Limpiar Filtros
                    </Button>
                  )}
                  {flow.sources.includes("WHATSAPP") && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setShowingWhatsappContactsManager(true)}
                      disabled={isDownloading}
                      className="document-button secondary"
                    >
                      <Phone className="h-4 w-4 mr-2" />
                      Administrar WhatsApp
                    </Button>
                  )}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={handleDownloadExcel}
                    disabled={isDownloading}
                    className="document-button secondary"
                  >
                    {isDownloading ? (
                      <RefreshCw className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                      <Download className="h-4 w-4 mr-2" />
                    )}
                    Exportar Excel
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setShowEmailModal(true)}
                    className="document-button secondary"
                  >
                    <Mail className="h-4 w-4 mr-2" />
                    Enviar Reporte
                  </Button>
                </div>
              </div>
            </div>

            {/* Filtros desplegables con mejor estética */}
            {showFilters && !loadingFilters ? (
              <div className="flow-filters-container">
                <div className="flex flex-wrap gap-4">
                  {Object.entries(filterOptions).map(([column, values]) => (
                    <div key={column} className="flow-filter-item">
                      <label className="flow-filter-label">{column}</label>
                      <select
                        className="flow-filter-select"
                        value={activeFilters[column] || "all"}
                        onChange={(e) =>
                          handleFilterChange(column, e.target.value)
                        }
                      >
                        <option value="all">Todos</option>
                        {values.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>
            ) : loadingFilters && showFilters ? (
              <div className="flex items-center justify-center h-full">
                <Spinner className="master-spinner" />
              </div>
            ) : null}

            {/* Selector de resultados por página */}
            <div className="document-table-container">
              {flowResults.length > 0 && !isLoading ? (
                <div>
                  <div className="flow-filters">
                    <label className="flow-filter-label">
                      Resultados por página:
                    </label>
                    <select
                      value={resultsPerPage}
                      onChange={(e) => {
                        setResultsPerPage(parseInt(e.target.value));
                        setCurrentPage(1);
                      }}
                      className="flow-filter-select"
                    >
                      {pageSizeOptions.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>

                  <table className="document-table">
                    <thead className="document-table-header">
                      <tr>
                        <th className="document-table-cell">#</th>
                        <th className="document-table-cell">Fecha</th>
                        {allColumns.map((column, index) => (
                          <th key={index} className="document-table-cell">
                            {column}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <AnimatePresence>
                        {processingData &&
                          Array.from(processingRows).map((fileName, index) => (
                            <ProcessingRowSkeleton
                              key={fileName}
                              fileName={fileName}
                              index={index}
                            />
                          ))}
                      </AnimatePresence>
                      {paginatedResults.map((result, index) => (
                        <motion.tr
                          key={index}
                          className="document-table-row"
                          onClick={() => handleRowClick(result)}
                        >
                          <td className="document-table-cell">
                            {(currentPage - 1) * resultsPerPage + index + 1}
                          </td>
                          <td className="document-table-cell">
                            {new Date(result.dateCreated).toLocaleString()}
                          </td>
                          {allColumns.map((column, index) => {
                            const value =
                              result.columns.find((c) => c.name === column)
                                ?.value || "-";
                            return (
                              <td key={index} className="document-table-cell">
                                {value.includes("http") ? (
                                  <i
                                    className="fas fa-external-link-alt"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(value);
                                    }}
                                  ></i>
                                ) : (
                                  value
                                )}
                              </td>
                            );
                          })}
                        </motion.tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="document-pagination">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className="document-pagination-button"
                    >
                      Anterior
                    </button>

                    <span className="document-text-primary">
                      Página {currentPage} de {totalPages}
                    </span>

                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                      className="document-pagination-button"
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              ) : isLoading ? (
                <div className="document-skeleton">
                  <LoadingSkeleton />
                </div>
              ) : (
                <div className="p-4 text-center document-text-secondary">
                  {t.noResultsAvailable}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Modales */}
      <Dialog open={showDetails} onOpenChange={setShowDetails}>
        <DialogContent className="flow-modal-content">
          <DialogHeader className="flow-modal-header">
            <DialogTitle className="flow-modal-title">
              Detalles del Proceso
            </DialogTitle>
          </DialogHeader>

          <div className="flex h-[calc(90vh-5rem)] overflow-hidden">
            <div className="flow-details-panel">
              <div className="space-y-6">
                {selectedRecord?.columns?.map((column, index) => (
                  <div
                    key={index}
                    className="floating-card"
                    onClick={() => handleEditClick(column)}
                  >
                    <div>
                      <h3 className="flow-detail-label">{column.name}</h3>
                      <p className="flow-detail-value">{column.value}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flow-pdf-panel">
              {selectedRecord?.fileUrl &&
              selectedRecord?.fileUrl?.includes(".pdf") ? (
                <iframe
                  src={selectedRecord.fileUrl}
                  className="w-full h-full"
                  title="Documento PDF"
                  style={{ border: "none" }}
                />
              ) : selectedRecord?.fileUrl &&
                (selectedRecord?.fileUrl?.includes(".xls") ||
                  selectedRecord?.fileUrl?.includes(".xlsx")) ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/view.aspx?src=${selectedRecord.fileUrl}`}
                  className="w-full h-full"
                  title="Documento Excel"
                  style={{ border: "none" }}
                />
              ) : selectedRecord?.fileUrl &&
                (selectedRecord?.fileUrl?.includes(".jpeg") ||
                  selectedRecord?.fileUrl?.includes(".jpg") ||
                  selectedRecord?.fileUrl?.includes(".png")) ? (
                <img src={selectedRecord.fileUrl} alt="imagen" />
              ) : (
                <div className="flow-pdf-placeholder">
                  <div className="text-center space-y-4">
                    <FileText className="flow-empty-icon" />
                    <p className="flow-empty-text">
                      No hay documento disponible
                    </p>
                    <p className="flow-empty-subtext">URL no encontrada</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Agregar el modal de envío de correo */}
      <Dialog open={showEmailModal} onOpenChange={setShowEmailModal}>
        <DialogContent className="dialog-content email-dialog">
          <DialogHeader>
            <DialogTitle className="dialog-title">
              Enviar Reporte por Correo
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label className="text-sm">Correos (separados por coma)</label>
              <Input
                value={emailForm.toEmails}
                onChange={(e) =>
                  setEmailForm((prev) => ({
                    ...prev,
                    toEmails: e.target.value,
                  }))
                }
                placeholder="correo1@ejemplo.com, correo2@ejemplo.com"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm">Asunto</label>
              <Input
                value={emailForm.subject}
                onChange={(e) =>
                  setEmailForm((prev) => ({ ...prev, subject: e.target.value }))
                }
                placeholder="Asunto del correo"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm">Mensaje</label>
              <textarea
                value={emailForm.message}
                onChange={(e) =>
                  setEmailForm((prev) => ({ ...prev, message: e.target.value }))
                }
                placeholder="Mensaje del correo"
                className="w-full h-32 px-3 py-2 rounded-md resize-none"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowEmailModal(false)}
              className="cancel"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSendEmail}
              disabled={isSendingEmail}
              className="send"
            >
              {isSendingEmail ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin mr-2" />
                  Enviando...
                </>
              ) : (
                <>
                  <Send className="h-4 w-4 mr-2" />
                  Enviar
                </>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Agregar el modal de administración de contactos de WhatsApp */}
      {showingWhatsappContactsManager && (
        <FlowWhatsappContactsManager
          isOpen={showingWhatsappContactsManager}
          toggle={() => setShowingWhatsappContactsManager(false)}
          whatsappContacts={flow.whatsappContacts}
          flow={flow}
          mantisBusinessId={props.mantisBusiness._id}
          token={props.token}
          onSave={(flow) => setFlow(flow)}
        />
      )}

      {/* Edit Modal */}
      <Dialog
        open={showEditModal}
        onOpenChange={() => {
          if (!updatingField) setShowEditModal(false);
        }}
        style={{
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <DialogContent className="dialog-content edit-dialog">
          <DialogHeader>
            <DialogTitle className="dialog-title">
              Editar {currentColumn}
            </DialogTitle>
          </DialogHeader>

          <div className="py-4">
            <input
              type="text"
              value={currentValue}
              onChange={(e) => setCurrentValue(e.target.value)}
              className="w-full p-2 rounded-md"
            />
          </div>

          <DialogFooter>
            <Button
              disabled={updatingField}
              variant="outline"
              onClick={() => setShowEditModal(false)}
              className="cancel mr-2"
            >
              Cancelar
            </Button>
            <Button
              disabled={updatingField}
              onClick={handleEditColumnValue}
              className="save"
            >
              {updatingField ? (
                <RefreshCw className="h-4 w-4 animate-spin mr-2" />
              ) : null}
              Guardar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </motion.div>
  );
}

export default connect(select)(FlowResults);
