"use client"

import { useState, useEffect } from "react"

const DynamicLoader = ({ isLoading }) => {
  const [phrase, setPhrase] = useState("Creando ideas...")

  const phrases = [
    "Creando ideas...",
    "Generando conexiones...",
    "Optimizando tu flujo...",
    "Analizando documentos...",
    "Estructurando información...",
    "Diseñando soluciones...",
    "Conectando conceptos...",
  ]

  useEffect(() => {
    if (!isLoading) return

    let currentIndex = 0
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % phrases.length
      setPhrase(phrases[currentIndex])
    }, 2000)

    return () => clearInterval(interval)
  }, [isLoading])

  if (!isLoading) return null

  return (
    <div className="fixed inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="flex flex-col items-center">
        <div className="relative w-16 h-16 mb-4">
          {/* Círculo animado */}
          <div className="absolute inset-0 border-4 border-orange-200 rounded-full"></div>
          <div className="absolute inset-0 border-4 border-transparent border-t-orange-500 rounded-full animate-spin"></div>

          {/* Puntos pulsantes */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-2 h-2 bg-orange-500 rounded-full animate-pulse"></div>
          </div>
        </div>

        <div className="text-center">
          <p className="text-orange-600 font-medium text-lg animate-pulse">{phrase}</p>
          <p className="text-gray-500 text-sm mt-2">Estamos preparando tu experiencia</p>
        </div>
      </div>
    </div>
  )
}

export default DynamicLoader

