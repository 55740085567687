import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../../styles/Users.css";

import mantisLogo from "../../../Assets/img/mantisBlackLogo.png";
import { createEmployee, getEmployees } from "../../../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function Employees(props) {
  const pageSize = 10;
  const [employees, setEmployees] = useState([]);
  const [creatingNewEmployee, setCreatingNewEmployee] = useState(false);
  const [loadingCreatingNewEmployee, setLoadingCreatingNewEmployee] =
    useState(false);
  const [newEmployeeForm, setNewEmployeeForm] = useState({
    firstName: "",
    lastName: "",
    identificationType: "CC",
    identificationNumber: "",
    email: "",
    cellPhone: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBusinessEmployees();
  }, []);

  function clearNewEmployeeForm() {
    setNewEmployeeForm({
      firstName: "",
      lastName: "",
      identificationType: "CC",
      identificationNumber: "",
      email: "",
      cellPhone: "",
    });
  }

  async function getBusinessEmployees() {
    const res = await getEmployees(props.mantisBusiness._id, props.token);
    if (res.success) {
      setEmployees(res.employees);
    }
    setLoading(false);
  }

  async function handleCreateNewEmployee(event) {
    event.preventDefault();
    setLoadingCreatingNewEmployee(true);
    const res = await createEmployee(props.mantisBusiness._id, props.token, {
      ...newEmployeeForm,
    });
    if (res.success) {
      setEmployees([...employees, res.newEmployee]);
      setCreatingNewEmployee(false);
      setLoadingCreatingNewEmployee(false);
      clearNewEmployeeForm();
    } else {
      setLoadingCreatingNewEmployee(false);
    }
  }

  function handleCreateEmployeeInputChange(event) {
    setNewEmployeeForm({
      ...newEmployeeForm,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div>
      <Row>
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          <Row>
            <Col xs={6}>
              {!creatingNewEmployee && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => {
                      setCreatingNewEmployee(true);
                    }}
                  >
                    +
                  </div>
                  <h5 className="add-employee-text">Agregar empleado</h5>
                </div>
              )}
              {creatingNewEmployee && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => {
                      setCreatingNewEmployee(false);
                    }}
                  >
                    {"<"}
                  </div>
                  <h5 className="add-employee-text">Regresar</h5>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={11} style={{ margin: "auto", marginTop: 20 }}>
          <div>
            {loading ? (
              <Spinner color="white" />
            ) : (
              <>
                {!creatingNewEmployee && (
                  <Table className="employees-table">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>ID</th>
                        <th>Fecha de Ingreso</th>
                        <th>Teléfono</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((employee, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {employee.user.firstName} {employee.user.lastName}
                            </td>
                            <td>
                              {employee.user.identificationType}{" "}
                              {employee.user.identification}
                            </td>
                            <td>
                              {new Date(
                                employee.dateCreated
                              ).toLocaleDateString("es")}
                            </td>
                            <td>{employee.businessEmployeePhone}</td>
                            <td>
                              {employee.status === "ACTIVE"
                                ? "Activo"
                                : employee.status === "PENDING_APPROVAL"
                                ? "Aprobación Pendiente"
                                : "Rechazado"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {creatingNewEmployee && (
                  <div>
                    <h3
                      style={{
                        textAlign: "left",
                        marginBottom: "20px",
                        fontFamily: "Poppins, sans-seriff",
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "white",
                      }}
                    >
                      Crea un nuevo empleado
                    </h3>
                    <Form
                      onSubmit={handleCreateNewEmployee}
                      style={{ margin: "auto" }}
                    >
                      <Row>
                        <Col xs={6}>
                          <FormGroup>
                            {/* <h5
                              className="new-employee-form-label"
                              for="amount"
                            >
                              Nombre(s)
                            </h5> */}
                            <Input
                              className="new-employee-form-input"
                              type="text"
                              name="firstName"
                              id="firstName"
                              value={newEmployeeForm.firstName}
                              onChange={handleCreateEmployeeInputChange}
                              placeholder="Nombre(s)"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            {/* <h5
                              className="new-employee-form-label"
                              for="amount"
                            >
                              Apellido(s)
                            </h5> */}
                            <Input
                              className="new-employee-form-input"
                              type="text"
                              name="lastName"
                              id="lastName"
                              value={newEmployeeForm.lastName}
                              onChange={handleCreateEmployeeInputChange}
                              placeholder="Apellido(s)"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <h5
                              className="new-employee-form-label"
                              for="amount"
                            >
                              Número de identificación
                            </h5>
                            <Row>
                              <Col xs={3}>
                                <Input
                                  id="identificationType"
                                  name="identificationType"
                                  type="select"
                                  className="new-employee-form-input"
                                  value={newEmployeeForm.identificationType}
                                  onChange={handleCreateEmployeeInputChange}
                                  placeholder="Tipo de identificación"
                                >
                                  <option value={"CC"}>CC</option>
                                  <option value={"CE"}>CE</option>
                                  <option value={"PPT"}>PPT</option>
                                  <option value={"TI"}>TI</option>
                                </Input>
                              </Col>
                              <Col xs={9}>
                                <Input
                                  className="new-employee-form-input"
                                  type="text"
                                  name="identificationNumber"
                                  id="identificationNumber"
                                  value={newEmployeeForm.identificationNumber}
                                  onChange={handleCreateEmployeeInputChange}
                                  placeholder="Número de identificación"
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            <h5
                              className="new-employee-form-label"
                              for="amount"
                            >
                              Correo
                            </h5>
                            <Input
                              className="new-employee-form-input"
                              type="text"
                              name="email"
                              id="email"
                              value={newEmployeeForm.email}
                              onChange={handleCreateEmployeeInputChange}
                              placeholder="Correo electrónico"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6}>
                          <FormGroup>
                            {/* <h5
                              className="new-employee-form-label"
                              for="amount"
                            >
                              Celular (incluir indicativo del país)
                            </h5> */}
                            {/* <Input
                              className="new-employee-form-input"
                              type="text"
                              name="cellPhone"
                              id="cellPhone"
                              value={newEmployeeForm.cellPhone}
                              onChange={handleCreateEmployeeInputChange}
                              placeholder="Ej: 571234567890"
                            /> */}
                            <PhoneInput
                              className="new-employee-form-input"
                              defaultCountry={"CO"}
                              placeholder="Celular de contacto"
                              value={newEmployeeForm.cellPhone}
                              onChange={(e) => {
                                if (!e) {
                                  e = "";
                                }
                                setNewEmployeeForm({
                                  ...newEmployeeForm,
                                  cellPhone: e
                                    .replace("+", "")
                                    .replace(" ", ""),
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12}>
                          <h5 className="new-employee-form-label" for="amount">
                            Enviaré un mensaje por Whatsapp para que pueda
                            descargar y crear la cuenta en Mantis.
                          </h5>
                        </Col>

                        <Col xs={12} style={{ margin: "auto", marginTop: 20 }}>
                          <Button
                            className="create-new-employee-button"
                            type="submit"
                          >
                            {loadingCreatingNewEmployee ? (
                              <Spinner size={"sm"} color="white" />
                            ) : (
                              "Crear empleado"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(Employees);
