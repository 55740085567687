"use client"

import { useState } from "react"
import { Clock, Mail, Calendar } from "lucide-react"
import { Button } from "../../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select"
import { Input } from "../../../components/ui/input"
import { Label } from "../../../components/ui/label"
import { useToast } from "../../../components/ui/use-toast"

export default function ReportScheduler({ flowId, flowName }) {
  const [open, setOpen] = useState(false)
  const [format, setFormat] = useState("excel")
  const [frequency, setFrequency] = useState("weekly")
  const [emails, setEmails] = useState("")
  const { toast } = useToast()

  const handleSubmit = (e) => {
    e.preventDefault()

    // Here you would typically make an API call to schedule the report
    console.log({
      flowId,
      format,
      frequency,
      emails: emails.split(",").map((email) => email.trim()),
    })

    toast({
      title: "Report scheduled successfully",
      description: `Your ${format.toUpperCase()} report for "${flowName}" will be sent ${frequency}.`,
      duration: 5000,
    })

    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="flex items-center gap-1">
          <Clock className="h-4 w-4" />
          <span>Schedule Report</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Schedule Report</DialogTitle>
          <DialogDescription>Set up automatic report delivery for "{flowName}".</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="format">Report Format</Label>
              <Select value={format} onValueChange={setFormat}>
                <SelectTrigger id="format">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="excel">Excel</SelectItem>
                  <SelectItem value="pdf">PDF</SelectItem>
                  <SelectItem value="csv">CSV</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="frequency">Frequency</Label>
              <Select value={frequency} onValueChange={setFrequency}>
                <SelectTrigger id="frequency">
                  <SelectValue placeholder="Select frequency" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="daily">Daily</SelectItem>
                  <SelectItem value="weekly">Weekly</SelectItem>
                  <SelectItem value="monthly">Monthly</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="emails">Recipients (comma separated)</Label>
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4 text-slate-500" />
                <Input
                  id="emails"
                  placeholder="email@example.com, other@example.com"
                  value={emails}
                  onChange={(e) => setEmails(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" className="bg-orange-500 hover:bg-orange-600">
              <Calendar className="mr-2 h-4 w-4" />
              Schedule Report
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

