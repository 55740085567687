import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {
  getBusinessFolderById,
  moveDocumentsToFolder,
} from "../../ApiCalls/mantisBusiness";
import { FaChevronRight } from "react-icons/fa";
import "../styles/Archivo.css";

const SelectFolderModal = ({
  isOpen,
  toggle,
  folders,
  onSelect,
  token,
  mantisBusinessId,
  originFolder,
  documentsToMove,
}) => {
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [shownFolders, setShownFolders] = useState(folders);
  const [loadingSubfolders, setLoadingSubfolders] = useState(false);
  const [destinationFolder, setDestinationFolder] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [movingDocuments, setMovingDocuments] = useState(false);

  const getFolderFromId = async (folderId) => {
    setLoadingSubfolders(true);
    const res = await getBusinessFolderById(
      folderId,
      token,
      mantisBusinessId,
      1
    );
    if (res.success) {
      setSelectedFolder(res.folder);
      setShownFolders(res.folder.subFolders);
    }
    setLoadingSubfolders(false);
  };

  useEffect(() => {
    if (isOpen) {
      setShownFolders(folders);
      setSelectedFolder(null);
    }
  }, [isOpen, folders]);

  useEffect(() => {
    if (selectedFolder) {
      const folder = shownFolders.find(
        (folder) => folder._id === selectedFolder._id
      );
      if (folder) {
        getFolderFromId(folder._id);
      }
    }
  }, [selectedFolder]);

  const handleSelect = (folder) => {
    setConfirmationMessage(
      `Mover ${documentsToMove.length} elemento(s) de ${originFolder.icon} ${originFolder.name} a ${folder.icon} ${folder.name}`
    );
    setIsConfirmationOpen(true);
    setDestinationFolder(folder);
  };

  const handleConfirm = async () => {
    try {
      setMovingDocuments(true);
      const res = await moveDocumentsToFolder(
        token,
        mantisBusinessId,
        documentsToMove,
        destinationFolder._id
      );
      if (res.success) {
        setIsConfirmationOpen(false);
        setMovingDocuments(false);
        onSelect();
      } else {
        alert("Error al mover los documentos");
      }
    } catch {
      alert("Error al mover los documentos");
    }
  };

  const handleCancel = () => {
    setIsConfirmationOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          if (movingDocuments) return;
          toggle();
        }}
        className="select-folder-modal"
        centered
      >
        <ModalHeader toggle={toggle} className="select-folder-modal-header">
          Seleccionar carpeta destino
        </ModalHeader>
        <ModalBody className="select-folder-modal-body">
          {loadingSubfolders ? (
            <div className="d-flex justify-content-center align-items-center p-5">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="folder-list-container">
              {shownFolders && shownFolders.length > 0 ? (
                shownFolders.map((folder) => (
                  <div
                    key={folder._id}
                    className="folder-list-item"
                    onMouseEnter={() => setHoveredFolder(folder._id)}
                    onMouseLeave={() => setHoveredFolder(null)}
                  >
                    <div 
                      className="folder-list-item-content"
                      onClick={() => getFolderFromId(folder._id)}
                    >
                      <span className="folder-list-item-icon">{folder.icon}</span>
                      <span className="folder-list-item-name">{folder.name}</span>
                    </div>
                    <div className="folder-list-item-actions">
                      <button
                        className="folder-select-button"
                        onClick={() => handleSelect(folder)}
                        aria-label="Seleccionar carpeta"
                      >
                        Seleccionar
                      </button>
                      <FaChevronRight 
                        className="folder-list-item-arrow" 
                        onClick={() => getFolderFromId(folder._id)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center p-4">
                  <p className="text-doc-text-primary">No hay carpetas disponibles</p>
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter className="select-folder-modal-footer">
          {selectedFolder && (
            <>
              <Button
                className="modal-button primary"
                onClick={() => {
                  handleSelect(selectedFolder);
                }}
                style={{ marginRight: "10px" }}
              >
                Seleccionar Carpeta
              </Button>
              <Button
                className="modal-button secondary"
                onClick={() => {
                  if (selectedFolder.parentFolderId) {
                    getFolderFromId(selectedFolder.parentFolderId);
                  } else {
                    setSelectedFolder(null);
                    setShownFolders(folders);
                  }
                }}
                style={{ marginRight: "10px" }}
              >
                Volver
              </Button>
            </>
          )}
          <Button className="modal-button secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        toggle={() => setIsConfirmationOpen(false)}
        message={confirmationMessage}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        movingDocuments={movingDocuments}
      />
    </>
  );
};

const ConfirmationModal = ({
  isOpen,
  toggle,
  message,
  onConfirm,
  onCancel,
  movingDocuments,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} centered className="confirmation-modal">
    <ModalHeader toggle={toggle} className="confirmation-modal-header">
      Confirmar
    </ModalHeader>
    <ModalBody className="confirmation-modal-body">
      <h2>{message}</h2>
    </ModalBody>
    <ModalFooter className="confirmation-modal-footer">
      <Button
        className="modal-button danger"
        onClick={onCancel}
        disabled={movingDocuments}
      >
        Cancelar
      </Button>
      {movingDocuments ? (
        <Spinner size="sm" color="light" />
      ) : (
        <Button
          className="modal-button primary"
          onClick={onConfirm}
          disabled={movingDocuments}
        >
          Confirmar
        </Button>
      )}
    </ModalFooter>
  </Modal>
);

export default SelectFolderModal;
