"use client"

import { useState, useEffect } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../../components/ui/dialog"
import { Button } from "../../../../components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs"
import { Label } from "../../../../components/ui/label"
import { Input } from "../../../../components/ui/input"
import { Textarea } from "../../../../components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select"
import { Switch } from "../../../../components/ui/switch"
import { Slider } from "../../../../components/ui/slider"
import { Cog } from "lucide-react"

const ProcessNodeModal = ({ isOpen, onClose, nodeData, onSave }) => {
  const [formData, setFormData] = useState({})
  const [activeTab, setActiveTab] = useState("general")

  // Inicializar formData cuando se abre el modal o cambia el nodo
  useEffect(() => {
    if (isOpen && nodeData) {
      setFormData({
        label: nodeData.label || "",
        description: nodeData.description || "",
        ...nodeData.config,
      })
    }
  }, [isOpen, nodeData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSwitchChange = (name, checked) => {
    setFormData((prev) => ({ ...prev, [name]: checked }))
  }

  const handleSelectChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSliderChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value[0] }))
  }

  const handleSave = () => {
    // Separar los datos básicos de los de configuración
    const { label, description, ...config } = formData
    onSave({ label, description, config })
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <Cog className="h-5 w-5 text-purple-600 mr-2" />
            Configuración de Proceso
          </DialogTitle>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid grid-cols-3 mb-4">
            <TabsTrigger value="general">General</TabsTrigger>
            <TabsTrigger value="specific">Específico</TabsTrigger>
            <TabsTrigger value="advanced">Avanzado</TabsTrigger>
          </TabsList>

          {/* Pestaña General */}
          <TabsContent value="general" className="space-y-4">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="label">Nombre</Label>
                <Input id="label" name="label" value={formData.label || ""} onChange={handleInputChange} />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="description">Descripción</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                  rows={3}
                />
              </div>
            </div>
          </TabsContent>

          {/* Pestaña Específica para Procesos */}
          <TabsContent value="specific" className="space-y-4">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="processType">Tipo de Proceso</Label>
                <Select
                  value={formData.processType || "automatic"}
                  onValueChange={(value) => handleSelectChange("processType", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleccionar tipo" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="automatic">Automático</SelectItem>
                    <SelectItem value="manual">Manual</SelectItem>
                    <SelectItem value="hybrid">Híbrido</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="priority">Prioridad</Label>
                <Select
                  value={formData.priority || "medium"}
                  onValueChange={(value) => handleSelectChange("priority", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleccionar prioridad" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="low">Baja</SelectItem>
                    <SelectItem value="medium">Media</SelectItem>
                    <SelectItem value="high">Alta</SelectItem>
                    <SelectItem value="critical">Crítica</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="grid gap-2">
                <Label>Tiempo Estimado (minutos)</Label>
                <Slider
                  defaultValue={[formData.estimatedTime || 10]}
                  max={120}
                  step={1}
                  onValueChange={(value) => handleSliderChange("estimatedTime", value)}
                />
                <div className="text-right text-sm text-muted-foreground">{formData.estimatedTime || 10} minutos</div>
              </div>
            </div>
          </TabsContent>

          {/* Pestaña Avanzada */}
          <TabsContent value="advanced" className="space-y-4">
            <div className="grid gap-4">
              <div className="flex items-center justify-between">
                <Label htmlFor="isRequired">Obligatorio</Label>
                <Switch
                  id="isRequired"
                  checked={formData.isRequired || false}
                  onCheckedChange={(checked) => handleSwitchChange("isRequired", checked)}
                />
              </div>
              <div className="flex items-center justify-between">
                <Label htmlFor="isVisible">Visible</Label>
                <Switch
                  id="isVisible"
                  checked={formData.isVisible !== false} // Por defecto true
                  onCheckedChange={(checked) => handleSwitchChange("isVisible", checked)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="customId">ID Personalizado</Label>
                <Input
                  id="customId"
                  name="customId"
                  value={formData.customId || ""}
                  onChange={handleInputChange}
                  placeholder="Dejar en blanco para ID automático"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="notes">Notas</Label>
                <Textarea
                  id="notes"
                  name="notes"
                  value={formData.notes || ""}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="Notas adicionales sobre este nodo"
                />
              </div>
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={handleSave}>Guardar Cambios</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ProcessNodeModal

