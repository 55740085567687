import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "../../styles/businessBackofficeStyles.scss";
import {brand} from "../../../config";
import {Folders, Inbox as InboxIcon, Flag, BotMessageSquare ,Target, Workflow  , UsersRound, Blocks , LogOut, LayoutDashboard, ChartBarStacked} from "lucide-react";
import mantisLogo from "../../../Assets/img/mantisBlackLogo.png";
import mantisBlackLogo from "../../../Assets/img/icons/b2b/mantisBlack.png";
import mantisWhiteLogo from "../../../Assets/img/icons/b2b/mantisWhite.png";
import sandyLogo from "../../../Assets/img/sandyIsotype.png";
import mantisWhiteCompleteLogo from "../../../Assets/img/mantisWhiteLogo2.png";
import mantisBlackCompleteLogo from "../../../Assets/img/mantisBlackLogo.png";

import sandyCompleteLogo from "../../../Assets/img/sandyLogo.png";
import bellWhite from "../../../Assets/img/icons/b2b/bellWhite.png";
import bellBlack from "../../../Assets/img/icons/b2b/bellBlack.png";
import Employees from "../Employees/Employees";
import Archive from "../Documents/Archive";
import {businessLogout} from "../../../ApiCalls/authentication";
import {getFromStorage, setInStorage} from "../../../utils/storage";
import {userLogout} from "../../../Redux/modules/auth";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {getMantisBusinessNotificationNumber} from "../../../ApiCalls/mantisBusiness";
import BusinessNotifications from "./BusinessNotifications";
import {checkMantisBusinessUserPermission} from "../../../utils/businessUtils";
import BusinessUsers from "./BusinessUsers";
import BusinessAssistant from "./BusinessAssistant";
import BusinessIntegrations from "./BusinessIntegrations";
import {Modal} from "react-bootstrap";
import UsersSection from "../User/UsersSection";
import BusinessMissions from "./BusinessMissions";
import BusinessArchive from "./BusinessArchive";
import ArchivesSection from "../Documents/ArchivesSection";
import HelpPanel from "../../Extras/HelpPanel";
import BusinessDashboard from "./BusinessDashboard";
import BusinessExtraction from "./BusinessExtraction";
import DocumentSection from "../Documents/DocumentSection";
import Correspondence from "../Correspondence/Correspondence";
import Inbox from "../User/Inbox";
import DocumentActions from "../Documents/DocumentActions";
import Flows from "../Flows/Flows";
import FlowCreator from "../FlowCreator/FlowCreator";
import FlowManagement from "../FlowManagement/FlowManagement";
import DocumentManager from '../Documents/Document';
import ThemeToggle from "../../Extras/ThemeToggle";
import "../../styles/Navbar.css";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout()),
  };
}

function BusinessBackOffice(props) {
  const { activeTab } = useParams();
  const navigate = useNavigate();

  const [sidebarExpandedWidth, setSidebarExpandedWidth] = useState(200);
  const [sidebarCollapsedWidth, setSidebarCollapsedWidth] = useState(100);
  const [sidebarTransitionTime, setSidebarTransitionTime] = useState(0.2);

  const [helpPanelExpandedWidth, setHelpPanelExpandedWidth] = useState(300);
  const [helpPanelCollapsedWidth, setHelpPanelCollapsedWidth] = useState(50);
  const [helpPanelTransitionTime, setHelpPanelTransitionTime] = useState(0.2);
  const isGovernmentAccount =
    props.mantisBusiness._id === "67194978e97e646e3429b270";

  const [activeSection, setActiveSection] = useState(
    activeTab
      ? activeTab
      : props.mantisBusiness.enabledSections
      ? props.mantisBusiness.enabledSections[0]
      : "DASHBOARD"
  );

  useEffect(() => {
    if (activeTab) {
      setActiveSection(activeTab.toUpperCase().replace(/-/g, "_"));
    }
  }, [activeTab]);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [helpPanelExpanded, setHelpPanelExpanded] = useState(false);
  const [hoverButtons, setHoverButtons] = useState({
    employees: false,
    archive: false,
    notifications: false,
    users: false,
    integrations: false,
    assistant: false,
    logout: false,
    profile: false,
    missions: false,
    dashboard: false,
    controlPanel: false,
    taktik: false,
    documentManager: false,
  });
  const [notificationAmount, setNotificationAmount] = useState(0);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(
    document.documentElement.classList.contains("dark")
  );

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (activeSection !== "ASSISTANT") {
      setHelpPanelCollapsedWidth(0);
      setHelpPanelExpanded(false);
    } else {
      setHelpPanelCollapsedWidth(50);
      setHelpPanelExpandedWidth(400);
    }
  }, [activeSection]);

  useEffect(() => {
    let trueCount = Object.values(hoverButtons).filter((value) => value).length;
    if (trueCount > 1) {
      clearHoverButtons();
    }
  }, [hoverButtons]);

  /*  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications();
    }, 10000);
    return () => clearInterval(interval);
  }, []); */

  const clearHoverButtons = () => {
    setHoverButtons({
      employees: false,
      archive: false,
      notifications: false,
      users: false,
      integrations: false,
      assistant: false,
      logout: false,
      profile: false,
      missions: false,
      dashboard: false,
      controlPanel: false,
      taktik: false,
      documentManager: false,
    });
  };

  const getNotifications = async () => {
    try {
      const notificationsRes = await getMantisBusinessNotificationNumber(
        props.mantisBusiness._id,
        props.token
      );
      if (notificationsRes.success) {
        setNotificationAmount(notificationsRes.notificationsAmount);
      }
    } catch (error) {}
  };

  const handleLogout = async () => {
    const localStorageToken = getFromStorage("mantis_app");

    if (localStorageToken && localStorageToken.token) {
      const { token } = localStorageToken;
      businessLogout(token);
      setInStorage("mantis_app", null);
      props.userLogout();
      navigate("/");
    }
  };

  const calculateViewPanelWidth = () => {
    if (sidebarExpanded && !helpPanelExpanded) {
      return `calc(100vw - ${sidebarExpandedWidth}px)`;
    } else if (sidebarExpanded && helpPanelExpanded) {
      return `calc(100vw - ${sidebarExpandedWidth + helpPanelExpandedWidth}px)`;
    } else if (!sidebarExpanded && helpPanelExpanded) {
      return `calc(100vw - ${helpPanelExpandedWidth + sidebarCollapsedWidth}px)`;
    } else {
      return `calc(100vw - ${sidebarCollapsedWidth + helpPanelCollapsedWidth}px)`;
    }
  };

  const handleThemeChange = (isDark) => {
    setIsDarkTheme(isDark);
  };

  return (
    <div className="business-backoffice-container">
      <div
        className="business-backoffice-sidebar"
        style={{
          width: sidebarExpanded
            ? `${sidebarExpandedWidth}px`
            : `${sidebarCollapsedWidth}px`,
          transition: `width ${sidebarTransitionTime}s`,
        }}
        onMouseEnter={() => {
          setSidebarExpanded(true);
        }}
        onMouseLeave={() => {
          setSidebarExpanded(false);
          setHoverButtons({
            employees: false,
            archive: false,
            notifications: false,
            users: false,
            integrations: false,
            assistant: false,
            logout: false,
          });
        }}
      >
        <div className="business-backoffice-top-buttons-container">
          {!sidebarExpanded ? (
            <img
              src={isDarkTheme ? mantisWhiteLogo : mantisBlackLogo}
              alt="Mantis logo"
              className="business-logo"
            />
          ) : (
            <img
              src={isDarkTheme ? mantisWhiteCompleteLogo : mantisBlackCompleteLogo}
              alt="Mantis logo"
              className="business-logo"
            />
          )}
        </div>

        <div className="business-backoffice-section-buttons-container">
          {props.mantisBusiness.enabledSections?.includes("FLOWS") &&
            props.user.mantisMainUser && (
              <div
                className={`
                  business-sidebar-button-container 
                  ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
                  ${activeSection === "FLOWS" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
                `}
                onClick={() => {
                  setActiveSection("FLOWS");
                  navigate("/business/backoffice/flows", { replace: true });
                  clearHoverButtons();
                }}
                onMouseEnter={() => {
                  if (activeSection !== "FLOWS") {
                    setHoverButtons({ ...hoverButtons, flows: true });
                  }
                }}
                onMouseLeave={() => {
                  if (activeSection !== "FLOWS") {
                    setHoverButtons({ ...hoverButtons, flows: false });
                  }
                }}
              >
                <Workflow  className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
                {sidebarExpanded && (
                  <h4 className="business-sidebar-tab-title">
                    Flujos
                  </h4>
                )}
              </div>
            )}
          {props.mantisBusiness.enabledSections?.includes("TAKTIK") ? (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "TAKTIK" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("TAKTIK");
                navigate("/business/backoffice/taktik", {
                  replace: true
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "TAKTIK") {
                  setHoverButtons({ ...hoverButtons, taktik: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "TAKTIK") {
                  setHoverButtons({ ...hoverButtons, taktik: false });
                }
              }}
            >
              <LayoutDashboard className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Taktik</h4>)}
            </div>
          ) : null}
          {props.mantisBusiness.enabledSections?.includes("FLOW_CREATOR") ? (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "FLOW_CREATOR" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("FLOW_CREATOR");
                navigate("/business/backoffice/flow-creator", {
                  replace: true
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "FLOW_CREATOR") {
                  setHoverButtons({ ...hoverButtons, flowCreator: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "FLOW_CREATOR") {
                  setHoverButtons({ ...hoverButtons, taktik: false });
                }
              }}
            >
              <Workflow  className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Flow Creator</h4>)}
            </div>
          ) : null}
          {props.mantisBusiness.enabledSections?.includes("FLOW_MANAGEMENT") ? (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "FLOW_MANAGEMENT" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("FLOW_MANAGEMENT");
                navigate("/business/backoffice/flow-management", {
                  replace: true
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "FLOW_MANAGEMENT") {
                  setHoverButtons({ ...hoverButtons, flowManagement: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "FLOW_MANAGEMENT") {
                  setHoverButtons({ ...hoverButtons, flowManagement: false });
                }
              }}
            >
              <LayoutDashboard  className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Flow Management</h4>)}
            </div>
          ) : null}
          {props.mantisBusiness.enabledSections?.includes("FILLING_FORM") && (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "FILLING_FORM" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("FILLING_FORM");
                navigate("/business/backoffice/filling-form", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "FILLING_FORM") {
                  setHoverButtons({ ...hoverButtons, fillingForm: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "FILLING_FORM") {
                  setHoverButtons({ ...hoverButtons, fillingForm: false });
                }
              }}
            >
              <Target className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Crear Radicado</h4>)}
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("INBOX") && (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "INBOX" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("INBOX");
                navigate("/business/backoffice/inbox", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "INBOX") {
                  setHoverButtons({ ...hoverButtons, inbox: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "INBOX") {
                  setHoverButtons({ ...hoverButtons, inbox: false });
                }
              }}
            >
              <InboxIcon className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Buzón</h4>)}
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("ASSISTANT") && (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "ASSISTANT" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
            
              onClick={() => {
                setActiveSection("ASSISTANT");
                navigate("/business/backoffice/assistant", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "ASSISTANT") {
                  setHoverButtons({ ...hoverButtons, assistant: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "ASSISTANT") {
                  setHoverButtons({ ...hoverButtons, assistant: false });
                }
              }}
            > 
              <BotMessageSquare className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Chat mantis</h4>)}
               
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("DASHBOARD") && (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "DASHBOARD" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("DASHBOARD");
                navigate("/business/backoffice/dashboard?idDashboard=1", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "DASHBOARD") {
                  setHoverButtons({ ...hoverButtons, dashboard: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "DASHBOARD") {
                  setHoverButtons({ ...hoverButtons, dashboard: false });
                }
              }}
            >
              <ChartBarStacked className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Dashboard</h4>)}
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("ARCHIVE") && (
            <div
            className={`
              business-sidebar-button-container 
              ${sidebarExpanded ? 'business-sidebar-button-container-expanded' : ''}
              ${activeSection === "ARCHIVE" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
              onClick={() => {
                setActiveSection("ARCHIVE");
                navigate("/business/backoffice/archive", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "ARCHIVE") {
                  setHoverButtons({ ...hoverButtons, archive: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "ARCHIVE") {
                  setHoverButtons({ ...hoverButtons, archive: false });
                }
              }}
            >
              <Folders className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Archivo</h4>)}
               
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("CONTROL_PANEL") && (
            <div
              className={
                activeSection === "CONTROL_PANEL"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("CONTROL_PANEL");
                navigate("/business/backoffice/control-panel", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "CONTROL_PANEL") {
                  setHoverButtons({ ...hoverButtons, controlPanel: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "CONTROL_PANEL") {
                  setHoverButtons({ ...hoverButtons, controlPanel: false });
                }
              }}
            >
              <Flag className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Centro de control</h4>)}
               
            </div>
          )}
          {props.mantisBusiness.enabledSections?.includes("USERS") && (
            <div
              className={
                activeSection === "USERS"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("USERS");
                navigate("/business/backoffice/users", { replace: true });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "USERS") {
                  setHoverButtons({ ...hoverButtons, users: true });
                }
              }}
              onMouseLeave={() => {
                if (activeSection !== "USERS") {
                  setHoverButtons({ ...hoverButtons, users: false });
                }
              }}
            > 
              <UsersRound className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Usuarios</h4>)}
               
            </div>
          )}

          {props.mantisBusiness.enabledSections?.includes("INTEGRATIONS") && (
            <div
              className={
                activeSection === "INTEGRATIONS"
                  ? "business-sidebar-active-tab-button"
                  : "business-sidebar-tab-button"
              }
              style={{
                position: "relative",
                justifyContent: sidebarExpanded ? "flex-start" : "center",
                paddingLeft: sidebarExpanded ? "10px" : "0px",
              }}
              onClick={() => {
                setActiveSection("INTEGRATIONS");
                navigate("/business/backoffice/integrations", {
                  replace: true,
                });
                clearHoverButtons();
              }}
              onMouseEnter={() => {
                if (activeSection !== "INTEGRATIONS") {
                  setHoverButtons({ ...hoverButtons, integrations: true });
                }
              }}
              onMouseLeave={() =>
                setHoverButtons({ ...hoverButtons, integrations: false })
              }
            >
              <Blocks  className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Integraciones</h4>)}
               
            </div>
          )}
        </div>

        <div className="business-backoffice-bottom-buttons-container">
          <div
            className={`
              business-profile-container
              ${activeSection === "PROFILE" ? "business-sidebar-active-tab-button" : "business-sidebar-tab-button"}
            `}
          >
            <img
              src={props.mantisBusiness.logo || mantisLogo}
              alt="Business logo"
              className="business-profile-image"
            />
              {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Perfil</h4>)}

          </div>
          <div
            className="business-sidebar-logout-button"
            onClick={() => setShowLogoutModal(true)}
            onMouseEnter={() =>
              setHoverButtons({ ...hoverButtons, logout: true })
            }
            onMouseLeave={() =>
              setHoverButtons({ ...hoverButtons, logout: false })
            }
          >
            <LogOut className={`business-sidebar-tab-icon ${sidebarExpanded ? 'sidebar-expanded' : ''}`} />
            {sidebarExpanded && (<h4 className="business-sidebar-tab-title">Cerrar sesión</h4>)}

          </div>
          <div className="theme-toggle-container">
            <ThemeToggle onThemeChange={handleThemeChange} />
          </div>
        </div>
      </div>
      {props.mantisBusiness.approved ? (
        <div
          className={`
            business-backoffice-viewpanel
            ${activeSection === "ASSISTANT" ? 'assistant' : ''}
          `}
          style={{
            width: calculateViewPanelWidth(),
            left: sidebarExpanded ? `${sidebarExpandedWidth}px` : `${sidebarCollapsedWidth}px`,
          }}
        >
          {activeSection === "EMPLOYEES" && <Employees />}
          {activeSection === "DASHBOARD" && <BusinessDashboard />}
          {activeSection === "ARCHIVE" && (
            <>
              {/* <Archive archiveUserId={archiveUserId} /> */}
              <ArchivesSection />
            </>
          )}
          {activeSection === "NOTIFICATIONS" && <BusinessNotifications />}
          {activeSection === "USERS" && <UsersSection />}
          {activeSection === "ASSISTANT" && (
            <BusinessAssistant
              sidebarExpanded={sidebarExpanded}
              helpPanelExpanded={helpPanelExpanded}
              sidebarCollapsedWidth={sidebarCollapsedWidth}
              sidebarExpandedWidth={sidebarExpandedWidth}
              helpPanelExpandedWidth={helpPanelExpandedWidth}
              helpPanelCollapsedWidth={helpPanelCollapsedWidth}
              sidebarTransitionTime={sidebarTransitionTime}
            />
          )}
          {activeSection === "INTEGRATIONS" && <BusinessIntegrations />}
          {activeSection === "CONTROL_PANEL" && <BusinessExtraction />}
          {activeSection === "INBOX" && <Inbox />}
          {activeSection === "FLOWS" && <Flows />}
          {activeSection === "FLOW_CREATOR" && <FlowCreator />}
          {activeSection === "FLOW_MANAGEMENT" && <FlowManagement />}
          {activeSection === "FILLING_FORM" && <DocumentActions />}
          {activeSection === "TAKTIK" && <DocumentManager />}
          {/* <div className="yellow-blur"></div>
          <div className="blue-blur"></div> */}
        </div>
      ) : (
        <div
          className={`
            business-backoffice-viewpanel
            ${activeSection === "ASSISTANT" ? 'assistant' : ''}
          `}
          style={{
            width: calculateViewPanelWidth(),
            left: sidebarExpanded ? `${sidebarExpandedWidth}px` : `${sidebarCollapsedWidth}px`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <h2
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
              }}
            >
              ¡Tu cuenta de empresa está en proceso de verificación!
            </h2>
          </div>
          <div className="yellow-blur"></div>
          <div className="blue-blur"></div>
        </div>
      )}
      <HelpPanel
        expanded={helpPanelExpanded}
        setExpanded={setHelpPanelExpanded}
        helpPanelExpandedWidth={helpPanelExpandedWidth}
        helpPanelCollapsedWidth={helpPanelCollapsedWidth}
        helpPanelTransitionTime={helpPanelTransitionTime}
      />
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <h4
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "20px",
                marginBottom: 20,
              }}
            >
              ¿Estás seguro que deseas cerrar sesión?
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <button
                className="business-logout-button"
                onClick={() => handleLogout()}
              >
                Sí
              </button>
              <button
                className="business-logout-button"
                onClick={() => setShowLogoutModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default connect(select, mapDispatchToProps)(BusinessBackOffice);
