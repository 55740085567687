"use client"

import { useState } from "react"
import FlowList from "./components/FlowList"
import FlowDetail from "./components/FlowDetail"
import { mockFlows } from "./mockData"
import { Toaster } from "../../components/ui/toaster"

export default function FlowManagement() {
  const [selectedFlow, setSelectedFlow] = useState(null)
  const [flows] = useState(mockFlows)

  const handleFlowSelect = (flow) => {
    setSelectedFlow(flow)
  }

  const handleBack = () => {
    setSelectedFlow(null)
  }

  return (
    <div className="container mx-auto max-w-7xl">
      {!selectedFlow && <h1 className="text-3xl font-bold mb-6 text-slate-800 dark:text-slate-100">Flow Management</h1>}

      {selectedFlow ? (
        <FlowDetail flow={selectedFlow} onBack={handleBack} />
      ) : (
        <FlowList flows={flows} onFlowSelect={handleFlowSelect} />
      )}

      <Toaster />
    </div>
  )
}

