"use client"

import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import {
  X,
  Maximize2,
  Minimize2,
  Copy,
  Search,
  CheckCircle,
  FileText,
} from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useToast } from "../../../components/ui/use-toast";

export default function FlowModal({
  data,
  onClose,
  columnLabels = {},
  formatCellValue,
}) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [copied, setCopied] = useState(false);
  const { toast } = useToast();

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const copyToClipboard = () => {
    const textToCopy = Object.entries(data)
      .filter(([key]) => key !== "id" && key !== "documentUrl")
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");

    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopied(true);
      toast({
        title: "Copied to clipboard",
        duration: 2000,
      });
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm || typeof text !== "string") return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={i} className="bg-yellow-200 dark:bg-yellow-700">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Obtener el nombre formateado de la columna o usar el nombre original
  const getColumnLabel = (column) => {
    return columnLabels[column] || column;
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
          isFullscreen ? "bg-black" : "bg-black/50"
        }`}
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.9 }}
          className={`bg-white dark:bg-slate-800 rounded-lg shadow-xl overflow-scroll ${
            isFullscreen
              ? "fixed inset-0 m-0 rounded-none"
              : "max-w-6xl w-full max-h-[90vh]"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-slate-700">
            <h3 className="text-lg font-semibold text-slate-800 dark:text-slate-100">
              Extraction Details
            </h3>
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleFullscreen}
                className="text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200"
              >
                {isFullscreen ? (
                  <Minimize2 className="h-5 w-5" />
                ) : (
                  <Maximize2 className="h-5 w-5" />
                )}
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={onClose}
                className="text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row h-[calc(100%-4rem)]">
            {/* Document Preview */}
            <div className="w-full max-h-full md:w-1/2 overflow-scroll border-r border-slate-200 dark:border-slate-700">
              {data?.file_url.includes(".pdf") ? (
                <iframe
                  src={data?.file_url}
                  className="w-full h-full"
                  title="Documento PDF"
                  style={{ border: "none" }}
                />
              ) : data?.file_url &&
                (data?.file_url?.includes(".xls") ||
                  data?.file_url?.includes(".xlsx")) ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/view.aspx?src=${data.file_url}`}
                  className="w-full h-full"
                  title="Documento Excel"
                  style={{ border: "none" }}
                />
              ) : data?.file_url &&
                (data?.file_url?.includes(".jpeg") ||
                  data?.file_url?.includes(".jpg") ||
                  data?.file_url?.includes(".png")) ? (
                <img src={data.file_url} alt="imagen" />
              ) : (
                <div className="flow-pdf-placeholder">
                  <div className="text-center space-y-4">
                    <FileText className="flow-empty-icon" />
                    <p className="flow-empty-text">
                      No hay documento disponible
                    </p>
                    <p className="flow-empty-subtext">URL no encontrada</p>
                  </div>
                </div>
              )}
            </div>

            {/* Extracted Data */}
            <div className="w-full md:w-1/2 h-[300px] md:h-full overflow-scroll">
              <div className="p-4">
                <div className="flex items-center mb-4 space-x-2">
                  <div className="relative flex-1">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-slate-400" />
                    <Input
                      placeholder="Search in extracted data..."
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      className="pl-8"
                    />
                  </div>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={copyToClipboard}
                    className="flex-shrink-0"
                  >
                    {copied ? (
                      <CheckCircle className="h-4 w-4 text-green-500" />
                    ) : (
                      <Copy className="h-4 w-4" />
                    )}
                  </Button>
                </div>

                <div className="space-y-4">
                  {Object.entries(data)
                    .filter(([key]) => key !== "id" && key !== "documentUrl")
                    .map(([key, value]) => {
                      value = formatCellValue(value);
                      return (
                        <div
                          key={key}
                          className="border-b border-slate-200 dark:border-slate-700 pb-3"
                        >
                          <div className="text-sm font-medium text-slate-500 dark:text-slate-400 mb-1">
                            {getColumnLabel(key)}
                          </div>
                          <div className="text-slate-800 dark:text-slate-200">
                            {typeof value === "boolean"
                              ? value
                                ? "Yes"
                                : "No"
                              : typeof value === "string"
                              ? highlightText(value, searchText)
                              : String(value)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

