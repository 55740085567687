import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Row, Table, Col, Spinner, Button, FormGroup } from "reactstrap";
import { Form } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../../styles/Users.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import {
  createMantisBusinessUser,
  updateMantisBusinessUser,
  getMantisBusinessUsers,
  deleteMantisBusinessUser,
  getMantisBusinessGroups,
} from "../../../ApiCalls/mantisBusiness";

const select = (state) => ({
  userId: state.auth.userId,
  token: state.auth.token,
  mantisBusiness: state.auth.mantisBusiness,
  user: state.auth.user,
});

function BusinessUsers(props) {
  const [mantisBusinessUsers, setMantisBusinessUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creatingNewUser, setCreatingNewUser] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loadingCreatingNewUser, setLoadingCreatingNewUser] = useState(false);
  const [loadingEditingUser, setLoadingEditingUser] = useState(false);
  const [newUserFormValidated, setNewUserFormValidated] = useState(false);
  const [editingUserFormValidated, setEditingUserFormValidated] =
    useState(false);
  const [expandedPermissions, setExpandedPermissions] = useState(null);
  const [mantisBusinessGroups, setMantisBusinessGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  const [newUserForm, setNewUserForm] = useState({
    firstName: "",
    lastName: "",
    documentId: "",
    personalEmail: "",
    corporateEmail: "",
    password: "",
    confirmPassword: "",
    cellPhone: "",
    position: "",
    permissions: [],
    role: "USER",
    userGroups: [],
  });

  const [permissionsOptions, setPermissionsOptions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);

  const [formValidated, setFormValidated] = useState(false);

  const clearNewUserForm = () => {
    setNewUserForm({
      firstName: "",
      lastName: "",
      documentId: "",
      personalEmail: "",
      corporateEmail: "",
      password: "",
      confirmPassword: "",
      cellPhone: "",
      position: "",
      permissions: [],
      role: "USER",
      userGroups: [],
    });
    setNewUserFormValidated(false);
  };

  const formatPermissions = (permissions, userId) => {
    if (!permissions || permissions.length === 0) return "-";

    const formattedPermissions = permissions.map((p) => {
      const permission = typeof p === "string" ? p : p.permission;
      const foundPermission = props.mantisBusiness.enabledPermissions.find(
        (ep) => ep.permission === permission
      );
      return foundPermission ? foundPermission.friendlyName : permission;
    });

    const isExpanded = expandedPermissions === userId;

    return (
      <div className="permissions-cell">
        <div className={`permissions-list ${isExpanded ? "expanded" : ""}`}>
          {formattedPermissions.map((permission, index) => (
            <div key={index} className="permission-item">
              {permission}
            </div>
          ))}
        </div>
        {formattedPermissions.length > 4 && (
          <Button
            className="view-more-btn"
            onClick={() => setExpandedPermissions(isExpanded ? null : userId)}
          >
            {isExpanded
              ? "Ver menos"
              : `Ver más (${formattedPermissions.length - 4})`}
          </Button>
        )}
      </div>
    );
  };

  const fetchUserGroups = useCallback(async () => {
    try {
      const res = await getMantisBusinessGroups(
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        const options = res.groups.map((group) => ({
          value: group._id,
          label: group.name,
        }));
        setUserGroupOptions(options);
      } else {
        console.error("Error fetching user groups:", res.message);
      }
    } catch (error) {
      console.error("Error fetching user groups:", error);
    }
  }, [props.mantisBusiness._id, props.token]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await getMantisBusinessUsers(
          props.mantisBusiness._id,
          props.token
        );
        if (res.success) {
          setMantisBusinessUsers(res.users || []);
        } else {
          toast.error(res.message || "Error al obtener usuarios");
        }
      } catch (error) {
        toast.error("Error al cargar usuarios");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (props.mantisBusiness._id && props.token) {
      fetchUsers();
    }
  }, [props.mantisBusiness._id, props.token]);

  useEffect(() => {
    if (props.mantisBusiness.enabledPermissions) {
      setPermissionsOptions(
        props.mantisBusiness.enabledPermissions.map((permission) => ({
          value: permission.permission,
          label: permission.friendlyName,
        }))
      );
    }
  }, [props.mantisBusiness.enabledPermissions]);

  useEffect(() => {
    fetchUserGroups();
  }, [fetchUserGroups]);

  const getCurrentUserRole = () => {
    if (props.user.mainUser) {
      return "ADMIN";
    } else if (props.user.supervisorUser) {
      return "SUPERVISOR";
    }
    return "USER";
  };

  const canManageRoles = () => {
    const currentRole = getCurrentUserRole();
    return currentRole === "ADMIN";
  };

  const canCreateUsers = () => {
    const currentRole = getCurrentUserRole();
    return (
      currentRole === "ADMIN" ||
      currentRole === "SUPERVISOR" ||
      props.user.permissions?.some((p) => p.permission === "CREATE_USERS")
    );
  };

  const renderCreateUserButton = () => {
    if (!canCreateUsers()) {
      return null;
    }

    return (
      <Button
        className="action-button"
        onClick={() => setCreatingNewUser(true)}
      >
        Crear Nuevo Usuario
      </Button>
    );
  };

  const getBusinessGroups = useCallback(async () => {
    try {
      const res = await getMantisBusinessGroups(
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setMantisBusinessGroups(res.groups);
        setGroupOptions(
          res.groups.map((group) => ({
            value: group._id,
            label: group.name,
          }))
        );
      } else {
        console.error("Error fetching groups:", res.message);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  }, [props.mantisBusiness._id, props.token]);

  useEffect(() => {
    if (props.mantisBusiness._id && props.token) {
      getBusinessGroups();
    }
  }, [props.mantisBusiness._id, props.token, getBusinessGroups]);

  const handleCreateNewUser = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setNewUserFormValidated(true);
      return;
    }

    //Password validation
    if (
      newUserForm.password.length < 8 ||
      !/[A-Z]/.test(newUserForm.password)
    ) {
      toast.error(
        "La contraseña debe tener al menos 8 caracteres y una letra mayúscula"
      );
      return;
    }

    if (newUserForm.password !== newUserForm.confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    if (!canManageRoles()) {
      newUserForm.role = "USER";
    }

    setLoadingCreatingNewUser(true);

    try {
      const userData = {
        ...newUserForm,
        mainUser: canManageRoles() && newUserForm.role === "ADMIN",
        supervisorUser: canManageRoles() && newUserForm.role === "SUPERVISOR",
        cellPhone: newUserForm.cellPhone?.replace(/\s/g, "").replace("+", ""),
        group: newUserForm.group
          ? {
              groupId: newUserForm.group.value,
              groupName: newUserForm.group.label,
            }
          : null,
      };

      const res = await createMantisBusinessUser(
        props.mantisBusiness._id,
        props.user._id,
        props.token,
        userData
      );

      if (res.success) {
        toast.success("Usuario creado exitosamente", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          style: {
            background: "#1e1e1e",
            color: "#fff",
            borderRadius: "4px",
            padding: "16px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          },
          progressStyle: {
            background: "linear-gradient(to right, #4cd964, #5ac8fa)",
            height: "2px",
            bottom: 0,
          },
        });
        setMantisBusinessUsers([
          ...mantisBusinessUsers,
          {
            ...res.user,
            groups: userData.userGroups.map((groupId) => ({
              groupId,
              name: userGroupOptions.find((g) => g.value === groupId)?.label,
            })),
          },
        ]);
        setCreatingNewUser(false);
        clearNewUserForm();
      } else {
        throw new Error(res.message || "Error al crear usuario");
      }
    } catch (error) {
      console.error("Error creating user:", error);

      // Verificar si es un error de correo duplicado
      if (
        error.message?.includes("already exists") ||
        error?.response?.status === 405
      ) {
        toast.error("El correo electrónico ya está registrado", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          style: {
            background: "#1e1e1e",
            color: "#fff",
            borderRadius: "4px",
            padding: "16px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          },
          progressStyle: {
            background: "linear-gradient(to right, #ff3b30, #ff9500)",
            height: "2px",
            bottom: 0,
          },
        });
      } else {
        toast.error("Error al crear usuario", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          style: {
            background: "#1e1e1e",
            color: "#fff",
            borderRadius: "4px",
            padding: "16px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
          },
          progressStyle: {
            background: "linear-gradient(to right, #ff3b30, #ff9500)",
            height: "2px",
            bottom: 0,
          },
        });
      }
    } finally {
      setLoadingCreatingNewUser(false);
    }
  };

  const handleEditUser = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Log inicial de permisos y roles del usuario
    console.log("Current user permissions and roles:", {
      permissions: props.user.permissions,
      isMainUser: props.user.mainUser,
      isSupervisor: props.user.supervisorUser,
    });

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setEditingUserFormValidated(true);
      return;
    }

    // Validación de datos requeridos
    if (!props.user._id || !editingUser._id || !props.token) {
      console.error("Missing required data:", {
        currentUserId: props.user._id,
        editingUserId: editingUser._id,
        hasToken: !!props.token,
        mantisBusinessId: props.mantisBusiness._id,
      });
      toast.error("Faltan datos requeridos para la actualización");
      return;
    }

    if (
      editingUser.password &&
      editingUser.password !== editingUser.confirmPassword
    ) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    // Verificación de permisos mejorada
    const hasUpdatePermission =
      props.user.permissions?.some((p) => p.permission === "EDIT_USERS") ||
      props.user.mainUser ||
      props.user.supervisorUser;

    console.log("Permission check:", {
      hasEditPermission: props.user.permissions?.some(
        (p) => p.permission === "EDIT_USERS"
      ),
      isMainUser: props.user.mainUser,
      isSupervisor: props.user.supervisorUser,
      finalPermission: hasUpdatePermission,
    });

    if (!hasUpdatePermission) {
      toast.error("No tienes permisos para editar usuarios");
      return;
    }

    setLoadingEditingUser(true);

    try {
      // Preparación y log de datos del usuario
      const userData = {
        firstName: editingUser.firstName,
        lastName: editingUser.lastName,
        documentId: editingUser.documentId,
        personalEmail: editingUser.personalEmail,
        corporateEmail: editingUser.corporateEmail,
        position: editingUser.position,
        cellPhone: editingUser.cellPhone?.replace(/\s/g, "").replace("+", ""),
        mainUser: editingUser.role === "ADMIN",
        supervisorUser: editingUser.role === "SUPERVISOR",
        permissions: editingUser.permissions || [],
        groups: editingUser.userGroups.map((groupId) => ({
          groupId,
          name: userGroupOptions.find((g) => g.value === groupId)?.label,
        })),
      };

      if (editingUser.password) {
        userData.password = editingUser.password;
      }

      console.log("Sending update request:", {
        mantisBusinessUserId: props.user._id,
        mantisBusinessUserToUpdateId: editingUser._id,
        userData: {
          ...userData,
          password: userData.password ? "***" : undefined,
        },
      });

      const res = await updateMantisBusinessUser(
        props.user._id,
        editingUser._id,
        userData,
        props.token,
        props.mantisBusiness._id
      );

      if (res.success) {
        toast.success("Usuario actualizado exitosamente");
        setMantisBusinessUsers(
          mantisBusinessUsers.map((user) =>
            user._id === editingUser._id ? res.user : user
          )
        );
        setEditingUser(null);
        setEditingUserFormValidated(false);
      } else {
        console.error("Update failed:", res);
        toast.error(res.message || "Error al actualizar usuario");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(
        "Error al actualizar usuario: " + (error.message || "Error desconocido")
      );
    } finally {
      setLoadingEditingUser(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm("¿Está seguro de eliminar este usuario?")) {
      return;
    }

    try {
      const res = await deleteMantisBusinessUser(
        props.mantisBusiness._id,
        props.user._id,
        userId,
        props.token
      );

      if (res.success) {
        toast.success("Usuario eliminado exitosamente");
        setMantisBusinessUsers(
          mantisBusinessUsers.filter((user) => user._id !== userId)
        );
      } else {
        toast.error(res.message || "Error al eliminar usuario");
      }
    } catch (error) {
      toast.error("Error al eliminar usuario");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Validación para documentId - solo números
    if (name === "documentId" && !/^\d*$/.test(value)) {
      return;
    }

    // Validación para position - solo letras y espacios
    if (name === "position" && !/^[A-Za-zÁáÉéÍíÓóÚúÑñ\s]*$/.test(value)) {
      return;
    }

    if (editingUser) {
      setEditingUser({
        ...editingUser,
        [name]: value,
      });
    } else {
      setNewUserForm({
        ...newUserForm,
        [name]: value,
      });
    }
  };

  const handlePhoneChange = (value) => {
    if (editingUser) {
      setEditingUser({
        ...editingUser,
        cellPhone: value,
      });
    } else {
      setNewUserForm({
        ...newUserForm,
        cellPhone: value,
      });
    }
  };

  const handlePermissionsChange = (selectedOptions) => {
    const selectedPermissions = selectedOptions.map((option) => option.value);
    setUserPermissions(selectedPermissions);
    if (editingUser) {
      setEditingUser({ ...editingUser, permissions: selectedPermissions });
    }
    setNewUserForm({ ...newUserForm, permissions: selectedPermissions });
  };

  const renderUserForm = (isEditing) => {
    const formData = isEditing ? editingUser : newUserForm;
    
    const handleSubmit = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      
      if (form.checkValidity() === false) {
        e.stopPropagation();
        setFormValidated(true);
        return;
      }

      if (isEditing) {
        handleEditUser(e);
      } else {
        handleCreateNewUser(e);
      }
    };

    const loading = isEditing ? loadingEditingUser : loadingCreatingNewUser;

    return (
      <Form onSubmit={handleSubmit} noValidate validated={formValidated}>
        <div className="user-form-section">
          <h3 className="section-title">
            {isEditing ? "Editar Usuario" : "Crear Nuevo Usuario"}
          </h3>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Nombre</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                />
                <Form.Control.Feedback type="invalid">
                  El nombre es requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Apellido</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                />
                <Form.Control.Feedback type="invalid">
                  El apellido es requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Documento de Identidad</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="documentId"
                  value={formData.documentId}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                  pattern="\d*"
                  title="Solo se permiten números"
                />
                <Form.Control.Feedback type="invalid">
                  El documento es requerido y solo debe contener números
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Cargo</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  value={formData.position}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Email Corporativo</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="corporateEmail"
                  value={formData.corporateEmail}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                />
                <Form.Control.Feedback type="invalid">
                  El email corporativo es requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Email Personal</Form.Label>
                <Form.Control
                  type="email"
                  name="personalEmail"
                  value={formData.personalEmail}
                  onChange={handleInputChange}
                  className="new-employee-form-input"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="form-label">Teléfono</Form.Label>
                <PhoneInput
                  international
                  defaultCountry="CO"
                  value={formData.cellPhone}
                  onChange={handlePhoneChange}
                  className="new-employee-form-input-phone"
                />
              </Form.Group>
            </Col>

            {canManageRoles() && (
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="form-label">Rol</Form.Label>
                  <Form.Control
                    as="select"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    className="new-employee-form-input"
                  >
                    <option
                      className="new-employee-form-input-dropdown-option"
                      value="USER"
                    >
                      Usuario
                    </option>
                    <option
                      className="new-employee-form-input-dropdown-option"
                      value="SUPERVISOR"
                    >
                      Supervisor
                    </option>
                    <option
                      className="new-employee-form-input-dropdown-option"
                      value="ADMIN"
                    >
                      Administrador
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
          </Row>

          {!isEditing && (
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="form-label">Contraseña</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="new-employee-form-input"
                  />
                  <Form.Text
                    className="text-muted"
                    style={{ color: "rgba(255,255,255,0.6) !important" }}
                  >
                    La contraseña debe tener al menos 8 caracteres y una letra
                    mayúscula
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    La contraseña es requerida
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label className="form-label">Confirmar Contraseña</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="new-employee-form-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    La confirmación de contraseña es requerida
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={6}>
              <FormGroup>
                <Form.Label className="form-label">Permisos</Form.Label>
                <Select
                  isMulti
                  options={permissionsOptions}
                  value={permissionsOptions.filter((option) =>
                    !editingUser
                      ? userPermissions.includes(option.value)
                      : editingUser.permissions.includes(option.value)
                  )}
                  onChange={handlePermissionsChange}
                  placeholder="Selecciona permisos..."
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "#333",
                      borderColor: "#666",
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      minHeight: "38px",
                    }),
                    menu: (styles) => ({
                      ...styles,
                      backgroundColor: "#333",
                    }),
                    option: (styles, { isFocused, isSelected }) => ({
                      ...styles,
                      backgroundColor: isFocused
                        ? "#555"
                        : isSelected
                        ? "#444"
                        : "#333",
                      color: "white",
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: "#555",
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: "white",
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#777",
                        color: "white",
                      },
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      color: "white",
                    }),
                  }}
                  closeMenuOnSelect={false}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Form.Label className="form-label">Grupos</Form.Label>
                <Select
                  isClearable
                  isMulti
                  options={userGroupOptions}
                  value={userGroupOptions.filter((option) =>
                    editingUser
                      ? editingUser.userGroups.includes(option.value)
                      : newUserForm.userGroups.includes(option.value)
                  )}
                  closeMenuOnSelect={false}
                  onChange={(selectedOptions) => {
                    const userGroups = selectedOptions.map(
                      (option) => option.value
                    );
                    if (editingUser) {
                      setEditingUser((prev) => ({
                        ...prev,
                        userGroups,
                      }));
                    } else {
                      setNewUserForm((prev) => ({
                        ...prev,
                        userGroups,
                      }));
                    }
                  }}
                  placeholder="Seleccionar grupos..."
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "#333",
                      borderColor: "#666",
                      color: "white",
                      fontFamily: "Poppins, sans-serif",
                      minHeight: "38px",
                    }),
                    menu: (styles) => ({
                      ...styles,
                      backgroundColor: "#333",
                    }),
                    option: (styles, { isFocused, isSelected }) => ({
                      ...styles,
                      backgroundColor: isFocused
                        ? "#555"
                        : isSelected
                        ? "#444"
                        : "#333",
                      color: "white",
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: "#555",
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: "white",
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#777",
                        color: "white",
                      },
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      color: "white",
                    }),
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="form-actions">
            <Button
              type="submit"
              className="action-button submit"
              disabled={loading}
            >
              {loading ? (
                <div className="button-spinner-container">
                  <Spinner size="sm" className="button-spinner" />
                </div>
              ) : isEditing ? (
                "Guardar Cambios"
              ) : (
                "Crear Usuario"
              )}
            </Button>
            <Button
              className="action-button cancel"
              onClick={() => {
                if (isEditing) {
                  setEditingUser(null);
                } else {
                  setCreatingNewUser(false);
                }
                clearNewUserForm();
                setFormValidated(false);
              }}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <div className="business-backoffice-container">
      <ToastContainer />
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <Row>
            <Col xs={6}>
              {!creatingNewUser && !editingUser && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => setCreatingNewUser(true)}
                  >
                    +
                  </div>
                  <h5 className="add-employee-text">Crear usuario</h5>
                </div>
              )}
              {(creatingNewUser || editingUser) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="add-employee-button"
                    onClick={() => {
                      setCreatingNewUser(false);
                      setEditingUser(null);
                      setUserPermissions([]);
                    }}
                  >
                    {"<"}
                  </div>
                  <h5 className="add-employee-text">Atras</h5>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={11} style={{ margin: "auto" }}>
          {loading ? (
            <div className="spinner-container">
              <Spinner className="main-spinner" />
            </div>
          ) : (
            <>
              {!creatingNewUser &&
                !editingUser &&
                mantisBusinessUsers.length > 0 && (
                  <div className="employees-table">
                    <Table>
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Documento</th>
                          <th>Cargo</th>
                          <th>Email</th>
                          <th>Teléfono</th>
                          <th>Rol</th>
                          <th>Permisos</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mantisBusinessUsers.map((user) => (
                          <tr key={user._id}>
                            <td>{`${user.firstName} ${user.lastName}`}</td>
                            <td>{user.documentId || "-"}</td>
                            <td>{user.position || "-"}</td>
                            <td>
                              {user.corporateEmail || user.personalEmail || "-"}
                            </td>
                            <td>
                              {user.cellPhone ? `+${user.cellPhone}` : "-"}
                            </td>
                            <td>
                              {user.mainUser
                                ? "Administrador"
                                : user.supervisorUser
                                ? "Supervisor"
                                : "Usuario"}
                            </td>
                            <td className="permissions-column">
                              {formatPermissions(user.permissions, user._id)}
                            </td>
                            <td>
                              <div className="user-actions">
                                <Button
                                  className="action-button edit"
                                  onClick={() => {
                                    setEditingUser({
                                      ...user,
                                      password: "",
                                      confirmPassword: "",
                                      role: user.mainUser
                                        ? "ADMIN"
                                        : user.supervisorUser
                                        ? "SUPERVISOR"
                                        : "USER",
                                      userGroups:
                                        user.groups.map(
                                          (group) => group.groupId
                                        ) || [],
                                    });
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                                <Button
                                  className="action-button delete"
                                  onClick={() => handleDeleteUser(user._id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              {(creatingNewUser || editingUser) &&
                renderUserForm(!!editingUser)}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(BusinessUsers);
