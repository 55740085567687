import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { FileInput } from "lucide-react";

import "../../styles/businessBackofficeStyles.scss";
import {
  priorityOptions,
  tipificationOptions,
  colombianCities,
  documentTypeOptions,
  responseMediumOptions,
  employeesByDependence,
  groupsByTipification,
  classesByGroup,
  receptionMediumOptions,
} from "../../../config";
import { backUrl, preMyceliumURL, mantisApiKey } from "../../../utils/backURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getGroupUsers,
  getMantisBusinessGroups,
} from "../../../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

const formatEmail = (email) => {
  if (!email) return "";
  return email
    .trim()
    .replace(/\s+/g, "") // Elimina todos los espacios
    .replace(/[\u00A0]/g, "") // Elimina el carácter específico \xa0
    .replace(/[^\x00-\x7F]/g, "") // Elimina todos los caracteres no ASCII
    .toLowerCase(); // Convierte a minúsculas
};

const formatText = (text) => {
  if (!text) return "";
  return text
    .replace(/\s+/g, "") // Elimina todos los espacios
    .replace(/[\u00A0]/g, "") // Elimina el carácter específico \xa0
    .replace(/[^\x00-\x7F]/g, "") // Elimina todos los caracteres no ASCII
    .trim(); // Elimina espacios al inicio y final
};

function DocumentActions(props) {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [previewFile, setPreviewFile] = useState(null);
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [radicado, setRadicado] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState({
    Radicado: "",
    "Fecha de Ingreso": getCurrentDate(),
    Prioridad: priorityOptions[0]?.value || "",
    Tipificación: tipificationOptions[0]?.value || "",
    Grupo: groupsByTipification[tipificationOptions[0]?.value] || [],
    "Clase Correspondencia":
      classesByGroup[
        groupsByTipification[tipificationOptions[0]?.value][0]?.value
      ]?.[0]?.value || "",
    "Medio Recepción": receptionMediumOptions[0]?.value || "",
    Dependencia: "",
    nombreDependencia: "",
    Para: "",
    IdUsuarioEncargado: "",
    Asunto: "",
    "No. Hojas": "",
    "Número de Comunicación": "",
    "Nombre Remitente": "",
    "Tipo de Documento": documentTypeOptions[0]?.value || "",
    "Número de Documento": "",
    Dirección: "",
    Email: "",
    "No. Celular": "",
    País: "COLOMBIA",
    Ciudad: colombianCities[0]?.value || "",
    "Fecha de Remisión": getCurrentDate(),
    "Medio de Respuesta": responseMediumOptions[0]?.value || "",
    "Copias del Rótulo": "",
    "Rótulo Automático": false,
    "Rótulo Papel": false,
    "Requiere Respuesta": false,
    "Observaciones del radicado": "",
    Tipo: "externo",
  });
  const [predictedFields, setPredictedFields] = useState([]);
  const [availableGroups, setAvailableGroups] = useState(
    groupsByTipification[tipificationOptions[0]?.value] || []
  );
  const [availableClasses, setAvailableClasses] = useState([]);

  //Dependencies
  const [dependenceOptions, setDependenceOptions] = useState([]);
  const [loadingDependencies, setLoadingDependencies] = useState(true);

  //Employees
  const [employees, setEmployees] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);

  useEffect(() => {
    generateRadicado();
    loadGroups();
  }, []);

  const loadGroups = async () => {
    const res = await getMantisBusinessGroups(
      props.mantisBusiness._id,
      props.token
    );
    if (res.success) {
      setDependenceOptions(
        res.groups.map((group) => {
          return {
            label: group.name,
            value: group._id,
          };
        })
      );
      setFormValues({
        ...formValues,
        Dependencia: dependenceOptions[0]?.value || "",
        nombreDependencia: dependenceOptions[0]?.label || "",
      });
    }
    setLoadingDependencies(false);
  };

  const generateRadicado = () => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const sequentialNumber = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");

    const radicadoNumber = `${year}${month}${sequentialNumber}`;
    setRadicado(radicadoNumber);
    setFormValues((prev) => ({ ...prev, Radicado: radicadoNumber }));
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("choose-files");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleTipificationChange = (e) => {
    const selectedTipification = e.target.value;

    const filteredGroups = groupsByTipification[selectedTipification] || [];
    setAvailableGroups(filteredGroups);

    setFormValues((prev) => ({
      ...prev,
      Tipificación: selectedTipification,
      Grupo: "",
      "Clase Correspondencia": "",
    }));

    setAvailableClasses([]);
  };

  const handleGroupChange = (e) => {
    const selectedGroup = e.target.value;

    const filteredClasses = classesByGroup[selectedGroup] || [];
    setAvailableClasses(filteredClasses);

    setFormValues((prev) => ({
      ...prev,
      Grupo: selectedGroup,
      "Clase Correspondencia": "",
    }));
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const filePromises = Array.from(selectedFiles).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.replace(
            /^data:application\/pdf;base64,/,
            ""
          );
          const sizeInBytes = Math.ceil((base64String.length * 3) / 4);
          const mimeType =
            reader.result.match(/data:(.*);base64/)?.[1] || "application/pdf";
          const fileExtension = file.name.split(".").pop().toLowerCase();

          resolve({
            name: file.name,
            base64: base64String,
            type: mimeType,
            size: sizeInBytes,
            extension: fileExtension,
          });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const filesData = await Promise.all(filePromises);
      setFiles(filesData);
      setPreviewFile(filesData[0]);
      setIsPreviewModalOpen(true);

      const fileInput = document.getElementById("choose-files");
      if (fileInput) {
        fileInput.value = "";
      }
    } catch (error) {
      console.error("Error processing files:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScan = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backUrl}/scan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error al escanear el documento");
      }

      const data = await response.json();

      // Crear un nuevo archivo con los datos del escaneo
      const scannedFile = {
        name: `scan_${new Date().toISOString()}.pdf`,
        base64: data.base64,
        type: "application/pdf",
        size: Math.ceil((data.base64.length * 3) / 4),
      };

      setFiles((prevFiles) => [...prevFiles, scannedFile]);

      if (!isPreviewModalOpen) {
        setPreviewFile(scannedFile);
        setIsPreviewModalOpen(true);
      }
    } catch (error) {
      console.error("Error al escanear:", error);
      alert("Error al escanear el documento");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitFiles = async () => {
    const url = `${backUrl}/upload/PQRS`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          files,
          mantisBusinessId: props.mantisBusiness._id,
        }),
      });

      if (!response.ok) {
        throw new Error("Error al enviar los archivos");
      }

      const data = await response.json();
      console.log("DATA desde el servidor:", data);

      if (!Array.isArray(data.responses) || data.responses.length === 0) {
        console.error("Formato de respuesta inesperado:", data);
        throw new Error("La respuesta del servidor no contiene datos válidos");
      }

      const responseObject = data.responses[0];
      setFiles((prev) =>
        prev.map((file) => ({
          ...file,
          file_url: responseObject.file_url,
          remote_file: responseObject.remote_file,
        }))
      );

      const updatedValues = { ...formValues };
      const predicted = [];

      if (responseObject["Tipificación"]) {
        const filteredGroups =
          groupsByTipification[responseObject["Tipificación"]] || [];
        setAvailableGroups(filteredGroups);

        if (
          responseObject["Grupo"] &&
          filteredGroups.some((g) => g.value === responseObject["Grupo"])
        ) {
          const filteredClasses = classesByGroup[responseObject["Grupo"]] || [];
          setAvailableClasses(filteredClasses);
        }
      }

      if (responseObject["Dependencia"]) {
        let dependenceId = dependenceOptions.find(
          (dep) => dep.label === responseObject["Dependencia"]
        )?.value;
        handleDependencyChange(dependenceId || dependenceOptions[0]?.value);

        /* const selectedEmployees =
          employeesByDependence[responseObject["Dependencia"]] || [];
        setAvailableEmployees(selectedEmployees);

        if (responseObject["Para"]) {
          const isValidEmployee = selectedEmployees.some(
            (emp) => emp.value === responseObject["Para"]
          );
          if (!isValidEmployee) {
            responseObject["Para"] = selectedEmployees[0]?.value || "";
          }
        } else if (selectedEmployees.length > 0) {
          responseObject["Para"] = selectedEmployees[0].value;
        } */
      }

      for (const [key, value] of Object.entries(responseObject)) {
        if (
          value !== undefined &&
          value !== null &&
          value !== "NULL" &&
          value !== "" &&
          value !== "null"
        ) {
          updatedValues[key] = value;
          predicted.push(key);
        }
      }
      setFormValues(updatedValues);
      setPredictedFields(predicted);
      setIsPreviewModalOpen(false);
      resetFileInput();
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
      resetFileInput();
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = (index) => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
    setPreviewFile(newFiles[0]);
  };

  const clearForm = () => {
    setFormValues({
      Radicado: "",
      "Fecha de Ingreso": getCurrentDate(),
      Prioridad: priorityOptions[0]?.value || "",
      Tipificación: tipificationOptions[0]?.value || "",
      Grupo: groupsByTipification[tipificationOptions[0]?.value] || [],
      "Clase Correspondencia":
        classesByGroup[
          groupsByTipification[tipificationOptions[0]?.value][0]?.value
        ]?.[0]?.value || "",
      "Medio Recepción": receptionMediumOptions[0]?.value || "",
      Dependencia: dependenceOptions[0]?.value || "",
      nombreDependencia: dependenceOptions[0]?.label || "",
      Para: "",
      IdUsuarioEncargado: "",
      Asunto: "",
      "No. Hojas": "",
      "Número de Comunicación": "",
      "Nombre Remitente": "",
      "Tipo de Documento": documentTypeOptions[0]?.value || "",
      "Número de Documento": "",
      Dirección: "",
      Email: "",
      "No. Celular": "",
      País: "COLOMBIA",
      Ciudad: colombianCities[0]?.value || "",
      "Fecha de Remisión": getCurrentDate(),
      "Medio de Respuesta": responseMediumOptions[0]?.value || "",
      "Copias del Rótulo": "",
      "Rótulo Automático": false,
      "Rótulo Papel": false,
      "Requiere Respuesta": false,
      "Observaciones del radicado": "",
      Tipo: "externo",
    });
    setFiles([]);
    setPreviewFile(null);
    setIsPreviewModalOpen(false);
    generateRadicado();
  };

  const handleSubmitForm = async () => {
    const selectedEmployee = availableEmployees.find(
      (emp) => emp.value === formValues["IdUsuarioEncargado"]
    );

    const cleanFormData = {
      ...formValues,
      Email: formatEmail(formValues["Email"]),
      Asunto: formValues["Asunto"],
      Para: selectedEmployee?.fullName || formValues["Para"],
    };

    if (files.length === 0) {
      toast.error("Debes adjuntar al menos un archivo para crear el radicado", {
        position: "top-right",
        theme: "dark",
      });
      return;
    }

    const premicilioData = {
      form_data: cleanFormData,
      document_id: null,
      main_key: formValues["Radicado"],
      mantis_business_id: props.mantisBusiness._id,
      file_metadata: {
        file_name: files[0].name,
        file_size: files[0].size,
        file_type: files[0].type,
        file_base64: files[0].base64,
        file_url: null,
        remote_file: files[0].remote_file,
      },
      base64_string: files[0].base64,
      embed_file_group: null,
      document_group_object: null,
      client_type: "PQRS",
      file_url: null,
      file_name: files[0].name,
      file_size: files[0].size,
      file_type: files[0].type,
      file_date: formValues["Fecha de Ingreso"],
      file_extension: files[0].extension,
      email_date: null,
      subject: formValues["Asunto"],
      attachments: files.map((file) => ({
        filename: file.name,
        mimeType: file.type,
        attachmentId: file.id || null,
      })),
      receiver_email_address:
        selectedEmployee?.email || formValues.emailUsuario || "",
      main_id: null,
      email_type: "VENTANILLA_UNICA",
    };

    console.log(
      "==================== DATOS A ENVIAR A PREMICELIO ===================="
    );
    console.log("Empleado seleccionado:", {
      nombre: selectedEmployee?.fullName,
      id: selectedEmployee?.value,
      email: selectedEmployee?.email,
      paraField: cleanFormData.Para,
    });
    console.log("Información del formulario:", {
      emailGuardado: formValues.emailUsuario,
      emailFinal: premicilioData.receiver_email_address,
      asunto: premicilioData.subject,
      radicado: premicilioData.main_key,
    });
    console.log("Datos completos:", JSON.stringify(premicilioData, null, 2));
    console.log(
      "================================================================="
    );

    if (!premicilioData.receiver_email_address) {
      toast.error(
        "No se ha seleccionado un usuario con correo electrónico válido",
        {
          position: "top-right",
          theme: "dark",
        }
      );
      return;
    }

    console.log("Datos a enviar:", {
      selectedEmployee,
      receiverEmail: premicilioData.receiver_email_address,
      formValues: formValues,
    });

    try {
      setLoading(true);
      const premicilioResponse = await fetch(`${preMyceliumURL}/process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": mantisApiKey,
        },
        mode: "cors",
        body: JSON.stringify(premicilioData),
      });
      if (!premicilioResponse.ok) {
        const errorData = await premicilioResponse.json();
        throw new Error(
          errorData.message || "Error al crear el radicado en premicelio"
        );
      }

      const responseData = await premicilioResponse.json();

      setLoading(false);

      toast.success(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            color: "#fff",
          }}
        >
          <strong style={{ fontSize: "16px" }}>
            ¡Radicado creado exitosamente!
          </strong>
        </div>,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: {
            background: "linear-gradient(to right, #00b09b, #96c93d)",
          },
          progressStyle: {
            background: "rgba(255,255,255,0.7)",
          },
          className: "success-toast",
        }
      );
      clearForm();
      setIsPreviewModalOpen(false);
    } catch (error) {
      console.error("Error al crear radicado en premicelio:", error);
      alert(`Error al crear el radicado: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const renderFilePreviewModal = () => (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={() => setIsPreviewModalOpen(false)}
      className="file-preview-modal"
      overlayClassName="ReactModal__Overlay"
    >
      <div className="file-preview">
        {files.map((file, index) => (
          <div key={index} className="file-item">
            <h2>Previsualización de {file.name}</h2>
            <div className="preview-container">
              {previewFile && (
                <>
                  {previewFile.type.startsWith("image/") && (
                    <img
                      src={`data:${previewFile.type};base64,${previewFile.base64}`}
                      alt={previewFile.name}
                    />
                  )}
                  {previewFile.type === "application/pdf" && (
                    <iframe
                      src={`data:application/pdf;base64,${previewFile.base64}`}
                      title={previewFile.name}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="modal-actions">
        <button
          className="cancel-button"
          onClick={() => setIsPreviewModalOpen(false)}
        >
          Cerrar
        </button>
        <button
          className="submit-button"
          onClick={() => {
            handleSubmitFiles();
            setLoading(true);
          }}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner color="dark" size="sm" style={{ marginRight: "8px" }} />
              Cargando documento...
            </>
          ) : (
            "Enviar"
          )}
        </button>
      </div>
    </Modal>
  );

  const sortOptions = (options) => {
    return [...options].sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleDependencyChange = async (selectedDependency) => {
    setLoadingEmployees(true);
    setFormValues({
      ...formValues,
      Dependencia: dependenceOptions.find(
        (dependence) => dependence.value === selectedDependency
      ).value,
      nombreDependencia: dependenceOptions.find(
        (dependence) => dependence.value === selectedDependency
      ).label,
    });

    const res = await getGroupUsers(
      props.token,
      props.mantisBusiness._id,
      selectedDependency
    );

    if (res.success) {
      const selectedEmployees = res.users.map((user) => ({
        label: user.firstName + " " + user.lastName,
        value: user._id,
        email: formatEmail(user.email),
        fullName: `${user.firstName} ${user.lastName}`,
      }));

      setEmployees(selectedEmployees);
      setAvailableEmployees(selectedEmployees);

      if (res.users.length >= 1) {
        const firstUser = res.users[0];
        setFormValues({
          ...formValues,
          Dependencia: dependenceOptions.find(
            (dependence) => dependence.value === selectedDependency
          ).value,
          nombreDependencia: dependenceOptions.find(
            (dependence) => dependence.value === selectedDependency
          ).label,
          Para: `${firstUser.firstName} ${firstUser.lastName}`,
          IdUsuarioEncargado: firstUser._id,
          emailUsuario: formatEmail(firstUser.email),
        });
      }
    }

    setLoadingEmployees(false);
  };

  const handleCreateRadicado = () => {
    const excludedFields = [
      "Fecha de Remisión",
      "Rótulo Automático",
      "Rótulo Papel",
      "Requiere Respuesta",
      "Observaciones",
      "Radicado",
    ];

    const emptyFields = [];
    for (const [key, value] of Object.entries(formValues)) {
      if (
        !excludedFields.includes(key) &&
        (!value || value === "" || value === "null" || value === null)
      ) {
        emptyFields.push(key);
      }
    }

    if (emptyFields.length > 0) {
      toast.error(
        <div>
          <strong>Campos requeridos faltantes:</strong>
          <ul style={{ marginTop: "10px", listStyle: "none", padding: 0 }}>
            {emptyFields.map((field, index) => (
              <span key={index}>
                {field}
                {index < emptyFields.length - 1 ? ", " : ""}
              </span>
            ))}
          </ul>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        }
      );
      return;
    }

    handleSubmitForm();
  };
  const renderSection = (title, fields) => (
    <div className="document-section">
      <h4 className="document-analysis-module-title">{title}</h4>
      {fields.map((field, index) => (
        <div key={index} className="modal-field">
          <label>{field.label}</label>
          {field.label === "Radicado" ||
          field.label === "Fecha de Ingreso" ||
          field.label === "País" ? (
            <div className="non-editable-field">{formValues[field.label]}</div>
          ) : field.type === "select" ? (
            <>
              {field.label === "Dependencia" && loadingDependencies ? (
                <Spinner
                  style={{
                    color: "white",
                    alignSelf: "center",
                  }}
                  size={"sm"}
                />
              ) : field.label === "Para" && loadingEmployees ? (
                <Spinner
                  style={{
                    color: "white",
                    alignSelf: "center",
                  }}
                  size={"sm"}
                />
              ) : (
                <select
                  value={formValues[field.label] || ""}
                  onChange={(e) => {
                    if (field.onChange) {
                      field.onChange(e);
                    } else if (field.label === "Dependencia") {
                      handleDependencyChange(e.target.value);
                    } else {
                      setFormValues({
                        ...formValues,
                        [field.label]: e.target.value,
                      });
                    }
                  }}
                  className={`${
                    predictedFields.includes(field.label) ? "predicted" : ""
                  } ${!formValues[field.label] ? "required" : ""}`}
                  required
                >
                  <option value="" disabled>
                    {field.label === "Dependencia"
                      ? "Seleccione una dependencia"
                      : field.label === "Para"
                      ? "Seleccione un empleado"
                      : `Seleccione ${field.label}`}
                  </option>

                  {field.label === "Para"
                    ? sortOptions(availableEmployees)
                        .filter((option) => option.value !== "")
                        .map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))
                    : sortOptions(field.options)
                        .filter((option) => option.value !== "")
                        .map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                </select>
              )}
            </>
          ) : field.type === "checkbox" ? (
            <input
              type="checkbox"
              name={field.label}
              checked={formValues[field.label] || false}
              onChange={(e) => {
                if (field.label === "Rótulo Automático") {
                  setFormValues({
                    ...formValues,
                    "Rótulo Automático": e.target.checked,
                    "Rótulo Papel": e.target.checked
                      ? false
                      : formValues["Rótulo Papel"],
                  });
                } else if (field.label === "Rótulo Papel") {
                  setFormValues({
                    ...formValues,
                    "Rótulo Papel": e.target.checked,
                    "Rótulo Automático": e.target.checked
                      ? false
                      : formValues["Rótulo Automático"],
                  });
                } else {
                  setFormValues({
                    ...formValues,
                    [field.label]: e.target.checked,
                  });
                }
              }}
            />
          ) : field.type === "file" ? (
            <>
              <div
                style={{
                  display: "flex",
                }}
              >
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="file-card"
                    onClick={(e) => {
                      setPreviewFile(file);
                      setIsPreviewModalOpen(true);
                    }}
                  >
                    <i className="fas fa-paperclip"></i>
                    <h2
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      {file.name}
                    </h2>
                    <i
                      className="fas fa-times"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFile(index);
                      }}
                    ></i>
                  </div>
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <input
                  id="choose-files"
                  type="file"
                  className="document-analysis-module-input"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "flex", color: "white" }}
                />
              </div>
            </>
          ) : field.type === "date" && field.label === "Fecha de Remisión" ? (
            <input
              type="date"
              value={formValues[field.label] || getCurrentDate()}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              className={`${
                predictedFields.includes(field.label) ? "predicted" : ""
              }`}
            />
          ) : (
            <input
              type={field.type}
              value={formValues[field.label] || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              readOnly={field.readOnly}
              className={`${
                predictedFields.includes(field.label) ? "predicted" : ""
              } ${!formValues[field.label] ? "required" : ""}`}
              required
            />
          )}
        </div>
      ))}
    </div>
  );

  const formSections = [
    {
      title: "Adjuntos",
      fields: [
        {
          label: "Adjunta o escanea los archivos del radicado",
          type: "file",
          multiple: true,
          onChange: handleFileChange,
        },
      ],
    },
    {
      title: "Identificación",
      fields: [
        // { label: "Radicado", type: "text", readOnly: true, value: radicado },
        {
          label: "Fecha de Ingreso",
          type: "text",
          readOnly: true,
          value: getCurrentDate(),
        },
        { label: "Prioridad", type: "select", options: priorityOptions },
        {
          label: "Tipificación",
          type: "select",
          options: tipificationOptions,
          onChange: handleTipificationChange,
        },
        {
          label: "Grupo",
          type: "select",
          options: availableGroups,
          onChange: handleGroupChange,
        },
        {
          label: "Clase Correspondencia",
          type: "select",
          options: availableClasses,
        },
        {
          label: "Medio Recepción",
          type: "select",
          options: receptionMediumOptions,
          defaultValue: "VENTANILLA ÚNICA",
        },
      ],
    },
    {
      title: "Destinatario (Usuario)",
      fields: [
        { label: "Dependencia", type: "select", options: dependenceOptions },
        { label: "Para", type: "select", options: employees },
        { label: "Asunto", type: "text" },
        { label: "No. Hojas", type: "number" },
        { label: "Número de Comunicación", type: "number" },
      ],
    },
    {
      title: "Remitente",
      fields: [
        { label: "Nombre Remitente", type: "text" },
        {
          label: "Tipo de Documento",
          type: "select",
          options: documentTypeOptions,
        },
        { label: "Número de Documento", type: "number" },
        { label: "Dirección", type: "text" },
        { label: "Email", type: "email" },
        { label: "No. Celular", type: "number" },
        { label: "País", type: "text", readOnly: true, value: "COLOMBIA" },
        { label: "Ciudad", type: "select", options: colombianCities },
        {
          label: "Fecha de Remisión",
          type: "date",
          defaultValue: getCurrentDate(),
        },
        {
          label: "Medio de Respuesta",
          type: "select",
          options: responseMediumOptions,
        },
        { label: "Copias del Rótulo", type: "number" },
        { label: "Rótulo Automático", type: "checkbox" },
        { label: "Rótulo Papel", type: "checkbox" },
        { label: "Requiere Respuesta", type: "checkbox" },
      ],
    },
    {
      title: "Observaciones",
      fields: [{ label: "Observaciones del radicado", type: "text" }],
    },
  ];

  return (
    <>
      <div className="document-actions-container-2">
        {formSections.map((section, index) => (
          <div key={index} className="section-divider">
            {renderSection(section.title, section.fields)}
          </div>
        ))}
        {files.length > 0 && renderFilePreviewModal()}
        <button
          onClick={handleCreateRadicado}
          className="create-radicado-button"
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner color="light" size="sm" style={{ marginRight: "8px" }} />
              Creando radicado...
            </>
          ) : (
            "Crear Radicado"
          )}
        </button>
      </div>
      <ToastContainer />
    </>
  );
}

export default connect(select)(DocumentActions);
