import React, { useState } from 'react';
import { Table, Row, Col } from 'reactstrap';
import pdfIcon from "../../../Assets/img/icons/pdf.png";
import imageIcon from "../../../Assets/img/icons/image.png";
import excelIcon from "../../../Assets/img/icons/excel.png";
import gmailIcon from "../../../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import "../../styles/businessBackofficeStyles.scss";

function DocumentTable({ documentGroups }) {
  const [selectedGroup, setSelectedGroup] = useState(null);

  const openModal = (group) => {
    setSelectedGroup(group);
  };

  const closeModal = () => {
    setSelectedGroup(null);
  };

  return (
    <div>
      <h3 className="business-extraction-table-title">Documentos Relacionados</h3>
      <Table borderless>
        <thead>
          <tr className="business-extraction-table-header">
            <th>Identificador</th>
            <th>Fecha Recibido</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {documentGroups.map((group, index) => (
            <tr
              key={group.mainKey}
              className="business-extraction-table-row"
              onClick={() => openModal(group)}
            >
              <td>{group.mainKey}</td>
              <td>{group.dateCreated}</td>
              <td>{group.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedGroup && (
        <>
          <div className="custom-modal-backdrop" onClick={closeModal}></div>
          <div className="custom-modal">
            <button className="close-button" onClick={closeModal}>
                &times;
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3 className="document-analysis-module-title">
                  Documentos relacionados con el identificador {selectedGroup.mainKey}
                </h3>
              </div>
            </div>
            <Row>
              {selectedGroup.documents.map((doc, index) => (
                <Col
                  key={index}
                  xs={6}
                  onClick={() => window.open(doc.url, "_blank")}
                >
                  <div className="document-analysis-module-related-document-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                            doc.url.includes("pdf")
                            ? pdfIcon
                            : doc.url.includes("png") ||
                                doc.url.includes("jpg") ||
                                doc.url.includes("jpeg")
                            ? imageIcon
                            : doc.url.includes("xlsx") ||
                                doc.url.includes("xls")
                            ? excelIcon
                            : gmailIcon
                        }
                        alt="file"
                        className="document-analysis-module-related-document-icon"
                      />
                    </div>
                    <h5 className="document-analysis-module-related-document-name">
                      {doc.name}
                    </h5>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </div>
  );
}

export default DocumentTable;