"use client"

import { useState } from "react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table"
import { Button } from "../../../components/ui/button"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { Badge } from "../../../components/ui/badge"

export default function FlowTable({ data, visibleColumns, onRowClick, columnLabels = {} }) {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const totalPages = Math.ceil(data.length / itemsPerPage)

  const startIndex = (currentPage - 1) * itemsPerPage
  const paginatedData = data.slice(startIndex, startIndex + itemsPerPage)

  const formatCellValue = (key, value) => {
    if (key === "status") {
      return (
        <Badge
          className={
            value === "complete"
              ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
              : value === "pending"
              ? "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-300"
              : "bg-slate-100 text-slate-800 dark:bg-slate-800 dark:text-slate-300"
          }
        >
          {value}
        </Badge>
      );
    } else if (key === "isVerified") {
      return value ? "Yes" : "No";
    } else if (key === "hasErrors") {
      return value ? <Badge variant="destructive">Yes</Badge> : "No";
    } else if (key === "date" && value) {
      return new Date(value).toLocaleDateString();
    } else if (/^\d+$/.test(value)) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value;
    }
  }

  // Obtener el nombre formateado de la columna o usar el nombre original
  const getColumnLabel = (column) => {
    return columnLabels[column] || column;
  }

  return (
    <div>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              {visibleColumns.map((column) => (
                <TableHead key={column} className="font-semibold">
                  {getColumnLabel(column)}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData.length > 0 ? (
              paginatedData.map((row) => (
                <TableRow
                  key={row.id}
                  className="cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-700/50 transition-colors"
                  onClick={() => onRowClick(row)}
                >
                  {visibleColumns.map((column) => (
                    <TableCell key={`${row.id}-${column}`}>{formatCellValue(column, row[column])}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={visibleColumns.length} className="text-center py-8 text-slate-500">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-between px-4 py-4 border-t border-slate-200 dark:border-slate-700">
          <div className="text-sm text-slate-500 dark:text-slate-400">
            Showing {startIndex + 1}-{Math.min(startIndex + itemsPerPage, data.length)} of {data.length} items
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="icon"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <span className="text-sm text-slate-600 dark:text-slate-300">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

