import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PreventBackNavigation = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const handleBackButton = (event) => {
        event.preventDefault();
        navigate(1); // Prevents going back
      };

      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", handleBackButton);

      return () => {
        window.removeEventListener("popstate", handleBackButton);
      };
    }
  }, [isAuthenticated, navigate]);

  return null;
};

export default PreventBackNavigation;
