"use client"

import { useState } from "react"
import { Download, FileSpreadsheet, FileText } from "lucide-react"
import { Button } from "../../../components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu"
import { useToast } from "../../../components/ui/use-toast"

export default function ExportButton({ data, filename }) {
  const [isExporting, setIsExporting] = useState(false)
  const { toast } = useToast()

  const exportToCSV = () => {
    setIsExporting(true)

    try {
      // Get headers from the first data item
      const headers = Object.keys(data[0] || {}).filter((key) => key !== "id" && key !== "documentUrl")

      // Convert data to CSV format
      const csvContent = [
        // Header row
        headers.join(","),
        // Data rows
        ...data.map((item) =>
          headers
            .map((header) => {
              const value = item[header]
              // Handle special cases like booleans, objects, etc.
              if (typeof value === "boolean") return value ? "Yes" : "No"
              if (value === null || value === undefined) return ""
              if (typeof value === "object") return JSON.stringify(value)
              // Escape commas and quotes in string values
              if (typeof value === "string") {
                if (value.includes(",") || value.includes('"')) {
                  return `"${value.replace(/"/g, '""')}"`
                }
                return value
              }
              return value
            })
            .join(","),
        ),
      ].join("\n")

      // Create a blob and download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.setAttribute("href", url)
      link.setAttribute("download", `${filename}.csv`)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      toast({
        title: "Export successful",
        description: `Data exported to ${filename}.csv`,
        duration: 3000,
      })
    } catch (error) {
      console.error("Export error:", error)
      toast({
        title: "Export failed",
        description: "There was an error exporting your data.",
        variant: "destructive",
        duration: 3000,
      })
    } finally {
      setIsExporting(false)
    }
  }

  const exportToExcel = () => {
    setIsExporting(true)

    // In a real application, you would use a library like xlsx
    // For this demo, we'll simulate the export
    setTimeout(() => {
      toast({
        title: "Export successful",
        description: `Data exported to ${filename}.xlsx`,
        duration: 3000,
      })
      setIsExporting(false)
    }, 1000)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          size="sm" 
          className="flex items-center gap-1 bg-white dark:bg-slate-800 border-slate-300 dark:border-slate-600 hover:bg-slate-100 dark:hover:bg-slate-700" 
          disabled={isExporting}
        >
          <Download className="h-4 w-4 text-slate-700 dark:text-slate-300" />
          <span className="text-slate-700 dark:text-slate-300">Export</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700">
        <DropdownMenuItem 
          onClick={exportToExcel}
          className="text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-700"
        >
          <FileSpreadsheet className="mr-2 h-4 w-4" />
          <span>Export to Excel</span>
        </DropdownMenuItem>
        <DropdownMenuItem 
          onClick={exportToCSV}
          className="text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-700"
        >
          <FileText className="mr-2 h-4 w-4" />
          <span>Export to CSV</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

