import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import "../../styles/businessBackofficeStyles.scss";
import pdfIcon from "../../../Assets/img/icons/pdf.png";
import FilesDragAndDrop from "../../../Extras/DragAndDrop/FilesDragAndDrop";
import { shortenText } from "../../../utils/formatText";
import {
  getVisionResponse,
  getMantisMissions,
  exportMantisMissions,
} from "../../../ApiCalls/mantisBusiness";
import BusinessMission from "./BusinessMission";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessMissions(props) {
  const [loading, setLoading] = useState(false);
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState(null);

  useEffect(() => {
    getMissions();
  }, []);

  useEffect(() => {
    if (selectedMission === null) {
      setMissions([]);
      getMissions();
    }
  }, [selectedMission]);

  const getMissions = async () => {
    try {
      setLoading(true);
      const res = await getMantisMissions(
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setMissions(res.mantisMissions);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  console.log("mantis missions", selectedMission);
  return (
    <div>
      {!selectedMission ? (
        <>
          <Row
            style={{
              marginTop: "50px",
            }}
          >
            <Col
              xs={11}
              style={{
                margin: "auto",
              }}
            >
              <Row>
                <Col xs={6}>
                  <h1 className="business-viewpanel-title">Misiones Mantis</h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={11} style={{ margin: "auto" }}>
              <h2 className="business-viewpanel-subtitle">
                ¡Programa tus misiones con Mantis y alcanza tus objetivos más
                rápido!
              </h2>
            </Col>
          </Row>

          <Row
            style={{
              marginTop: "40px",
            }}
          >
            <Col xs={11} style={{ margin: "auto" }}>
              <Row>
                {loading && (
                  <Col xs={3}>
                    <div
                      className="mantis-mission-container"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <Spinner
                        style={{
                          width: "3rem",
                          height: "3rem",
                          margin: "auto",
                          color: "white",
                        }}
                      />
                    </div>
                  </Col>
                )}
                {missions.map((mission, index) => {
                  return (
                    <Col
                      xs={3}
                      key={index}
                      style={{
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        className="mantis-mission-container"
                        onClick={() => setSelectedMission(mission)}
                      >
                        <h3 className="mantis-mission-title">{mission.name}</h3>
                        <p className="mantis-mission-description">
                          {shortenText(mission.description, 100)}
                        </p>
                      </div>
                    </Col>
                  );
                })}
                <Col xs={3}>
                  <div
                    className="mantis-new-mission-container"
                    onClick={() => {
                      setSelectedMission({
                        name: "Nueva misión",
                        prompt: "",
                        files: [],
                        description: "",
                      });
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.11)",
                        strokeWidth: 1.521,
                        stroke: "#FFF",
                        width: 65,
                        height: 65,
                        border: "1px solid rgba(255, 255, 255, 0.3)",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3
                        className="mantis-mission-title"
                        style={{
                          textAlign: "center",
                          fontSize: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        +
                      </h3>
                    </div>
                    <h3 className="mantis-mission-title">Nueva misión</h3>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <BusinessMission
          mantisMission={selectedMission}
          goBack={() => setSelectedMission(null)}
        />
      )}
    </div>
  );
}

export default connect(select)(BusinessMissions);
