"use client"

import { useState, useRef, useEffect } from "react"
import { Button } from "../../../components/ui/button"
import { Send } from "lucide-react"

const MessageInput = ({ onSendMessage, disabled = false, accentColor = "blue" }) => {
  const [message, setMessage] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const textareaRef = useRef(null)

  const getAccentColorClasses = () => {
    switch (accentColor) {
      case "orange":
        return {
          border: "border-orange-500",
          ring: "ring-orange-200",
          bg: "bg-orange-500",
          hoverBg: "hover:bg-orange-600",
          text: "text-orange-500",
        }
      default:
        return {
          border: "border-primary",
          ring: "ring-primary/20",
          bg: "bg-primary",
          hoverBg: "hover:bg-primary/90",
          text: "text-primary",
        }
    }
  }

  const colorClasses = getAccentColorClasses()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (message.trim() && !disabled) {
      onSendMessage(message)
      setMessage("")
      // Reset height after sending
      if (textareaRef.current) {
        textareaRef.current.style.height = "40px"
      }
    }
  }

  // Auto-resize textarea based on content
  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to get the correct scrollHeight
      textareaRef.current.style.height = "40px"
      // Set the height based on scrollHeight
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = scrollHeight > 120 ? "120px" : `${scrollHeight}px`
    }
  }, [message])

  return (
    <form onSubmit={handleSubmit} className="flex items-center w-full relative">
      <div className={`flex-1 relative transition-all duration-300 ${isFocused ? "scale-[1.01]" : ""}`}>
        <div className="relative flex items-center w-full">
          <textarea
            ref={textareaRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Escribe tu pregunta aquí..."
            className={`w-full pr-12 py-2 px-4 resize-none overflow-hidden transition-all duration-300 ease-in-out border rounded-full border-gray-200 focus:outline-none ${
              isFocused ? `${colorClasses.border} shadow-sm ring-1 ${colorClasses.ring}` : ""
            }`}
            style={{
              minHeight: "40px",
              maxHeight: "120px",
              lineHeight: "1.5",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault()
                handleSubmit(e)
              }
            }}
            disabled={disabled}
          />

          <Button
            type="submit"
            size="icon"
            disabled={disabled}
            className={`absolute right-1 top-1/2 -translate-y-1/2 h-8 w-8 rounded-full transition-all duration-300 ${
              message.trim()
                ? `${colorClasses.bg} ${colorClasses.hoverBg} hover:scale-110`
                : "bg-gray-200 hover:bg-gray-300"
            }`}
          >
            <Send
              className={`h-4 w-4 transition-all duration-300 ${message.trim() ? "text-white" : "text-gray-500"}`}
            />
          </Button>
        </div>
      </div>
    </form>
  )
}

export default MessageInput

