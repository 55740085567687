"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import FlowItem from "./FlowItem"
import { getFlows } from "../../../../ApiCalls/mantisFlowsManangment"

export default function FlowList({ onFlowSelect }) {
  const [flows, setFlows] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        // Quemamos el mantisBusinessId con "2"
        const mantisBusinessId = "2"
        const response = await getFlows(mantisBusinessId)
        
        if (response.success) {
          // Adaptamos los datos para que coincidan con el formato esperado
          const adaptedFlows = response.data.map(flow => ({
            id: flow.id,
            name: flow.name,
            status: mapApiStatusToUi(flow.status),
            description: "Flujo de trabajo automatizado",
            documentCount: 0,
            createdAt: new Date(flow.created_at).toLocaleDateString()
          }))
          
          setFlows(adaptedFlows)
        } else {
          setError("No se pudieron cargar los flujos")
        }
      } catch (err) {
        console.error("Error al cargar los flujos:", err)
        setError("Error al cargar los flujos")
      } finally {
        setLoading(false)
      }
    }

    fetchFlows()
  }, [])

  // Función para mapear los estados de la API a los estados de la UI
  const mapApiStatusToUi = (apiStatus) => {
    switch (apiStatus) {
      case "PREPARING":
        return "processing"
      case "READY":
        return "active"
      case "ERROR":
        return "error"
      default:
        return "processing"
    }
  }

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <FlowItem key={item} loading={true} />
        ))}
      </motion.div>
    )
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <h3 className="text-xl font-bold text-red-500 mb-2">Error</h3>
        <p className="text-slate-600 dark:text-slate-300">{error}</p>
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
      {flows.map((flow) => (
        <FlowItem key={flow.id} flow={flow} onClick={() => onFlowSelect(flow)} />
      ))}
    </motion.div>
  )
}

