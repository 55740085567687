import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Spinner } from "reactstrap";
import "../../styles/businessBackofficeStyles.scss";
import { getInternalMessages } from "../../../ApiCalls/mantisBusiness";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function Inbox(props) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const response = await getInternalMessages(
      props.mantisBusiness._id,
      props.token,
      props.user._id
    );
    if (response.success) {
      // Ordenar los mensajes por fecha de creación, de más reciente a más antigua
      const sortedMessages = [...response.receivedMessages, ...response.sentMessages].sort((a, b) => {
        const dateA = new Date(a.dateCreated);
        const dateB = new Date(b.dateCreated);
        return dateB - dateA; // Ordenar de más reciente a más antigua
      });
      setMessages(sortedMessages);
    } else {
      alert("Error al obtener los datos");
    }
    setLoading(false);
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={12} style={{ textAlign: "center" }}>
          <h1 className="business-viewpanel-title" style={{ color: "white"}}>Notificaciones</h1>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
            <Spinner color="white" style={{ alignSelf: "center" }} />
          </Col>
        </Row>
      ) : (
        <div>
          {messages.length === 0 ? (
            <h2
              style={{
                margin: "auto",
                fontSize: "1.5em",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                color: "white",
                textAlign: "center",
              }}
            >
              No hay notificaciones
            </h2>
          ) : (
            messages.map((message, index) => (
              <div key={index} className="internal-message-card">
                <div className="internal-message-subject">{message.subject}</div>
                <div className="internal-message-preview">{message.message}</div>
                <div className="internal-message-meta">
                  <span>De: {message.toEmails.join(", ")}</span>
                  <span>{new Date(message.dateCreated).toLocaleDateString()}</span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default connect(select)(Inbox);