"use client"

import { useState, useEffect } from "react"
import { Filter, Loader2, ChevronDown } from "lucide-react"
import { Button } from "../../../components/ui/button"
import { Badge } from "../../../components/ui/badge"
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover"
import { getWorkflowFilterOptions } from "../../../../ApiCalls/mantisFlowsManangment"
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "../../../components/ui/select"
import { Label } from "../../../components/ui/label"

export default function FilterControls({ data, activeFilters, onFilterToggle, flowId, clientId, onCategoriesUpdate }) {
  const [open, setOpen] = useState(false)
  const [filterCategories, setFilterCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Función para formatear nombres de categorías
  const formatCategoryName = (name) => {
    // Convertir snake_case a palabras separadas
    const words = name.split('_');
    
    // Capitalizar cada palabra y unirlas con espacios
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  // Función para formatear valores numéricos
  const formatNumericValue = (value) => {
    // Verificar si es un número
    if (!isNaN(parseFloat(value))) {
      // Formatear como moneda si parece ser un valor monetario (tiene decimales)
      if (value.includes('.')) {
        return new Intl.NumberFormat('es-CO', { 
          style: 'currency', 
          currency: 'COP',
          minimumFractionDigits: 2
        }).format(parseFloat(value));
      }
      // Formatear como número normal
      return new Intl.NumberFormat('es-CO').format(parseFloat(value));
    }
    return value;
  }

  // Función para formatear fechas
  const formatDateValue = (value) => {
    // Verificar si es una fecha en formato ISO
    if (/^\d{4}-\d{2}-\d{2}/.test(value)) {
      return new Date(value).toLocaleDateString('es-CO', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
    // Verificar si es una fecha en formato largo
    if (value.includes('GMT')) {
      return new Date(value).toLocaleDateString('es-CO', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    return value;
  }

  // Cargar opciones de filtrado desde la API
  useEffect(() => {
    const fetchFilterOptions = async () => {
      if (!flowId || !clientId) {
        console.log("FilterControls: No se puede cargar filtros - flowId o clientId faltantes", { flowId, clientId });
        return;
      }
      
      console.log("FilterControls: Iniciando carga de filtros para:", { clientId, flowId });
      setLoading(true);
      
      try {
        console.log("FilterControls: Realizando petición a la API...");
        const response = await getWorkflowFilterOptions(clientId, flowId);
        console.log("FilterControls: Respuesta recibida:", response);
        
        // Verificar que la respuesta tenga el formato esperado
        if (!response.success || !response.data) {
          throw new Error("Formato de respuesta inválido");
        }
        
        const filterOptions = response.data;
        
        // Transformar las opciones de filtrado al formato esperado por el componente
        const categories = [];
        
        // Ordenar las categorías alfabéticamente
        const sortedCategories = Object.keys(filterOptions).sort();
        
        // Recorrer las opciones de filtrado recibidas de la API
        sortedCategories.forEach((categoryName) => {
          console.log(`FilterControls: Procesando categoría: ${categoryName}`, filterOptions[categoryName]);
          const filters = [];
          
          // Determinar si la categoría parece contener fechas
          const isDateCategory = categoryName.toLowerCase().includes('date');
          
          // Determinar si la categoría parece contener valores numéricos
          const isNumericCategory = 
            categoryName.toLowerCase().includes('total') || 
            categoryName.toLowerCase().includes('profit') || 
            categoryName.toLowerCase().includes('revenue') ||
            categoryName.toLowerCase().includes('amount');
          
          // Ordenar los valores de la categoría
          let categoryValues = [...filterOptions[categoryName]];
          
          if (isDateCategory) {
            // Ordenar fechas de más reciente a más antigua
            categoryValues.sort((a, b) => new Date(b) - new Date(a));
          } else if (isNumericCategory) {
            // Ordenar valores numéricos de mayor a menor
            categoryValues.sort((a, b) => parseFloat(b) - parseFloat(a));
          } else {
            // Ordenar texto alfabéticamente
            categoryValues.sort();
          }
          
          // Crear un filtro para cada valor
          categoryValues.forEach((value) => {
            // Formatear el valor según su tipo
            let formattedValue = value;
            
            if (isDateCategory) {
              formattedValue = formatDateValue(value);
            } else if (isNumericCategory) {
              formattedValue = formatNumericValue(value);
            }
            
            // Crear un ID único para el filtro: categoryName___value
            const filterId = `${categoryName}___${value}`;
            
            // Añadir el filtro a la lista
            filters.push({
              id: filterId,
              value: value,
              label: formattedValue,
              condition: (item) => {
                // Verificar si el item tiene la propiedad y si coincide con el valor
                return item[categoryName] && String(item[categoryName]) === String(value);
              }
            });
          });
          
          // Solo añadir la categoría si tiene filtros
          if (filters.length > 0) {
            categories.push({
              name: formatCategoryName(categoryName),
              key: categoryName,
              filters,
              type: isDateCategory ? 'date' : isNumericCategory ? 'numeric' : 'text'
            });
          }
        });
        
        console.log("FilterControls: Categorías procesadas:", categories);
        setFilterCategories(categories);
        
        // Comunicar las categorías al componente padre
        if (onCategoriesUpdate) {
          onCategoriesUpdate(categories);
        }
      } catch (err) {
        console.error("FilterControls: Error al cargar opciones de filtrado:", err);
        setError("No se pudieron cargar las opciones de filtrado");
      } finally {
        setLoading(false);
        console.log("FilterControls: Finalizada carga de filtros");
      }
    };
    
    fetchFilterOptions();
  }, [flowId, clientId]);

  // Función para aplicar los filtros a los datos
  const applyFilters = (data, activeFilters) => {
    if (activeFilters.length === 0) return data;
    
    console.log("FilterControls: Aplicando filtros:", activeFilters);
    return data.filter(item => {
      // Para cada filtro activo, verificar si el item cumple la condición
      for (const filterId of activeFilters) {
        // Encontrar el filtro en las categorías
        for (const category of filterCategories) {
          const filter = category.filters.find(f => f.id === filterId);
          if (filter && filter.condition(item)) {
            return true;
          }
        }
      }
      return false;
    });
  };

  // Agrupar categorías por tipo
  const groupedCategories = {
    date: filterCategories.filter(cat => cat.type === 'date'),
    numeric: filterCategories.filter(cat => cat.type === 'numeric'),
    text: filterCategories.filter(cat => cat.type === 'text')
  };

  // Manejar la selección de un filtro
  const handleFilterSelect = (categoryKey, value) => {
    // Crear el ID del filtro
    const filterId = `${categoryKey}___${value}`;
    console.log("FilterControls: Seleccionando filtro:", filterId);
    
    // Activar el filtro
    onFilterToggle(filterId);
    
    // Cerrar el popover
    setOpen(false);
  };

  return (
    <div className="relative">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="h-8 gap-1 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-300"
          >
            <Filter className="h-3.5 w-3.5" />
            <span>Filtros</span>
            {activeFilters.length > 0 && (
              <Badge className="ml-1 bg-orange-500 text-white rounded-full h-5 min-w-5 flex items-center justify-center px-1.5">
                {activeFilters.length}
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent 
          className="w-80 p-3 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 shadow-lg rounded-md"
          align="start"
        >
          {loading ? (
            <div className="flex justify-center items-center py-4">
              <Loader2 className="h-6 w-6 animate-spin text-orange-500" />
              <span className="ml-2 text-slate-700 dark:text-slate-300">Cargando filtros...</span>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center py-2">{error}</div>
          ) : filterCategories.length === 0 ? (
            <div className="text-slate-500 text-center py-2">No hay filtros disponibles</div>
          ) : (
            <div className="space-y-4 max-h-[70vh] overflow-y-auto pr-1">
              {/* Sección de fechas */}
              {groupedCategories.date.length > 0 && (
                <div className="space-y-3">
                  <h3 className="font-semibold text-orange-500 border-b border-orange-200 pb-1 text-xs">Fechas</h3>
                  {groupedCategories.date.map((category) => (
                    <div key={category.key} className="space-y-1">
                      <Label className="text-xs text-slate-700 dark:text-slate-300">{category.name}</Label>
                      <Select onValueChange={(value) => handleFilterSelect(category.key, value)}>
                        <SelectTrigger className="w-full h-8 text-xs bg-white dark:bg-slate-800">
                          <SelectValue placeholder={`Seleccionar ${category.name.toLowerCase()}`} />
                        </SelectTrigger>
                        <SelectContent className="bg-white dark:bg-slate-800">
                          {category.filters.map((filter) => (
                            <SelectItem 
                              key={filter.id} 
                              value={filter.value}
                              className={`text-xs ${activeFilters.includes(filter.id) ? "bg-orange-100 dark:bg-orange-900" : ""}`}
                            >
                              {filter.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  ))}
                </div>
              )}
              
              {/* Sección de valores numéricos */}
              {groupedCategories.numeric.length > 0 && (
                <div className="space-y-3">
                  <h3 className="font-semibold text-green-500 border-b border-green-200 pb-1 text-xs">Valores</h3>
                  {groupedCategories.numeric.map((category) => (
                    <div key={category.key} className="space-y-1">
                      <Label className="text-xs text-slate-700 dark:text-slate-300">{category.name}</Label>
                      <Select onValueChange={(value) => handleFilterSelect(category.key, value)}>
                        <SelectTrigger className="w-full h-8 text-xs bg-white dark:bg-slate-800">
                          <SelectValue placeholder={`Seleccionar ${category.name.toLowerCase()}`} />
                        </SelectTrigger>
                        <SelectContent className="bg-white dark:bg-slate-800">
                          {category.filters.map((filter) => (
                            <SelectItem 
                              key={filter.id} 
                              value={filter.value}
                              className={`text-xs ${activeFilters.includes(filter.id) ? "bg-green-100 dark:bg-green-900" : ""}`}
                            >
                              {filter.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  ))}
                </div>
              )}
              
              {/* Sección de texto */}
              {groupedCategories.text.length > 0 && (
                <div className="space-y-3">
                  <h3 className="font-semibold text-blue-500 border-b border-blue-200 pb-1 text-xs">Texto</h3>
                  {groupedCategories.text.map((category) => (
                    <div key={category.key} className="space-y-1">
                      <Label className="text-xs text-slate-700 dark:text-slate-300">{category.name}</Label>
                      <Select onValueChange={(value) => handleFilterSelect(category.key, value)}>
                        <SelectTrigger className="w-full h-8 text-xs bg-white dark:bg-slate-800">
                          <SelectValue placeholder={`Seleccionar ${category.name.toLowerCase()}`} />
                        </SelectTrigger>
                        <SelectContent className="bg-white dark:bg-slate-800">
                          {category.filters.map((filter) => (
                            <SelectItem 
                              key={filter.id} 
                              value={filter.value}
                              className={`text-xs ${activeFilters.includes(filter.id) ? "bg-blue-100 dark:bg-blue-900" : ""}`}
                            >
                              {filter.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  ))}
                </div>
              )}

              {activeFilters.length > 0 && (
                <div className="pt-2 border-t border-slate-200 dark:border-slate-700">
                  <Button
                    variant="ghost"
                    size="sm"
                    className="w-full justify-center text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-md text-xs"
                    onClick={() => {
                      console.log("FilterControls: Limpiando todos los filtros");
                      activeFilters.forEach((filter) => onFilterToggle(filter));
                    }}
                  >
                    Limpiar todos los filtros
                  </Button>
                </div>
              )}
            </div>
          )}
        </PopoverContent>
      </Popover>

      {activeFilters.length > 0 && (
        <div className="flex flex-wrap gap-1 mt-2">
          {activeFilters.map((filterId) => {
            // Find the filter in all categories
            let filterLabel = filterId;
            let filterType = 'text';
            let categoryName = '';
            
            for (const category of filterCategories) {
              const filter = category.filters.find((f) => f.id === filterId);
              if (filter) {
                filterLabel = filter.label;
                filterType = category.type;
                categoryName = category.name;
                break;
              }
            }

            return (
              <Badge
                key={filterId}
                variant="secondary"
                className={`cursor-pointer rounded-md text-xs py-0.5 px-2 ${
                  filterType === 'date' 
                    ? 'bg-orange-100 text-orange-800 hover:bg-orange-200 dark:bg-orange-900 dark:text-orange-300 dark:hover:bg-orange-800'
                    : filterType === 'numeric'
                      ? 'bg-green-100 text-green-800 hover:bg-green-200 dark:bg-green-900 dark:text-green-300 dark:hover:bg-green-800'
                      : 'bg-blue-100 text-blue-800 hover:bg-blue-200 dark:bg-blue-900 dark:text-blue-300 dark:hover:bg-blue-800'
                }`}
                onClick={() => {
                  console.log("FilterControls: Eliminando filtro:", filterId);
                  onFilterToggle(filterId);
                }}
              >
                <span className="font-semibold mr-1">{categoryName}:</span> {filterLabel}
                <span className="ml-1">×</span>
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
}


