import React, {useEffect, useState, useRef} from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {connect} from "react-redux";
import {
  Row,
  Table,
  Col,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  Collapse
} from "reactstrap";
import {Form} from "react-bootstrap";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import sendIcon from "../../../Assets/img/icons/b2b/send.png";
import defaultUserIcon from "../../../Assets/img/icons/b2b/defaultUser.png";
import mantisWhiteIcon from "../../../Assets/img/icons/b2b/mantisWhite.png";
import gmailIcon from "../../../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import pdfIcon from "../../../Assets/img/icons/pdf.png";
import documentIcon from "../../../Assets/img/icons/document.png";
import excelIcon from "../../../Assets/img/icons/excel.png";
import imageIcon from "../../../Assets/img/icons/image.png";
import {
  createMantisBusinessUser,
  feedbackAssistantMessage,
  getLatestAssistantConversation,
  getMantisBusinessUsers,
  postAssistantMessage
} from "../../../ApiCalls/mantisBusiness";
import {shortenText} from "../../../utils/formatText";
import {checkMantisBusinessUserPermission} from "../../../utils/businessUtils";
import ReactMarkdown from "react-markdown";
import {useAudioRecorder} from "react-audio-voice-recorder";
import axios from "axios";
import {googleApiKey} from "../../../config";
import { getDocumentSignedUrl } from "../../../ApiCalls/mantisBusiness";
import  "../../styles/Chat.css";
import { Mic, Square, Send, Volume2, VolumeX, FileText, File, FileSpreadsheet, Image } from "lucide-react";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user
  };
};

function BusinessAssistant(props) {
  const [searchType, setSearchType] = useState("deep");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [relatedDocumentsExpanded, setRelatedDocumentsExpanded] = useState({});
  const [relatedDocumentMailShown, setRelatedDocumentMailShown] = useState({});
  const [functionalitiesShown, setFunctionalitiesShown] = useState({
    voiceToText: false,
    imagesToPDF: false,
    documentRedaction: false,
    documentSearch: false,
  });
  const messagesEndRef = useRef(null);
  const [audioRecording, setAudioRecording] = useState(null);
  const [sendingAudio, setSendingAudio] = useState(false);
  const [muted, setMuted] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({});
    }
  };

  const { startRecording, stopRecording, recordingBlob, isRecording: isAudioRecording } =
    useAudioRecorder();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!recordingBlob) return;

    // recordingBlob will be present at this point after 'stopRecording' has been called

    const url = URL.createObjectURL(recordingBlob);
    setAudioRecording(url);
    //createAudioRecording(recordingBlob);
  }, [recordingBlob]);

  const audioBlobToBase64 = async (blob) => {
    try {
      // Creating a new promise to handle FileReader
      const base64Audio = await new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Event listener for when the reading is complete
        reader.onloadend = () => {
          // Getting the ArrayBuffer result
          const arrayBuffer = reader.result;

          // Converting the ArrayBuffer to base64
          const base64Audio = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );

          resolve(base64Audio);
        };

        // Event listener for handling errors
        reader.onerror = reject;

        // Reading the blob as an ArrayBuffer
        reader.readAsArrayBuffer(blob);
      });

      // Return the base64 encoded audio
      return base64Audio;
    } catch (error) {
      // Handle or rethrow the error
      console.error("Error converting audio blob to base64:", error);
      throw error;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  async function getData() {
    await getLatestConversation();
    setLoading(false);
    scrollToBottom();
  }

  const sendAudioMessage = async () => {
    //Speech to text
    if (sendingAudio) return;
    try {
      setSendingAudio(true);
      const base64Audio = await audioBlobToBase64(recordingBlob);
      const response = await axios.post(
        `https://speech.googleapis.com/v1/speech:recognize?key=${googleApiKey}`,
        {
          config: {
            encoding: "WEBM_OPUS",
            sampleRateHertz: 16000,
            languageCode: "es-ES",
          },
          audio: {
            content: base64Audio,
          },
        }
      );
      if (response.data.results?.length > 0) {
        let message = response.data.results[0].alternatives[0].transcript;
        //Capitalize first letter
        message = message.charAt(0).toUpperCase() + message.slice(1);
        setCurrentMessage(message);
        sendMessage(new Event("submit"), message);
      } else {
        alert("No se pudo enviar el mensaje, por favor intenta de nuevo");
      }
      setSendingAudio(false);
      setAudioRecording(null);
    } catch (e) {
      console.log(e);
    }
  };

  async function getLatestConversation() {
    const conversationResponse = await getLatestAssistantConversation(
      props.user._id,
      props.mantisBusiness._id,
      props.token
    );
    if (conversationResponse.conversation) {
      setMessages(conversationResponse.conversation.responses);
      setCurrentConversation(conversationResponse.conversation);
    }
  }

  const handleDocumentClick = async (documentId) => {
    try {
      // Llama a la función para obtener la URL pre-firmada
      const response = await getDocumentSignedUrl(
        props.token,
        documentId,
        props.mantisBusiness._id
      );

      if (response.success) {
        window.open(response.signedUrl, "_blank");
      } else {
        console.error("Error fetching signed URL:", response.message);
      }
    } catch (error) {
      console.error("Error fetching signed URL:", error);
    }
  };

  async function playAudioResponse(message) {
    try {
      if (muted) return;
      const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${googleApiKey}`;
      //Clean message
      let messageCopy = message
        .replace(/【.*】/g, "")
        .replace(/\*/g, "")
        .replace(/#/g, "");
      const data = {
        input: {
          text: messageCopy,
        },
        voice: {
          languageCode: "es-US",
          name: "es-US-Standard-A",
          ssmlGender: "FEMALE",
        },
        audioConfig: {
          audioEncoding: "MP3",
        },
      };

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
      });

      const responseJson = await response.json();
      const audioBlob = toBlob(responseJson.audioContent, "audio/mpeg");
      const audioUrl = URL.createObjectURL(audioBlob);
      setResponseAudioUrl(audioUrl);
    } catch (e) {
      console.log(e);
    }
  }

  function toBlob(base64, type) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  }

  async function sendMessage(event, message) {
    event.preventDefault();
    event.stopPropagation();
    if (message === "" || waitingForResponse) return;
    setWaitingForResponse(true);
    //Add the message to the chat
    setMessages([
      ...messages,
      {
        message: message,
        sender: "User",
        timestamp: new Date(),
        type: "text",
        searchType: searchType,
      },
    ]);
    setCurrentMessage("");
    
    // Mostrar el indicador de "escribiendo..."
    setIsTyping(true);
    
    const response = await postAssistantMessage(
      props.user.cellPhone,
      message,
      props.token,
      props.user._id,
      props.mantisBusiness._id,
      searchType
    );
    
    // Ocultar el indicador de "escribiendo..."
    setIsTyping(false);
    
    if (response.success) {
      setAudioRecording(null);
      for (let i = 0; i < response.messages.length; i++) {
        if (
          response.messages[i]?.type === "text" &&
          response.messages[i]?.sender === "Mantis"
        ) {
          await playAudioResponse(response.messages[i]?.message);
        }
      }
      let messagesCopy = [...messages];
      response.messages.forEach((message) => {
        messagesCopy.push({
          type: message.type,
          message: message.message,
          sender: message.sender,
          timestamp: message.timestamp,
          url: message.url,
          filename: message.filename,
          relatedDocuments: message.relatedDocuments,
          source: message.source,
        });
      });
      setMessages(messagesCopy);
      setCurrentMessage("");
      setWaitingForResponse(false);
    } else {
      setWaitingForResponse(false);
      alert("Hubo un error al enviar el mensaje, por favor intenta de nuevo");
    }
  }

  async function handleFeedbackMessage(
    messageIndex,
    businessQuery,
    assistantResponse,
    feedbackType
  ) {
    let messagesCopy = [...messages];
    messagesCopy[messageIndex].feedbacked = true;
    setMessages(messagesCopy);
    const response = await feedbackAssistantMessage(
      props.mantisBusiness._id,
      currentConversation._id,
      assistantResponse,
      businessQuery,
      feedbackType,
      messageIndex,
      props.token
    );
    if (response.success) {
      alert("¡Gracias por tu retroalimentación!");
    } else {
      let messagesCopy = [...messages];
      messagesCopy[messageIndex].feedbacked = false;
      setMessages(messagesCopy);
      alert(
        "Hubo un error al enviarla retroalimentación del mensaje, por favor intenta de nuevo"
      );
    }
  }

  return (
    <div className="assistant-chat-container">
      {/* Control de Volumen */}
      <div 
        className="volume-control"
        style={{
          left: props.sidebarExpanded
            ? `${props.sidebarExpandedWidth + 20}px`
            : `${props.sidebarCollapsedWidth + 20}px`,
        }}
        onClick={() => {
          if (!muted) {
            const audio = document.getElementById("audioResponse");
            if (audio) audio.src = "";
          }
          setMuted(!muted);
        }}
      >
        {muted ? <VolumeX className="volume-icon" /> : <Volume2 className="volume-icon" />}
      </div>

      {/* Contenedor de Mensajes */}
      <div className="assistant-message-wrapper">
        {loading ? (
          <div className="flex justify-center">
            <Spinner className="chat-spinner" />
          </div>
        ) : (
          <>
            {messages.length === 0 && (
              <div className="assistant-mantis-message-container">
                <p className="assistant-mantis-message">
                  ¡Hola! Soy tu asistente virtual. ¿En qué puedo ayudarte hoy?
                </p>
              </div>
            )}

            {messages.map((message, index) => (
              <div key={index}>
                {message.sender === "Mantis" ? (
                  <div className="assistant-mantis-message-container">
                    {message.type === "text" ? (
                      <>
                        <div className="assistant-mantis-message">
                          <ReactMarkdown>{message.message}</ReactMarkdown>
                        </div>
                        <div className="assistant-mantis-message-timestamp">
                          {new Date(message.timestamp).toLocaleTimeString()}
                        </div>
                        
                        {/* Documentos Relacionados */}
                        {message.relatedDocuments && message.relatedDocuments.length > 0 && (
                          <div className="assistant-related-documents-container">
                            {message.relatedDocuments.map((doc, docIndex) => (
                              <div 
                                key={docIndex}
                                className="assistant-related-document-container"
                                onClick={() => handleDocumentClick(doc.userDocumentId.toString())}
                              >
                                {doc.filename.toLowerCase().includes(".pdf") ? (
                                  <FileText className="document-icon" />
                                ) : doc.filename.toLowerCase().includes(".doc") ? (
                                  <File className="document-icon" />
                                ) : doc.filename.toLowerCase().includes(".xls") ? (
                                  <FileSpreadsheet className="document-icon" />
                                ) : doc.filename.toLowerCase().includes(".jpg") || 
                                   doc.filename.toLowerCase().includes(".png") || 
                                   doc.filename.toLowerCase().includes(".jpeg") ? (
                                  <Image className="document-icon" />
                                ) : (
                                  <File className="document-icon" />
                                )}
                                <span className="assistant-mantis-message">
                                  {shortenText(doc.filename, 30)}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="assistant-link" onClick={() => window.open(message.url)}>
                        {message.filename}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="assistant-user-message-container">
                    <div className="assistant-user-message">{message.message}</div>
                    <div className="assistant-user-message-timestamp">
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </div>
                  </div>
                )}
              </div>
            ))}
            
            {/* Indicador de "escribiendo..." */}
            {isTyping && (
              <div className="assistant-mantis-message-container">
                <div className="assistant-typing-indicator">
                  <span className="typing-dot"></span>
                  <span className="typing-dot"></span>
                  <span className="typing-dot"></span>
                </div>
              </div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Container */}
      <div className="chat-input-container">
        <Form onSubmit={(e) => sendMessage(e, currentMessage)}>
          <div className="chat-input-wrapper">
            <Input
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              placeholder="Escribe tu mensaje..."
              className="chat-input"
            />
            
            <div className="chat-button-group">
              {!isRecording ? (
                <Button
                  className="chat-button mic-button"
                  onClick={startRecording}
                  disabled={waitingForResponse}
                >
                  <Mic className="chat-button-icon" />
                </Button>
              ) : (
                <Button 
                  className="chat-button stop-button"
                  onClick={stopRecording}
                >
                  <Square className="chat-button-icon" />
                </Button>
              )}
              
              <Button
                className="chat-button"
                type="submit"
                disabled={currentMessage === "" || waitingForResponse}
              >
                {waitingForResponse ? (
                  <Spinner size="sm" />
                ) : (
                  <Send className="chat-button-icon" />
                )}
              </Button>
            </div>
          </div>
        </Form>
      </div>

      {/* Audio Response */}
      {responseAudioUrl && (
        <audio
          id="audioResponse"
          src={responseAudioUrl}
          autoPlay
          className="hidden"
        />
      )}
    </div>
  );
}

export default connect(select)(BusinessAssistant);
