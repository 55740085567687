import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "../../styles/businessBackofficeStyles.scss";
import {
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalFooter,
  Button,
  Collapse,
} from "reactstrap";
import Employees from "../Employees/Employees";
import DocumentAnalysisModule from "../../Extras/DocumentAnalysisModule";
import {
  downloadOfficesExcel,
  downloadExcel,
  downloadFinaktivaExtractExcel,
  filterOffices,
  getDocumentAlerts,
  getExtractedData,
  updateExtractionField,
  filterPqrs,
  getMantisBusinessUsers,
  assignPqrstoUser,
} from "../../../ApiCalls/mantisBusiness";
import { stringify } from "../../../utils/dataExtractionUtils";
import { saveAs } from "file-saver";
import { formatMoney } from "../../../utils/dataExtractionUtils";
import { useLocation } from "react-router-dom";
import DocumentActions from "../Documents/DocumentActions";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

const pqrsSourceOptions = [
  { value: "sede_electronica", label: "Sede Electrónica" },
  { value: "email", label: "Correo Electrónico" },
  { value: "ventanilla_unica", label: "Ventanilla Única" },
];

function BusinessExtraction(props) {
  const [selectedRegisters, setSelectedRegisters] = useState([]);
  const [showingFileDetails, setShowingFileDetails] = useState(false);
  const [extractedData, setExtractedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [shownAlerts, setShownAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [registersPerPage, setRegistersPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id")?.replace(/_/g, " ");

  //Brinks
  const [brinksSelectedOffice, setBrinksSelectedOffice] = useState(null);
  const [brinksShownFile, setBrinksShownFile] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [brinksOfficeFilter, setBrinksOfficeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [filtering, setFiltering] = useState(false);
  const [showingFilteredData, setShowingFilteredData] = useState(false);
  const [showExportExcelModal, setShowExportExcelModal] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [brinksFase, setBrinksFase] = useState("");
  const [selectedBrinksOffices, setSelectedBrinksOffices] = useState([]);
  const [tab, setTab] = useState(
    props.mantisBusiness._id === "669e908234c61963b0b753f6"
      ? "brinks"
      : props.mantisBusiness.clientType === "PQRS"
      ? "RECEIVED"
      : ""
  );

  //PQRS
  const [tangeloSelectedCase, setTangeloSelectedCase] = useState(null);
  const [tangeloShownFile, setTangeloShownFile] = useState(null);
  const [tangeloShownEmail, setTangeloShownEmail] = useState(null);
  const [updatingCases, setUpdatingCases] = useState({});
  const [radicadoFilter, setRadicadoFilter] = useState("");
  const [vencimientoFilter, setVencimientoFilter] = useState("");
  const [sourceFilter, setSourceFilter] = useState("");
  const [showAssignCaseModal, setShowAssignCaseModal] = useState(false);
  const [pqrsCaseToAssign, setPqrsCaseToAssign] = useState(null);
  const [businessUsers, setBusinessUsers] = useState([]);
  const [assigningCase, setAssigningCase] = useState(false);
  const [selectedUserToAssign, setSelectedUserToAssign] = useState(null);

  useEffect(() => {
    getData();
    getBusinessUsers();
  }, []);

  useEffect(() => {
    getData();
  }, [tab]);

  useEffect(() => {
    if (id && extractedData.length > 0) {
      console.log("Id desde la URL:", id);
      const matchingDocument = extractedData.find(
        (item) => item.main_key === id
      );
      if (matchingDocument) {
        console.log("Documento encontrado:", matchingDocument);
        setSelectedRegister(matchingDocument);
        if (matchingDocument.categoria) {
          setTangeloSelectedCase(matchingDocument);
        } else {
          setBrinksSelectedOffice(matchingDocument);
        }
      } else {
        console.log("Documento no encontrado");
      }
    }
  }, [id, extractedData]);

  const getAlerts = async (relatedDocumentsGroupId) => {
    setAlertsLoading(true);
    if (relatedDocumentsGroupId) {
      const response = await getDocumentAlerts(
        props.token,
        relatedDocumentsGroupId,
        props.mantisBusiness._id
      );
      if (response.success) {
        setShownAlerts(response.alerts);
      }
    }
    setAlertsLoading(false);
  };

  const getBusinessUsers = async () => {
    const response = await getMantisBusinessUsers(
      props.mantisBusiness._id,
      props.token
    );
    if (response.success) {
      setBusinessUsers(response.users);
      setSelectedUserToAssign(response.users[0]);
    }
  };

  const getNextPage = async () => {
    if (loadingPage) return;
    setLoadingPage(true);
    const response = await getExtractedData(
      props.mantisBusiness._id,
      props.token,
      "rateConfirmations",
      currentPage + 1,
      registersPerPage,
      tab,
      props.user._id
    );
    if (response.success) {
      if (response.data.length === 0) {
        alert("No hay más datos para mostrar");
      } else {
        setExtractedData([...extractedData, ...response.data]);
        setCurrentPage(currentPage + 1);
      }
    }
    setLoadingPage(false);
  };

  const handleFilterOffices = async () => {
    setFiltering(true);
    const response = await filterOffices(
      props.token,
      {
        startDate: startDateFilter,
        endDate: endDateFilter,
        office: brinksOfficeFilter,
        status: statusFilter,
      },
      registersPerPage,
      props.mantisBusiness._id,
      tab
    );
    if (response.success) {
      setSelectedBrinksOffices([]);
      setShowingFilteredData(true);
      setExtractedData(response.data);
      if (response.data.length > 0) {
        const headers = [];
        for (let key in response.data[0]) {
          headers.push(key);
        }
        setTableHeaders(headers);
      }
    }
    setFiltering(false);
  };

  const handleFilterPqrs = async () => {
    setFiltering(true);
    const response = await filterPqrs(
      props.token,
      {
        startDate: startDateFilter,
        endDate: endDateFilter,
        radicado: radicadoFilter,
        status: statusFilter,
        source: sourceFilter,
        vencimiento: vencimientoFilter,
      },
      registersPerPage,
      props.mantisBusiness._id,
      props.mantisBusiness.clientType,
      tab
    );
    if (response.success) {
      setShowingFilteredData(true);
      setExtractedData(response.data);
    }
    setFiltering(false);
  };

  const handleAssignCaseToUser = async () => {
    setAssigningCase(true);
    const response = await assignPqrstoUser(
      props.token,
      pqrsCaseToAssign.main_key,
      selectedUserToAssign._id,
      props.mantisBusiness._id
    );
    if (response.success) {
      alert("Caso asignado correctamente");
      setShowAssignCaseModal(false);
      setPqrsCaseToAssign(null);
      setSelectedUserToAssign(
        businessUsers.length > 0 ? businessUsers[0] : null
      );
      setAssigningCase(false);
    } else {
      alert("Error al asignar el caso");

      setAssigningCase(false);
    }
  };

  const handleDownloadOfficesExcel = async () => {
    setDownloadingExcel(true);
    if (tab === "brinks") {
      const res = await downloadOfficesExcel(
        props.token,
        props.mantisBusiness._id,
        extractedData,
        "davivienda",
        brinksFase
      );
      if (res.success) {
        const byteCharacters = window.atob(res.data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        setShowExportExcelModal(false);
        saveAs(blob, "reportes_oficinas.xlsx");
      } else {
        alert("Error al descargar el archivo");
      }
      setDownloadingExcel(false);
    } else if (tab === "keralty" || tab === "keralty_weekly") {
      const res = await downloadOfficesExcel(
        props.token,
        props.mantisBusiness._id,
        extractedData,
        "keralty",
        tab
      );
      if (res.success) {
        const byteCharacters = window.atob(res.data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        setShowExportExcelModal(false);
        saveAs(blob, "keralty.xlsx");
      } else {
        alert("Error al descargar el archivo");
      }
      setDownloadingExcel(false);
    }
  };

  const updateTangeloCase = async (mainKey, fieldToUpdate, valueToUpdate) => {
    setUpdatingCases({
      ...updatingCases,
      [mainKey]: true,
    });
    const response = await updateExtractionField(
      props.token,
      fieldToUpdate,
      valueToUpdate,
      mainKey,
      props.mantisBusiness._id
    );
    if (!response.success) {
      alert("Error al actualizar el caso");
    } else {
      let updatedData = [...extractedData];
      const index = updatedData.findIndex((data) => data.main_key === mainKey);
      updatedData[index][fieldToUpdate] = valueToUpdate;
      setExtractedData(updatedData);
    }
    setUpdatingCases({
      ...updatingCases,
      [mainKey]: false,
    });
  };

  const handleExportExcel = async (type) => {
    setDownloadingExcel(true);
    const res = await downloadExcel(
      props.token,
      props.mantisBusiness._id,
      type,
      extractedData
    );
    if (res.success) {
      const byteCharacters = window.atob(res.data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      setShowExportExcelModal(false);
      saveAs(blob, "desembolsos.xlsx");
    } else {
      alert("Error al descargar el archivo");
    }
    setDownloadingExcel(false);
  };

  const getData = async () => {
    setLoading(true);
    const response = await getExtractedData(
      props.mantisBusiness._id,
      props.token,
      "rateConfirmations",
      currentPage,
      registersPerPage,
      tab,
      props.user._id
    );
    if (response.success) {
      setExtractedData(response.data);
      if (response.data.length > 0) {
        const headers = [];
        for (let key in response.data[0]) {
          headers.push(key);
        }
        setTableHeaders(headers);
      }
    } else {
      alert("Error al obtener los datos");
    }
    setLoading(false);
  };

  const closeFileDetailsModal = () => {
    setShowingFileDetails(false);
    setSelectedRegisters([]);
    setSelectedRegister(null);
  };

  const closeBrinksOfficeModal = () => {
    setBrinksSelectedOffice(null);
  };

  const closeTangeloCaseModal = () => {
    setTangeloSelectedCase(null);
  };

  return (
    <div>
      <Row
        style={{
          marginTop: "50px",
        }}
      >
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          {loading ? (
            <Row>
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xs={6}>
                  <h1 className="business-viewpanel-title">
                    {props.mantisBusiness._id === "66bbb0a2556f62f4d3b49f69"
                      ? "Tangelo"
                      : props.mantisBusiness.name}
                  </h1>
                </Col>
              </Row>
              {props.mantisBusiness.clientType === "CONCILIACION" && (
                <>
                  <Row
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    <Col
                      xs={4}
                      onClick={() => {
                        setTab("brinks");
                      }}
                      style={
                        tab === "brinks"
                          ? { borderBottom: "1px solid white" }
                          : {
                              cursor: "pointer",
                            }
                      }
                    >
                      <h4 className="business-extraction-table-title">
                        Davivienda
                      </h4>
                    </Col>
                    <Col
                      xs={4}
                      onClick={() => {
                        setTab("keralty");
                      }}
                      style={
                        tab === "keralty"
                          ? { borderBottom: "1px solid white" }
                          : {
                              cursor: "pointer",
                            }
                      }
                    >
                      <h4 className="business-extraction-table-title">
                        Keralty
                      </h4>
                    </Col>
                    <Col
                      xs={4}
                      onClick={() => {
                        setTab("keralty_weekly");
                      }}
                      style={
                        tab === "keralty_weekly"
                          ? { borderBottom: "1px solid white" }
                          : {
                              cursor: "pointer",
                            }
                      }
                    >
                      <h4 className="business-extraction-table-title">
                        Máquinas
                      </h4>
                    </Col>
                  </Row>
                  <Row>
                    {filtering ? (
                      <>
                        <Col xs={12}>
                          <Spinner color="white" size={"sm"} />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs={2}>
                          <Form>
                            <FormGroup>
                              <Label
                                for="exampleDate"
                                className="
                        business-extraction-filter-label"
                              >
                                Fecha Inicial
                              </Label>
                              <Input
                                type="date"
                                name="startDate"
                                id="startDate"
                                placeholder="Fecha"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  setStartDateFilter(e.target.value);
                                }}
                                value={startDateFilter}
                              />
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col xs={2}>
                          <Form>
                            <FormGroup>
                              <Label
                                for="exampleDate"
                                className="
                        business-extraction-filter-label"
                              >
                                Fecha Final
                              </Label>
                              <Input
                                type="date"
                                name="endDate"
                                id="endDate"
                                placeholder="Fecha"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  setEndDateFilter(e.target.value);
                                }}
                                value={endDateFilter}
                              />
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col xs={2}>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleFilterOffices();
                            }}
                          >
                            <FormGroup>
                              <Label
                                for="exampleDate"
                                className="
                        business-extraction-filter-label"
                              >
                                Oficina
                              </Label>
                              <Input
                                type="text"
                                name="brinksOffice"
                                id="brinksOffice"
                                placeholder="Oficina"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  setBrinksOfficeFilter(e.target.value);
                                }}
                                value={brinksOfficeFilter}
                              />
                            </FormGroup>
                          </Form>
                        </Col>
                        <Col xs={2}>
                          <Form>
                            <FormGroup>
                              <Label
                                for="exampleDate"
                                className="
                        business-extraction-filter-label"
                              >
                                Estado
                              </Label>
                              <Input
                                type="select"
                                name="status"
                                id="status"
                                className="business-extraction-filter-input"
                                onChange={(e) => {
                                  setStatusFilter(e.target.value);
                                }}
                                value={statusFilter}
                              >
                                <option
                                  value=""
                                  className="business-extraction-select-option"
                                >
                                  Todos
                                </option>
                                <option
                                  className="business-extraction-select-option"
                                  value="Good to Go!"
                                >
                                  Cuadre
                                </option>
                                <option
                                  className="business-extraction-select-option"
                                  value="Pendiente revisión"
                                >
                                  Pendiente revisión
                                </option>
                              </Input>
                            </FormGroup>
                          </Form>
                        </Col>
                        {(startDateFilter ||
                          endDateFilter ||
                          brinksOfficeFilter ||
                          statusFilter) && (
                          <Col
                            xs={1}
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            <div
                              className="business-extraction-filter-button"
                              onClick={() => {
                                handleFilterOffices();
                              }}
                            >
                              <h4 className="business-extraction-filter-button-text">
                                {filtering ? (
                                  <Spinner color="white" size={"sm"} />
                                ) : (
                                  "Filtrar"
                                )}
                              </h4>
                            </div>
                          </Col>
                        )}
                        {showingFilteredData && (
                          <Col
                            xs={2}
                            style={{
                              marginTop: "30px",
                            }}
                          >
                            <div
                              className="business-extraction-filter-button"
                              onClick={() => {
                                setShowingFilteredData(false);
                                setStartDateFilter(null);
                                setEndDateFilter(null);
                                setBrinksOfficeFilter("");
                                setStatusFilter("");
                                getData();
                              }}
                            >
                              <h4 className="business-extraction-filter-button-text">
                                Limpiar Filtros
                              </h4>
                            </div>
                          </Col>
                        )}
                        <Col
                          xs={1}
                          style={{
                            marginTop: "30px",
                          }}
                        >
                          <div
                            className="business-extraction-filter-button"
                            onClick={() => {
                              if (tab === "keralty_weekly") {
                                handleDownloadOfficesExcel();
                              } else if (tab === "keralty") {
                                handleDownloadOfficesExcel();
                              } else {
                                setShowExportExcelModal(true);
                              }
                            }}
                          >
                            <i
                              className="fas fa-file-excel"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </Col>
                        {selectedBrinksOffices.length > 0 && (
                          <Col xs={2}>
                            <Label
                              for="exampleDate"
                              className="
                        business-extraction-filter-label"
                            >
                              Enviar Correo
                            </Label>
                            <div className="business-extraction-filter-button">
                              <i
                                className="fas fa-envelope"
                                style={{ color: "white" }}
                              ></i>
                            </div>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </>
              )}
              {props.mantisBusiness.clientType === "PQRS" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    {/* Tab Sent - Interno */}
                    <div
                      className={
                        tab === "INTERNO"
                          ? "active-business-tab"
                          : "business-tab"
                      }
                      onClick={() => setTab("INTERNO")}
                    >
                      <h4 className="business-viewpanel-tab-title">Interno</h4>
                    </div>
                    {/* Tab Received - Externo */}
                    <div
                      className={
                        tab === "RECEIVED"
                          ? "active-business-tab"
                          : "business-tab"
                      }
                      onClick={() => setTab("RECEIVED")}
                    >
                      <h4 className="business-viewpanel-tab-title">Externo</h4>
                    </div>
                    {/* Tab Internal - Salida */}
                    <div
                      className={
                        tab === "SENT" ? "active-business-tab" : "business-tab"
                      }
                      onClick={() => setTab("SENT")}
                    >
                      <h4 className="business-viewpanel-tab-title">Salida</h4>
                    </div>
                  </div>
                  {tab === "INTERNO" && <DocumentActions activeView={tab} />}
                  {(extractedData.length > 0 ||
                    startDateFilter ||
                    endDateFilter ||
                    radicadoFilter ||
                    statusFilter ||
                    sourceFilter) && (
                    <div className="filter-section-container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="filter-section-header"
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                        >
                          <i className="fas fa-filter" />
                          <Label
                            className="filter-section-title"
                            style={{
                              marginRight: "10px",
                              textAlign: "center",
                            }}
                          >
                            Filtrar
                          </Label>
                          <i
                            className={`fas fa-chevron-${
                              isFilterExpanded ? "up" : "down"
                            }`}
                          />
                        </div>
                        {showingFilteredData && (
                          <div
                            className="filter-section-header"
                            onClick={() => {
                              setShowingFilteredData(false);
                              setStartDateFilter(null);
                              setEndDateFilter(null);
                              setStatusFilter("");
                              setRadicadoFilter("");
                              getData();
                            }}
                          >
                            <i className="fas fa-broom" />
                            <Label
                              className="filter-section-title"
                              style={{
                                marginRight: "10px",
                                textAlign: "center",
                              }}
                            >
                              Limpiar Filtros
                            </Label>
                          </div>
                        )}
                      </div>

                      <Collapse isOpen={isFilterExpanded}>
                        <Row
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                            borderRadius: "8px",
                            padding: "15px",
                          }}
                        >
                          <Row>
                            {filtering ? (
                              <>
                                <Col xs={12}>
                                  <Spinner color="white" size={"sm"} />
                                </Col>
                              </>
                            ) : (
                              <>
                                {
                                  <Col xs={2}>
                                    <Form>
                                      <FormGroup>
                                        <Label
                                          for="exampleDate"
                                          className="
                        business-extraction-filter-label"
                                        >
                                          Origen
                                        </Label>
                                        <Input
                                          type="select"
                                          name="origen"
                                          id="origen"
                                          className="business-extraction-filter-input"
                                          onChange={(e) => {
                                            setSourceFilter(e.target.value);
                                          }}
                                          value={sourceFilter}
                                        >
                                          {pqrsSourceOptions.map((option) => (
                                            <option
                                              value={option.value}
                                              className="business-extraction-select-option"
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </Input>
                                      </FormGroup>
                                    </Form>
                                  </Col>
                                }
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Fecha Inicial
                                      </Label>
                                      <Input
                                        type="date"
                                        name="startDate"
                                        id="startDate"
                                        placeholder="Fecha"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setStartDateFilter(e.target.value);
                                        }}
                                        value={startDateFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Fecha Final
                                      </Label>
                                      <Input
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        placeholder="Fecha"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setEndDateFilter(e.target.value);
                                        }}
                                        value={endDateFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={1}>
                                  <Form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      handleFilterPqrs();
                                    }}
                                  >
                                    <FormGroup>
                                      <Label
                                        for="radicado"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Radicado
                                      </Label>
                                      <Input
                                        type="text"
                                        name="radicado"
                                        id="radicado"
                                        placeholder="# radicado"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setRadicadoFilter(e.target.value);
                                        }}
                                        value={radicadoFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Estado
                                      </Label>
                                      <Input
                                        type="select"
                                        name="status"
                                        id="status"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setStatusFilter(e.target.value);
                                        }}
                                        value={statusFilter}
                                      >
                                        <option
                                          value=""
                                          className="business-extraction-select-option"
                                        >
                                          Todos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="activo"
                                        >
                                          Activo
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="respondido"
                                        >
                                          Respondido
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="archivado"
                                        >
                                          Archivado
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="sin respuesta"
                                        >
                                          Sin Respuesta
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="vencimiento"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Vencimiento
                                      </Label>
                                      <Input
                                        type="select"
                                        name="vencimiento"
                                        id="vencimiento"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setVencimientoFilter(e.target.value);
                                        }}
                                        value={vencimientoFilter}
                                      >
                                        <option
                                          value=""
                                          className="business-extraction-select-option"
                                        >
                                          Todos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="VENCIDO"
                                        >
                                          Vencidos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="POR_VENCER"
                                        >
                                          Por vencer
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="A_TIEMPO"
                                        >
                                          A tiempo
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Form>
                                </Col>
                                {(startDateFilter ||
                                  endDateFilter ||
                                  radicadoFilter ||
                                  statusFilter ||
                                  sourceFilter ||
                                  vencimientoFilter) && (
                                  <Col
                                    xs={1}
                                    style={{
                                      marginTop: "30px",
                                    }}
                                  >
                                    <div
                                      className="business-extraction-filter-button"
                                      onClick={() => {
                                        handleFilterPqrs();
                                      }}
                                    >
                                      <h4 className="business-extraction-filter-button-text">
                                        {filtering ? (
                                          <Spinner color="white" size={"sm"} />
                                        ) : (
                                          "Filtrar"
                                        )}
                                      </h4>
                                    </div>
                                  </Col>
                                )}

                                {selectedBrinksOffices.length > 0 && (
                                  <Col xs={2}>
                                    <Label
                                      for="exampleDate"
                                      className="
                        business-extraction-filter-label"
                                    >
                                      Enviar Correo
                                    </Label>
                                    <div className="business-extraction-filter-button">
                                      <i
                                        className="fas fa-envelope"
                                        style={{ color: "white" }}
                                      ></i>
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </Row>
                      </Collapse>
                    </div>
                  )}
                </>
              )}
              {props.mantisBusiness.clientType === "DESEMBOLSOS" && (
                <Row>
                  <Col
                    xs={3}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Label
                      for="exampleDate"
                      className="
                        business-extraction-filter-label"
                    >
                      Desembolsos
                    </Label>
                    <div
                      className="business-extraction-filter-button"
                      onClick={() => {
                        handleExportExcel("finaktiva");
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ color: "white" }}
                      ></i>
                    </div>
                  </Col>
                  <Col
                    xs={3}
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Label
                      for="exampleDate"
                      className="
                        business-extraction-filter-label"
                    >
                      Extractos
                    </Label>
                    <div
                      className="business-extraction-filter-button"
                      onClick={() => {
                        handleExportExcel("finaktiva_extracts");
                      }}
                    >
                      <i
                        className="fas fa-file-excel"
                        style={{ color: "white" }}
                      ></i>
                    </div>
                  </Col>
                </Row>
              )}
              {extractedData.length === 0 && (
                <h2
                  style={{
                    margin: "auto",
                    fontSize: "1.5em",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  No hay datos para mostrar
                </h2>
              )}
              <Row style={{ marginTop: "30px" }}>
                <Col xs={12}>
                  {props.mantisBusiness.clientType === "CONCILIACION" &&
                  tab === "brinks" ? (
                    <Table borderless>
                      <thead>
                        <tr className="business-extraction-table-header">
                          <th>
                            <Input
                              type="checkbox"
                              style={{
                                cursor: "pointer",
                              }}
                              checked={
                                selectedBrinksOffices.length ===
                                extractedData.length
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedBrinksOffices(extractedData);
                                } else {
                                  setSelectedBrinksOffices([]);
                                }
                              }}
                            />
                          </th>
                          <th>Fecha</th>
                          <th>Oficina</th>
                          <th>Documentos Faltantes</th>
                          <th>Cuadre Total</th>
                          <th>Cuadre DOE</th>
                          <th>Conciliación</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      {
                        <tbody>
                          {extractedData.map((register, index) => {
                            const office = register["main_key"];
                            let date = register["fecha_cuadre"]?.split("T")[0];
                            let cuadreTotal = register["diferencia_cuadre"];
                            let cuadreDOE =
                              register["diferencia_valor_doe"] > 0
                                ? "No Cuadre DOE"
                                : "Cuadre DOE";
                            let conciliacion = register["conciliacion_cryp"];
                            let documentos = "";
                            if (!register["ido_jn_main_id"]) {
                              documentos = "IDO JN/";
                            }
                            if (!register["doe_main_id"]) {
                              documentos += "DOE/";
                            }
                            if (!register["ido_ja_main_id"]) {
                              documentos += "IDO JA/";
                            }
                            if (!register["inspector_main_id"]) {
                              documentos += "Inspector/";
                            }
                            if (!register["cuadre_jn_main_id"]) {
                              documentos += "Cuadre JN/";
                            }
                            if (!register["cuadre_ja_main_id"]) {
                              documentos += "Cuadre JA/";
                            }
                            if (!register["du_main_id"]) {
                              documentos += "DU/";
                            }
                            if (!register["codigo_oficina_main_id"]) {
                              documentos += "Código Oficina/";
                            }
                            if (!register["provision_jn_main_id"]) {
                              documentos += "Provisión JN/";
                            }
                            if (!register["provision_ja_main_id"]) {
                              documentos += "Provisión JA/";
                            }
                            if (!register["banco_en_linea_main_id"]) {
                              documentos += "Banco en línea/";
                            }
                            if (!register["reversa_ja_main_id"]) {
                              documentos += "Reversa JA/";
                            }
                            if (!register["reversa_jn_main_id"]) {
                              documentos += "Reversa JN/";
                            }
                            if (!register["timbre_sobrante_jn_main_id"]) {
                              documentos += "Timbre Sobrante JN/";
                            }
                            if (!register["timbre_sobrante_ja_main_id"]) {
                              documentos += "Timbre Sobrante JA/";
                            }
                            documentos = documentos.slice(0, -1);

                            const status =
                              register["status"] === "Good to Go!"
                                ? "Cuadre"
                                : register["status"];
                            return (
                              <tr
                                className="business-extraction-table-row"
                                key={index}
                              >
                                <td>
                                  {/* <i
                                        className="fas fa-envelope"
                                        style={{
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                      ></i> */}
                                  <Input
                                    type="checkbox"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    checked={selectedBrinksOffices.includes(
                                      register
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSelectedBrinksOffices([
                                          ...selectedBrinksOffices,
                                          register,
                                        ]);
                                      } else {
                                        setSelectedBrinksOffices(
                                          selectedBrinksOffices.filter(
                                            (office) =>
                                              office.main_key !==
                                              register.main_key
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                >
                                  {date ? date : "-"}
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                  style={{
                                    width: "300px",
                                  }}
                                >
                                  {office ? office : "-"}
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                  style={{
                                    width: "250px",
                                  }}
                                >
                                  {documentos ? stringify(documentos) : "-"}
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                >
                                  <div
                                    className={
                                      cuadreTotal > 1 || cuadreTotal < -1
                                        ? "business-extraction-table-cell-red"
                                        : "business-extraction-table-cell-green"
                                    }
                                  >
                                    {cuadreTotal ? stringify(cuadreTotal) : "-"}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                >
                                  <div
                                    className={
                                      cuadreDOE === "No Cuadre DOE"
                                        ? "business-extraction-table-cell-red"
                                        : "business-extraction-table-cell-green"
                                    }
                                  >
                                    {cuadreDOE ? stringify(cuadreDOE) : "-"}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                >
                                  <div
                                    className={
                                      conciliacion < 4999 &&
                                      conciliacion > -4999
                                        ? "business-extraction-table-cell-green"
                                        : "business-extraction-table-cell-red"
                                    }
                                  >
                                    {conciliacion
                                      ? `${
                                          conciliacion === "0" ? "$" : ""
                                        } ${stringify(conciliacion)}`
                                      : "-"}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    console.log(register);
                                    setBrinksSelectedOffice(register);
                                  }}
                                >
                                  <div
                                    className={
                                      status === "Cuadre"
                                        ? "business-extraction-table-cell-green"
                                        : status === "Pendiente revisión"
                                        ? "business-extraction-table-cell-red"
                                        : ""
                                    }
                                  >
                                    {status ? stringify(status) : "-"}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      }
                    </Table>
                  ) : props.mantisBusiness.clientType === "CONCILIACION" &&
                    tab === "keralty" ? (
                    <>
                      <Table borderless>
                        <thead>
                          <tr className="business-extraction-table-header">
                            <th>
                              <Input
                                type="checkbox"
                                style={{
                                  cursor: "pointer",
                                }}
                                checked={
                                  selectedBrinksOffices.length ===
                                  extractedData.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedBrinksOffices(extractedData);
                                  } else {
                                    setSelectedBrinksOffices([]);
                                  }
                                }}
                              />
                            </th>
                            <th>Fecha</th>
                            <th>Oficina</th>
                            <th>Total Bsafe</th>
                            <th>Efectivo Caja Ossi</th>
                            <th>Total Tarjetas</th>
                            <th>Tarjetas Caja Ossi</th>
                            <th>Estado</th>
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {extractedData.map((register, index) => {
                              const office = register["main_key"];
                              let date =
                                register["fecha_cuadre"]?.split("T")[0];
                              let totalBsafe = register["bsafe_declared_total"];
                              let totalTarjetasUnificado =
                                register["unificado_codigo_compra_neta_total"];
                              let totalTarjetas =
                                (register["unificado_cmc_compra_neta_total"]
                                  ? parseFloat(
                                      register[
                                        "unificado_cmc_compra_neta_total"
                                      ]
                                    )
                                  : 0) +
                                (register["unificado_eps_compra_neta_total"]
                                  ? parseFloat(
                                      register[
                                        "unificado_eps_compra_neta_total"
                                      ]
                                    )
                                  : 0) +
                                (register["unificado_clinica_compra_neta_total"]
                                  ? parseFloat(
                                      register[
                                        "unificado_clinica_compra_neta_total"
                                      ]
                                    )
                                  : 0) +
                                "";
                              let tarjetasCajaOssi =
                                register["diario_efectivo_total_tarjetas"];

                              let efectivoCajaOssi =
                                register["diario_efectivo_total_efectivo"];
                              const status =
                                register["status"] === "OK"
                                  ? "Cuadre"
                                  : register["status"];
                              return (
                                <tr
                                  className="business-extraction-table-row"
                                  key={index}
                                >
                                  <td>
                                    <Input
                                      type="checkbox"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      checked={selectedBrinksOffices.includes(
                                        register
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedBrinksOffices([
                                            ...selectedBrinksOffices,
                                            register,
                                          ]);
                                        } else {
                                          setSelectedBrinksOffices(
                                            selectedBrinksOffices.filter(
                                              (office) =>
                                                office.main_key !==
                                                register.main_key
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {date ? date : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {office ? office : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {totalBsafe ? stringify(totalBsafe) : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {efectivoCajaOssi
                                      ? stringify(efectivoCajaOssi)
                                      : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {totalTarjetas !== "0"
                                      ? stringify(totalTarjetas)
                                      : totalTarjetasUnificado
                                      ? stringify(totalTarjetasUnificado)
                                      : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {tarjetasCajaOssi
                                      ? stringify(tarjetasCajaOssi)
                                      : "-"}
                                  </td>

                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    <div
                                      className={
                                        status === "Cuadre"
                                          ? "business-extraction-table-cell-green"
                                          : status === "Pendiente revisión"
                                          ? "business-extraction-table-cell-red"
                                          : ""
                                      }
                                    >
                                      {status ? stringify(status) : "-"}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        }
                      </Table>
                    </>
                  ) : props.mantisBusiness.clientType === "CONCILIACION" &&
                    tab === "keralty_weekly" ? (
                    <>
                      <Table borderless>
                        <thead>
                          <tr className="business-extraction-table-header">
                            <th>Punto BSAFE</th>
                            <th>Fecha Inicial</th>
                            <th>Fecha Final</th>
                            <th>Valor del retiro</th>
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {extractedData.map((register, index) => {
                              let bsafePoint =
                                register["cierre_pickup_sucursal"];
                              let startDate =
                                register[
                                  "cierre_pickup_fecha_retirada_inicial"
                                ]?.split("T")[0];
                              let endDate =
                                register[
                                  "cierre_pickup_fecha_retirada_final"
                                ]?.split("T")[0];
                              let valorRetiro =
                                register["cierre_pickup_valor_retiro"];
                              let pickup = register["weekly_bsafe_sum"];
                              let certificationVsPickup =
                                register["certificacion_vs_pickup_valor"];
                              return (
                                <tr
                                  className="business-extraction-table-row"
                                  key={index}
                                >
                                  <td>{bsafePoint ? bsafePoint : "-"}</td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {startDate ? startDate : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {endDate ? endDate : "-"}
                                  </td>
                                  <td
                                    onClick={() => {
                                      console.log(register);
                                      setBrinksSelectedOffice(register);
                                    }}
                                  >
                                    {valorRetiro ? stringify(valorRetiro) : "-"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        }
                      </Table>
                    </>
                  ) : props.mantisBusiness._id ===
                    "66bbb0a2556f62f4d3b49f69" ? (
                    <>
                      <h3 className="business-extraction-table-title">PQRS</h3>
                      <Table borderless>
                        <thead>
                          <tr className="business-extraction-table-header">
                            <th>No. Radicado</th>
                            <th>Categoría</th>
                            <th>Fecha Recibido</th>
                            <th>Fecha Límite</th>
                            <th>Nombre Solicitante</th>
                            <th>Documento Solicitante</th>
                            <th>Estado</th>
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {extractedData.map((register, index) => {
                              const radicado = register["main_key"];
                              let date =
                                register["fecha_recibido"]?.split("T")[0];
                              let limitDate =
                                register["fecha_respuesta_maxima"]?.split(
                                  "T"
                                )[0];
                              //Days between today and the limit date
                              let daysToRespond = Math.floor(
                                (new Date(limitDate) - new Date()) /
                                  (1000 * 60 * 60 * 24)
                              );
                              let requesterDocument =
                                register["documento_solicitante"];
                              let requesterName =
                                register["nombre_solicitante"];
                              let category =
                                register["categoria"] || "SIN CATEGORÍA";
                              let petitions = register["peticion_1"] || "";
                              let status = register["status"];
                              let petitionsArray = petitions
                                .replace("[", "")
                                .replace("]", "")
                                .replace(/"/g, "")
                                .split(",");
                              if (requesterName) {
                                return (
                                  <tr
                                    className="business-extraction-table-row"
                                    key={index}
                                  >
                                    {updatingCases[radicado] ? (
                                      <td
                                        colSpan={7}
                                        style={{
                                          textAlign: "center",
                                          display: "table-cell",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Spinner size={"sm"} />
                                      </td>
                                    ) : (
                                      <>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {radicado ? radicado : "-"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {category
                                            ? category
                                            : "SIN CLASIFICAR"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {date
                                            ? new Date(date).toLocaleDateString(
                                                "es"
                                              )
                                            : "-"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                          className={
                                            daysToRespond >= 3
                                              ? "business-extraction-table-cell-green"
                                              : daysToRespond >= 0 &&
                                                daysToRespond < 3
                                              ? "business-extraction-table-cell-yellow"
                                              : "business-extraction-table-cell-red"
                                          }
                                        >
                                          {limitDate
                                            ? new Date(
                                                limitDate
                                              ).toLocaleDateString("es")
                                            : "-"}
                                        </td>

                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {requesterName ? requesterName : "-"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {requesterDocument
                                            ? requesterDocument
                                            : "-"}
                                        </td>
                                        <td>
                                          <div className="business-extraction-filter-input">
                                            {status ? status : "-"}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        }
                      </Table>
                    </>
                  ) : props.mantisBusiness.clientType === "DESEMBOLSOS" ? (
                    <Table borderless>
                      <thead>
                        <tr className="business-extraction-table-header">
                          <th>Fecha</th>
                          <th>Nombre Proveedor</th>
                          <th>NIT Proveedor</th>
                          <th>Nombre Pagador</th>
                          <th>NIT Pagador</th>
                          <th>Valor Total</th>
                          <th>Porcentaje Cubrimiento</th>
                        </tr>
                      </thead>
                      {
                        <tbody>
                          {extractedData.map((register, index) => {
                            const office = register["main_key"];
                            let date =
                              register["formato_desembolso_fecha"]?.split(
                                "T"
                              )[0];
                            let providerName =
                              register["informacion_proveedor_nombre"];
                            let providerNit =
                              register["informacion_proveedor_nit"];
                            let payerName =
                              register["informacion_pagador_nombre"];
                            let payerNit = register["informacion_pagador_nit"];
                            let total =
                              register["valor_facturas_total_valor_factura"];
                            let percentage =
                              register[
                                "valor_facturas_total_porcentaje_de_cubrimiento"
                              ];
                            return (
                              <tr
                                className="business-extraction-table-row"
                                key={index}
                              >
                                <td>{date ? date : "-"}</td>
                                <td>{providerName ? providerName : "-"}</td>

                                <td>{providerNit ? providerNit : "-"}</td>
                                <td>{payerName ? payerName : "-"}</td>
                                <td>{payerNit ? payerNit : "-"}</td>
                                <td>${total ? formatMoney(total) : "-"}</td>
                                <td>{percentage ? percentage : "-"}%</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      }
                    </Table>
                  ) : props.mantisBusiness.clientType === "PQRS" ? (
                    <>
                      {extractedData.length > 0 && (
                        <Table borderless>
                          <thead>
                            <tr className="business-extraction-table-header">
                              <th>No. Radicado</th>
                              <th>Fecha Radicado</th>
                              <th>Remitente</th>
                              <th>Receptor</th>
                              <th>Categoria</th>
                              <th>Vencimiento</th>
                              <th>Días</th>
                              <th>Estado</th>
                            </tr>
                          </thead>
                          {
                            <tbody>
                              {extractedData.map((register, index) => {
                                const radicado = register["main_key"];
                                let date =
                                  register["fecha_radicado"]?.split("T")[0];
                                let limitDate =
                                  register["vencimiento"]?.split("T")[0];
                                let remitente = register["nombre_solicitante"];
                                //Days between today and the limit date
                                let daysToRespond = Math.floor(
                                  (new Date(limitDate) - new Date()) /
                                    (1000 * 60 * 60 * 24)
                                );
                                let vencimiento =
                                  daysToRespond <= 0
                                    ? "Vencido"
                                    : daysToRespond > 0 && daysToRespond < 3
                                    ? "Próximo a vencer"
                                    : "A tiempo";

                                let receiverName =
                                  register["dpto_receptor"] || "Por definir";
                                let category =
                                  register["categoria"] || "SIN CATEGORÍA";
                                let status = register["estado"];
                                status =
                                  status[0].toUpperCase() +
                                  status.slice(1).replace("_", " ");

                                return (
                                  <tr
                                    className="business-extraction-table-row"
                                    key={index}
                                  >
                                    {updatingCases[radicado] ? (
                                      <td
                                        colSpan={7}
                                        style={{
                                          textAlign: "center",
                                          display: "table-cell",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Spinner size={"sm"} />
                                      </td>
                                    ) : (
                                      <>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {radicado ? radicado : "-"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {date
                                            ? new Date(date).toLocaleDateString(
                                                "es"
                                              )
                                            : "-"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {remitente ? remitente : "REMITENTE"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <div
                                            className={
                                              receiverName === "Por definir"
                                                ? "business-extraction-table-cell-red"
                                                : ""
                                            }
                                            onClick={() => {
                                              if (
                                                receiverName !== "Por definir"
                                              ) {
                                                setTangeloSelectedCase(
                                                  register
                                                );
                                              } else {
                                                setPqrsCaseToAssign(register);
                                                setShowAssignCaseModal(true);
                                              }
                                            }}
                                          >
                                            {receiverName}
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {category
                                            ? category
                                            : "SIN CLASIFICAR"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          <div
                                            className={
                                              daysToRespond >= 3
                                                ? "business-extraction-table-cell-green"
                                                : daysToRespond >= 0 &&
                                                  daysToRespond < 3
                                                ? "business-extraction-table-cell-yellow"
                                                : "business-extraction-table-cell-red"
                                            }
                                          >
                                            {vencimiento}
                                          </div>
                                        </td>

                                        <td>
                                          <div className="business-extraction-filter-input">
                                            {daysToRespond
                                              ? daysToRespond
                                              : "-"}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="business-extraction-filter-input">
                                            {status ? status : "-"}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                        </Table>
                      )}
                    </>
                  ) : (
                    <Table borderless>
                      <thead>
                        <tr className="business-extraction-table-header">
                          <th># Carga (Manifiesto)</th>
                          <th># PO</th>
                          <th>Valor</th>
                          <th>Peso</th>
                          <th>Fecha de recogida</th>
                          <th>Fecha de entrega</th>
                          <th>Ciudad de recogida</th>
                          <th>Ciudad de entrega</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      {
                        <tbody>
                          {extractedData.map((registerList, index) => {
                            const register = registerList[0];
                            const docNumber = register["ManifestID"];
                            const poNumber = register["OrderNumber"];
                            const status = register["Status"];
                            const value = register["Amount"];
                            const weight =
                              register["Weight"] + register["UnitOfMeasure"];
                            const pickupAddresses = register["PickUpAddresses"]
                              ? register["PickUpAddresses"]
                                  .replace("[", "")
                                  .replace("]", "")
                                  .replace(/"/g, "")
                                  .split(",")
                              : "";
                            const deliveryAddresses = register[
                              "DeliveryAddresses"
                            ]
                              ? register["DeliveryAddresses"]
                                  .replace("[", "")
                                  .replace("]", "")
                                  .replace(/"/g, "")
                                  .split(",")
                              : "";
                            const pickupDates = register["PickUpDatesTimes"]
                              ? register["PickUpDatesTimes"]
                                  .replace("[", "")
                                  .replace("]", "")
                                  .replace(/"/g, "")
                                  .split(",")
                              : "";
                            const deliveryDates = register["DeliveryDatesTimes"]
                              ? register["DeliveryDatesTimes"]
                                  .replace("[", "")
                                  .replace("]", "")
                                  .replace(/"/g, "")
                                  .split(",")
                              : "";
                            return (
                              <tr
                                className="business-extraction-table-row"
                                key={index}
                                onClick={() => {
                                  setSelectedRegisters(registerList);
                                  setSelectedRegister(register);
                                  setShowingFileDetails(true);
                                }}
                              >
                                <td>
                                  {docNumber ? stringify(docNumber) : "-"}
                                </td>
                                <td>{poNumber ? stringify(poNumber) : "-"}</td>
                                <td>{value ? stringify(value) : "-"}</td>
                                <td>{weight ? stringify(weight) : "-"}</td>
                                <td>
                                  {pickupDates ? stringify(pickupDates) : "-"}
                                </td>
                                <td>
                                  {deliveryDates
                                    ? stringify(deliveryDates)
                                    : "-"}
                                </td>
                                <td>
                                  {pickupAddresses
                                    ? stringify(pickupAddresses)
                                    : "-"}
                                </td>
                                <td>
                                  {deliveryAddresses
                                    ? stringify(deliveryAddresses)
                                    : "-"}
                                </td>
                                <td>{status ? stringify(status) : "-"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      }
                    </Table>
                  )}
                </Col>
              </Row>
              {!showingFilteredData && extractedData.length > 0 && (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Col xs={3}>
                    <div
                      className="business-extraction-filter-button"
                      onClick={() => {
                        getNextPage();
                      }}
                    >
                      <h4 className="business-extraction-filter-button-text">
                        {loadingPage ? (
                          <Spinner color="white" size={"sm"} />
                        ) : (
                          "Cargar más"
                        )}
                      </h4>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
      {selectedRegisters.length > 0 && (
        <Modal
          className="business-extraction-file-analysis-modal"
          isOpen={showingFileDetails}
          toggle={() => closeFileDetailsModal()}
          centered
          size="xl"
        >
          <ModalBody className="business-extraction-file-analysis-modal-body">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col
                xs={6}
                style={{
                  marginBottom: "10px",
                  height: "55vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`Manifiesto ${selectedRegister.ManifestID}`}
                  selectedRegister={selectedRegister}
                  mainId={selectedRegister.main_id}
                  type="documentDetail"
                />
              </Col>
              <Col
                xs={6}
                style={{
                  marginBottom: "10px",
                  height: "55vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`Alertas`}
                  selectedRegister={selectedRegister}
                  mainId={selectedRegister.main_id}
                  type="alerts"
                />
              </Col>
              <Col
                xs={6}
                style={{
                  marginBottom: "10px",
                  height: "30vh",
                }}
              >
                <DocumentAnalysisModule
                  selectedRegister={selectedRegister}
                  mainId={selectedRegister.main_id}
                  type="relatedDocuments"
                  selectedRegisters={selectedRegisters}
                  setSelectedRegister={setSelectedRegister}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
      {brinksSelectedOffice && (
        <Modal
          className="business-extraction-file-analysis-modal"
          isOpen={brinksSelectedOffice}
          toggle={() => closeBrinksOfficeModal()}
          centered
          size="xl"
        >
          <div
            onClick={() => closeBrinksOfficeModal()}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "50px",
              marginTop: "10px",
            }}
          >
            <h4
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.5em",
                fontWeight: "bold",
              }}
            >
              X
            </h4>
          </div>
          <ModalBody className="business-extraction-file-analysis-modal-body">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col
                xs={9}
                style={{
                  marginBottom: "10px",
                  height: "55vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`${brinksSelectedOffice.main_key}`}
                  brinksOffice={brinksSelectedOffice}
                  type="brinksMain"
                  brinksShownFile={brinksShownFile}
                  getAlerts={getAlerts}
                />
              </Col>
              <Col
                xs={3}
                style={{
                  marginBottom: "10px",
                  height: "55vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`Alertas`}
                  relatedDocumentsGroupId={brinksSelectedOffice.file_group_id}
                  type="alerts"
                  alerts={shownAlerts}
                  getAlerts={getAlerts}
                  alertsLoading={alertsLoading}
                />
              </Col>
              <Col
                xs={12}
                style={{
                  marginBottom: "10px",
                  height: "30vh",
                }}
              >
                <DocumentAnalysisModule
                  brinksOffice={brinksSelectedOffice}
                  type="brinksOfficeDocuments"
                  brinksShownFile={brinksShownFile}
                  setBrinksShownFile={setBrinksShownFile}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
      {tangeloSelectedCase && (
        <Modal
          className="business-extraction-file-analysis-modal"
          isOpen={tangeloSelectedCase}
          toggle={() => closeTangeloCaseModal()}
          centered
          size="xl"
        >
          <div
            onClick={() => closeTangeloCaseModal()}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "50px",
              marginTop: "10px",
            }}
          >
            <h4
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.5em",
                fontWeight: "bold",
              }}
            >
              X
            </h4>
          </div>
          <ModalBody className="business-extraction-file-analysis-modal-body">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col
                xs={8}
                style={{
                  marginBottom: "10px",
                  height: "50vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`${tangeloSelectedCase.nombre_solicitante}`}
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloMain"
                  tangeloShownFile={tangeloShownFile}
                  tangeloShownEmail={tangeloShownEmail}
                  selectedDocuments={selectedDocuments}
                  //getAlerts={getAlerts}
                />
              </Col>
              <Col
                xs={4}
                style={{
                  marginBottom: "10px",
                  height: "50vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`Peticiones`}
                  type="tangeloCasePetitions"
                  tangeloCase={tangeloSelectedCase}
                />
              </Col>
              <Col
                xs={8}
                style={{
                  marginBottom: "10px",
                  height: "35vh",
                }}
              >
                <DocumentAnalysisModule
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloCaseDocuments"
                  tangeloShownFile={tangeloShownFile}
                  tangeloShownEmail={tangeloShownEmail}
                  setTangeloShownFile={setTangeloShownFile}
                  setTangeloShownEmail={setTangeloShownEmail}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Col>
              <Col
                xs={4}
                style={{
                  marginBottom: "10px",
                  height: "35vh",
                }}
              >
                <DocumentAnalysisModule
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloStatus"
                  updateTangeloCase={updateTangeloCase}
                  title={`Acciones`}
                  tangeloStatus={tangeloSelectedCase.estado}
                  tangeloCategory={tangeloSelectedCase.categoria}
                  changingStatus={updatingCases[tangeloSelectedCase.main_key]}
                  selectedDocuments={selectedDocuments}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
      {showExportExcelModal && (
        <Modal
          isOpen={showExportExcelModal}
          toggle={() => setShowExportExcelModal(!showExportExcelModal)}
          centered
        >
          <ModalHeader>
            <h3>Exportar a Excel</h3>
          </ModalHeader>
          <ModalBody className="business-extraction-file-analysis-modal-body">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col
                xs={12}
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    className="document-analysis-module-related-document-checkbox"
                    type="checkbox"
                    checked={brinksFase === "CUADRE"}
                    style={{
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBrinksFase("CUADRE");
                      } else {
                        setBrinksFase("");
                      }
                    }}
                  />
                  <h5>Cuadre</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    className="document-analysis-module-related-document-checkbox"
                    type="checkbox"
                    checked={brinksFase === "CONCILIACION"}
                    style={{
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBrinksFase("CONCILIACION");
                      } else {
                        setBrinksFase("");
                      }
                    }}
                  />
                  <h5>Conciliación</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    className="document-analysis-module-related-document-checkbox"
                    type="checkbox"
                    checked={brinksFase === "CONTROL"}
                    style={{
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBrinksFase("CONTROL");
                      } else {
                        setBrinksFase("");
                      }
                    }}
                  />
                  <h5>Control</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    className="document-analysis-module-related-document-checkbox"
                    type="checkbox"
                    checked={brinksFase === "REPORTERIA"}
                    style={{
                      marginRight: "10px",
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBrinksFase("REPORTERIA");
                      } else {
                        setBrinksFase("");
                      }
                    }}
                  />
                  <h5>Reportería</h5>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="secondary"
              onClick={() => {
                if (!downloadingExcel) {
                  setShowExportExcelModal(false);
                }
              }}
            >
              Cancelar
            </Button>
            {brinksFase !== "" && (
              <Button
                color="primary"
                onClick={() => {
                  handleDownloadOfficesExcel();
                }}
              >
                {downloadingExcel ? (
                  <Spinner color="white" size={"sm"} />
                ) : (
                  "Exportar"
                )}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
      {showAssignCaseModal && (
        <Modal
          isOpen={showAssignCaseModal}
          toggle={() => setShowAssignCaseModal(!showAssignCaseModal)}
          centered
          className="business-extraction-modal"
        >
          <ModalHeader className="business-extraction-modal-header">
            <h3
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Asignar caso
            </h3>
          </ModalHeader>
          <ModalBody className="business-extraction-modal-body">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup>
                    <Label
                      for="userSelect"
                      className="business-extraction-filter-label"
                    >
                      Usuario
                    </Label>
                    <Input
                      type="select"
                      name="userSelect"
                      id="userSelect"
                      className="business-extraction-filter-input"
                      onChange={(e) => {
                        setSelectedUserToAssign(
                          businessUsers.find(
                            (user) => user._id === e.target.value
                          )
                        );
                      }}
                      value={
                        selectedUserToAssign ? selectedUserToAssign._id : ""
                      }
                    >
                      {businessUsers.map((user, index) => (
                        <option
                          value={user._id}
                          key={index}
                          className="business-extraction-select-option"
                        >
                          {user.email}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="business-extraction-modal-footer">
            <Button
              color="secondary"
              onClick={() => {
                if (!assigningCase) {
                  setShowAssignCaseModal(false);
                }
              }}
              className="business-extraction-modal-button"
              style={{
                backgroundColor: "#282828",
                color: "white",
                border: "1px solid #444",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Cancelar
            </Button>
            {selectedUserToAssign && (
              <Button
                onClick={handleAssignCaseToUser}
                className="business-extraction-modal-button"
                disabled={assigningCase}
                style={{
                  backgroundColor: "rgba(240, 245, 108, 0.8)",
                  color: "#000",
                  border: "none",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {assigningCase ? <Spinner color="dark" size="sm" /> : "Asignar"}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default connect(select)(BusinessExtraction);
