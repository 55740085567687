"use client"

import { CheckCircle, Clock, AlertTriangle, FileText } from "lucide-react"
import { motion } from "framer-motion"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../components/ui/card"
import { Badge } from "../../../components/ui/badge"

export default function FlowItem({ flow, onClick, loading = false }) {
  if (loading) {
    return (
      <motion.div
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <Card className="h-full border border-slate-200 dark:border-slate-700">
          <CardHeader className="pb-2">
            <div className="h-6 w-3/4 bg-slate-200 dark:bg-slate-700 rounded animate-pulse"></div>
          </CardHeader>
          <CardContent>
            <div className="h-4 w-full bg-slate-200 dark:bg-slate-700 rounded animate-pulse mb-2"></div>
            <div className="h-4 w-2/3 bg-slate-200 dark:bg-slate-700 rounded animate-pulse"></div>
          </CardContent>
          <CardFooter>
            <div className="h-4 w-1/2 bg-slate-200 dark:bg-slate-700 rounded animate-pulse"></div>
          </CardFooter>
        </Card>
      </motion.div>
    )
  }

  const getStatusIcon = () => {
    switch (flow.status) {
      case "active":
        return <CheckCircle className="h-5 w-5 text-green-500" />
      case "processing":
        return <Clock className="h-5 w-5 text-amber-500" />
      case "error":
        return <AlertTriangle className="h-5 w-5 text-red-500" />
      default:
        return null
    }
  }

  const getStatusText = () => {
    switch (flow.status) {
      case "active":
        return "Active"
      case "processing":
        return "In Process"
      case "error":
        return "Error Detected"
      default:
        return "Unknown"
    }
  }

  const getStatusColor = () => {
    switch (flow.status) {
      case "active":
        return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
      case "processing":
        return "bg-amber-100 text-amber-800 dark:bg-amber-900 dark:text-amber-300"
      case "error":
        return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
      default:
        return "bg-slate-100 text-slate-800 dark:bg-slate-800 dark:text-slate-300"
    }
  }

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <Card
        className="cursor-pointer h-full border border-slate-200 dark:border-slate-700 hover:border-orange-300 dark:hover:border-orange-500 transition-all duration-200"
        onClick={() => onClick(flow)}
      >
        <CardHeader className="pb-2">
          <div className="flex justify-between items-start">
            <CardTitle className="text-xl font-bold text-slate-800 dark:text-slate-100">{flow.name}</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <p className="text-slate-600 dark:text-slate-300 mb-4">{flow.description}</p>
        </CardContent>
        <CardFooter>
          <div className="flex items-center text-slate-500 dark:text-slate-400">
            <FileText className="h-4 w-4 mr-2" />
            <span>{flow.documentCount} documentos procesados</span>
          </div>
        </CardFooter>
      </Card>
    </motion.div>
  )
}

