"use client"

import { useState } from "react"
import { Save, Database, FileCheck, FileText } from "lucide-react"
import { Button } from "../../../../components/ui/button"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../../components/ui/tooltip"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../../../../components/ui/dialog"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import { Checkbox } from "../../../../components/ui/checkbox"
import { Badge } from "../../../../components/ui/badge"
import DataTable from "./DataTable"
import SuccessAnimation from "./SuccessAnimation"

const SaveFlowButton = ({ flowData, isReady = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [flowName, setFlowName] = useState("Mi Flujo de Trabajo")
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  // Datos de ejemplo que se extraerían del flujo
  const extractedData = [
    {
      id: "doc-001",
      name: "Contrato de servicios",
      type: "PDF",
      status: "Procesado",
      date: "2025-03-15",
      size: "1.2 MB",
      author: "Juan Pérez",
      department: "Legal",
      tags: ["contrato", "servicios", "legal"],
      processingTime: "45s",
    },
    {
      id: "doc-002",
      name: "Factura mensual",
      type: "Excel",
      status: "Pendiente",
      date: "2025-03-16",
      size: "0.8 MB",
      author: "María Gómez",
      department: "Finanzas",
      tags: ["factura", "mensual", "finanzas"],
      processingTime: "30s",
    },
    {
      id: "doc-003",
      name: "Informe trimestral",
      type: "Word",
      status: "Revisado",
      date: "2025-03-10",
      size: "2.5 MB",
      author: "Carlos Ruiz",
      department: "Dirección",
      tags: ["informe", "trimestral", "resultados"],
      processingTime: "60s",
    },
    {
      id: "doc-004",
      name: "Presentación de proyecto",
      type: "PowerPoint",
      status: "Aprobado",
      date: "2025-03-12",
      size: "5.1 MB",
      author: "Ana Martínez",
      department: "Marketing",
      tags: ["presentación", "proyecto", "cliente"],
      processingTime: "25s",
    },
    {
      id: "doc-005",
      name: "Manual de usuario",
      type: "PDF",
      status: "Publicado",
      date: "2025-03-05",
      size: "3.7 MB",
      author: "Pedro Sánchez",
      department: "Soporte",
      tags: ["manual", "usuario", "documentación"],
      processingTime: "90s",
    },
  ]

  // Estado para las columnas seleccionadas
  const [selectedColumns, setSelectedColumns] = useState(
    extractedData.length > 0 ? Object.keys(extractedData[0]).reduce((acc, key) => ({ ...acc, [key]: true }), {}) : {},
  )

  const handleOpenModal = () => {
    setIsModalOpen(true)
    setIsSaved(false)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSave = () => {
    setIsSaving(true)

    // Simulación de guardado
    setTimeout(() => {
      setIsSaving(false)
      setIsSaved(true)
    }, 1000)
  }

  const handleToggleColumn = (column) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }))
  }

  const handleToggleAll = (checked) => {
    const newColumns = {}
    Object.keys(selectedColumns).forEach((column) => {
      newColumns[column] = checked
    })
    setSelectedColumns(newColumns)
  }

  // Verificar si todas las columnas están seleccionadas
  const allSelected = Object.values(selectedColumns).every((value) => value === true)
  // Contar columnas seleccionadas
  const selectedCount = Object.values(selectedColumns).filter(Boolean).length
  const totalColumns = Object.keys(selectedColumns).length

  // Formatear nombres de columnas para mostrar
  const formatColumnName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).replace(/([A-Z])/g, " $1")
  }

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={handleOpenModal}
              disabled={!isReady}
              className="bg-orange-500 hover:bg-orange-600 text-white flex items-center gap-2"
              size="sm"
            >
              <Save className="h-4 w-4" />
              <span>Guardar Flujo</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Guardar el flujo de trabajo actual</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog open={isModalOpen} onOpenChange={handleCloseModal}>
        <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col">
          {isSaved ? (
            <SuccessAnimation flowName={flowName} onClose={handleCloseModal} />
          ) : (
            <>
              <DialogHeader>
                <DialogTitle className="flex items-center gap-2">
                  <Save className="h-5 w-5 text-orange-500" />
                  Guardar Flujo de Trabajo
                </DialogTitle>
                <DialogDescription>Previsualiza y guarda tu flujo de trabajo actual.</DialogDescription>
              </DialogHeader>

              <div className="space-y-4 py-2">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="flow-name" className="flex items-center gap-1">
                      <FileText className="h-4 w-4 text-orange-500" />
                      Nombre del flujo
                    </Label>
                    <Input
                      id="flow-name"
                      value={flowName}
                      onChange={(e) => setFlowName(e.target.value)}
                      placeholder="Ingresa un nombre para tu flujo"
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="flow-description" className="flex items-center gap-1">
                      <FileCheck className="h-4 w-4 text-orange-500" />
                      Descripción (opcional)
                    </Label>
                    <Input id="flow-description" placeholder="Añade una breve descripción" />
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium flex items-center gap-1">
                    <Database className="h-4 w-4 text-orange-500" />
                    Vista previa de datos
                  </h3>
                  <Badge variant="outline" className="text-xs">
                    {selectedCount} de {totalColumns} columnas visibles
                  </Badge>
                </div>

                <div className="border rounded-md p-4 bg-white">
                  <div className="h-[250px] overflow-auto">
                    <DataTable data={extractedData} columns={selectedColumns} />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium">Columnas disponibles</h3>
                  <div className="flex items-center gap-2">
                    <Checkbox id="select-all" checked={allSelected} onCheckedChange={handleToggleAll} />
                    <Label htmlFor="select-all" className="text-xs cursor-pointer">
                      {allSelected ? "Deseleccionar todo" : "Seleccionar todo"}
                    </Label>
                  </div>
                </div>

                <div className="border rounded-md p-3 bg-gray-50">
                  <div className="flex flex-wrap gap-2">
                    {Object.entries(selectedColumns).map(([column, isSelected]) => (
                      <div
                        key={column}
                        onClick={() => handleToggleColumn(column)}
                        className={`px-3 py-2 rounded-full cursor-pointer transition-all duration-200 ${
                          isSelected
                            ? "bg-orange-500 text-white shadow-sm"
                            : "bg-white text-gray-700 border border-gray-200"
                        }`}
                      >
                        <span className="text-xs font-medium whitespace-nowrap">{formatColumnName(column)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <DialogFooter className="mt-4">
                <Button variant="outline" onClick={handleCloseModal} disabled={isSaving}>
                  Cancelar
                </Button>
                <Button
                  onClick={handleSave}
                  className="bg-orange-500 hover:bg-orange-600 text-white"
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <div className="flex items-center gap-1">
                      <div className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      <span>Guardando...</span>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1">
                      <Save className="h-4 w-4" />
                      <span>Guardar Flujo</span>
                    </div>
                  )}
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SaveFlowButton

