"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { ArrowLeft } from "lucide-react"
import { Button } from "../../../components/ui/button"
import FlowTable from "./FlowTable"
import FilterControls from "./FilterControls"
import ReportScheduler from "./ReportScheduler"
import ExportButton from "./ExportButton"
import FlowModal from "./FlowModal"
import { Badge } from "../../../components/ui/badge"
import { Input } from "../../../components/ui/input"
import { Search } from "lucide-react"
import { getWorkflowResults } from "../../../../ApiCalls/mantisFlowsManangment"
import Columns from "./Colums"

export default function FlowDetail({ flow, onBack }) {
  const [selectedData, setSelectedData] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [flowResults, setFlowResults] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [visibleColumns, setVisibleColumns] = useState([])
  const [allColumns, setAllColumns] = useState([])
  const [filterCategories, setFilterCategories] = useState([])

  // Función para formatear nombres de columnas a un formato más amigable
  const formatColumnName = (columnName) => {
    // Convertir snake_case a palabras separadas
    const words = columnName.split('_');
    
    // Capitalizar cada palabra y unirlas con espacios
    return words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  // Función para determinar qué columnas mostrar y en qué orden
  const determineVisibleColumns = (data) => {
    if (!data || data.length === 0) return [];
    
    // Obtener todas las claves del primer objeto
    const allKeys = Object.keys(data[0]);
    
    // Excluir campos internos o de metadatos que no queremos mostrar
    const excludedColumns = ['id', 'hasErrors', 'isVerified', 'status', 'date'];
    
    // Filtrar las columnas que queremos mostrar
    return allKeys.filter(key => !excludedColumns.includes(key));
  }

  //Format value
  const formatCellValue = (value) => {
    //If value contains only numbers, add commas
    if (/^\d+$/.test(value)) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value;
    }
  };

  useEffect(() => {
    const fetchWorkflowResults = async () => {
      try {
        setLoading(true)
        // Quemamos el clientId con "2"
        const clientId = "2"
        const response = await getWorkflowResults(clientId, flow.id)
        
        if (response.success) {
          // Adaptamos los datos para que coincidan con el formato esperado
          const formattedResults = response.data.map(result => {
            // Crear objeto base con los campos originales
            const formattedResult = { ...result };
            
            // Formatear campos específicos
            if (result.extraction_date) {
              formattedResult.extraction_date = new Date(result.extraction_date).toLocaleString();
            }
            
            if (result.total_amount) {
              formattedResult.total_amount = `$${parseFloat(result.total_amount).toLocaleString('es-CO')}`;
            }
            
            // Agregar campos adicionales para los filtros
            formattedResult.hasErrors = result.validation_status === "Rechazado";
            formattedResult.isVerified = result.validation_status === "Validado";
            formattedResult.status = mapValidationStatus(result.validation_status);
            formattedResult.date = result.extraction_date;
            
            return formattedResult;
          });
          
          setFlowResults(formattedResults);
          
          // Determinar dinámicamente las columnas visibles
          const columns = determineVisibleColumns(formattedResults);
          setVisibleColumns(columns);
          
          // Determinar todas las columnas disponibles (excluyendo las de metadatos)
          if (formattedResults.length > 0) {
            const allAvailableColumns = Object.keys(formattedResults[0]).filter(
              (col) => col !== "id" && col !== "hasErrors" && col !== "isVerified" && col !== "status" && col !== "date"
            )
            setAllColumns(allAvailableColumns)
            setVisibleColumns(allAvailableColumns) // Inicialmente mostramos todas
          }
        } else {
          setError("No se pudieron cargar los resultados")
        }
      } catch (err) {
        console.error("Error al cargar los resultados:", err)
        setError("Error al cargar los resultados")
      } finally {
        setLoading(false)
      }
    }

    if (flow && flow.id) {
      fetchWorkflowResults()
    }
  }, [flow])

  // Función para mapear los estados de validación a los estados de la UI
  const mapValidationStatus = (status) => {
    switch (status) {
      case "Validado":
        return "complete"
      case "Pendiente":
        return "pending"
      case "Rechazado":
        return "error"
      default:
        return "pending"
    }
  }

  const handleRowClick = (data) => {
    setSelectedData(data)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  // Actualizar para recibir las categorías de filtros
  const handleFilterCategoriesUpdate = (categories) => {
    console.log("FlowDetail: Recibiendo categorías de filtros:", categories);
    setFilterCategories(categories);
  }

  const handleFilterToggle = (filter) => {
    console.log("FlowDetail: Toggle de filtro:", filter);
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter((f) => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const handleColumnToggle = (column) => {
    if (visibleColumns.includes(column)) {
      // Si la columna ya está visible, la ocultamos
      setVisibleColumns(visibleColumns.filter(col => col !== column))
    } else {
      // Si la columna está oculta, la mostramos
      setVisibleColumns([...visibleColumns, column])
    }
  }

  // Función mejorada para aplicar filtros dinámicos en FlowDetail.jsx
  const applyFilters = (data) => {
    console.log("FlowDetail: Aplicando filtros a los datos:", { activeFilters, filterCategories });
    
    // Si no hay filtros activos, devolver todos los datos
    if (activeFilters.length === 0) {
      return data;
    }
    
    return data.filter(item => {
      // El item debe cumplir con TODOS los filtros activos
      return activeFilters.every(filterId => {
        // Extraer el nombre de la categoría y el valor del filtro
        const [categoryName, filterValue] = filterId.split('___');
        
        console.log("FlowDetail: Verificando filtro:", { categoryName, filterValue, item });
        
        // Verificar si el item tiene la propiedad correspondiente
        if (item.hasOwnProperty(categoryName)) {
          // Convertir ambos valores a string para comparación
          const itemValue = String(item[categoryName]);
          
          console.log("FlowDetail: Comparando valores:", { 
            itemValue, 
            filterValue, 
            match: itemValue === filterValue 
          });
          
          // Si coincide, este filtro se cumple
          return itemValue === filterValue;
        }
        
        // Manejar filtros predefinidos
        switch (filterId) {
          case "hasErrors":
            return item.hasErrors;
          case "notVerified":
            return item.isVerified === false;
          case "isVerified":
            return item.isVerified;
          case "status_complete":
            return item.status === "complete";
          case "status_pending":
            return item.status === "pending";
          case "thisMonth":
            if (!item.date) return false;
            const itemDate = new Date(item.date);
            const now = new Date();
            return itemDate.getMonth() === now.getMonth() && itemDate.getFullYear() === now.getFullYear();
          case "lastMonth":
            if (!item.date) return false;
            const itemDateLastMonth = new Date(item.date);
            const nowLastMonth = new Date();
            const lastMonth = nowLastMonth.getMonth() === 0 ? 11 : nowLastMonth.getMonth() - 1;
            const lastMonthYear = nowLastMonth.getMonth() === 0 ? nowLastMonth.getFullYear() - 1 : nowLastMonth.getFullYear();
            return itemDateLastMonth.getMonth() === lastMonth && itemDateLastMonth.getFullYear() === lastMonthYear;
          case "noErrors":
            return item.hasErrors === false;
          default:
            return false; // Si no es un filtro predefinido y no coincide con la propiedad, no cumple
        }
      });
    });
  };

  // Aplicar búsqueda de texto
  const applySearch = (data) => {
    if (!searchTerm) return data;
    
    const searchLower = searchTerm.toLowerCase();
    return data.filter(item => {
      return Object.values(item).some(
        (value) => typeof value === "string" && value.toLowerCase().includes(searchLower)
      );
    });
  }

  // Aplicar filtros y búsqueda en secuencia
  const filteredData = applySearch(applyFilters(flowResults));

  // Crear un objeto que mapee los nombres de columnas originales a nombres formateados
  const columnLabels = {};
  allColumns.forEach(column => {
    columnLabels[column] = formatColumnName(column);
  });

  if (loading) {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className="space-y-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center">
            <Button variant="ghost" size="icon" onClick={onBack} className="mr-2">
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <div>
              <h2 className="text-2xl font-bold text-slate-800 dark:text-slate-100">{flow.name}</h2>
              <p className="text-slate-500 dark:text-slate-400">Cargando resultados...</p>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-slate-800 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 p-8">
          <div className="flex justify-center items-center h-64">
            <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
          </div>
        </div>
      </motion.div>
    )
  }

  if (error) {
    return (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} className="space-y-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center">
            <Button variant="ghost" size="icon" onClick={onBack} className="mr-2">
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <div>
              <h2 className="text-2xl font-bold text-slate-800 dark:text-slate-100">{flow.name}</h2>
              <p className="text-slate-500 dark:text-slate-400">Error al cargar resultados</p>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-slate-800 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 p-8 text-center">
          <p className="text-red-500 font-medium">{error}</p>
        </div>
      </motion.div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div className="flex items-center">
          <Button variant="ghost" size="icon" onClick={onBack} className="mr-2">
            <ArrowLeft className="h-5 w-5" />
          </Button>
          <div>
            <h2 className="text-2xl font-bold text-slate-800 dark:text-slate-100">
              {flow.name}
            </h2>
            <p className="text-slate-500 dark:text-slate-400">
              {filteredData.length} of {flowResults.length} shown results
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white dark:bg-slate-800 rounded-lg shadow-sm border border-slate-200 dark:border-slate-700 overflow-hidden">
        <div className="p-4 border-b border-slate-200 dark:border-slate-700 flex flex-col md:flex-row gap-4 items-start md:items-center justify-between">
          <div className="relative w-full md:w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-slate-400" />
            <Input
              placeholder="Buscar registros..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8"
            />
          </div>

          <div className="flex flex-wrap gap-2 items-center">
            <FilterControls
              data={flowResults}
              activeFilters={activeFilters}
              onFilterToggle={handleFilterToggle}
              flowId={flow.id}
              clientId="2"
              onCategoriesUpdate={handleFilterCategoriesUpdate}
            />

            <Columns
              allColumns={allColumns}
              visibleColumns={visibleColumns}
              onColumnToggle={handleColumnToggle}
              columnLabels={columnLabels}
            />

            <ReportScheduler flowId={flow.id} flowName={flow.name} />

            <ExportButton
              data={filteredData}
              filename={`${flow.name
                .toLowerCase()
                .replace(/\s+/g, "-")}-export`}
            />
          </div>
        </div>

        <FlowTable
          data={filteredData}
          visibleColumns={visibleColumns}
          onRowClick={handleRowClick}
          columnLabels={columnLabels}
        />
      </div>

      {showModal && (
        <FlowModal
          data={selectedData}
          onClose={handleCloseModal}
          columnLabels={columnLabels}
          formatCellValue={formatCellValue}
        />
      )}
    </motion.div>
  );
}