import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "../../styles/businessBackofficeStyles.scss";

import mantisLogo from "../../../Assets/img/mantisBlackLogo.png";
import {
  createEmployee,
  getActiveEmployees,
  getEmployees,
} from "../../../ApiCalls/mantisBusiness";
import EmployeeFiles from "../Employees/EmployeeFiles";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function Archive(props) {
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    fetchActiveEmployees();
  }, []);

  async function fetchActiveEmployees() {
    const res = await getActiveEmployees(props.mantisBusiness._id, props.token);
    console.log(res);
    if (res.success) {
      setActiveEmployees(res.employees);
      if (props.archiveUserId) {
        const employee = res.employees.find(
          (employee) => employee.userId === props.archiveUserId
        );
        console.log(employee);
        setSelectedEmployee(employee);
      }
    }
    setLoading(false);
  }
  return (
    <div>
      {/* <Row>
        <Col
          xs={11}
          style={{
            margin: "auto"
          }}
        >
          <Row>
            <Col xs={6}>
              <h1 className="business-viewpanel-title">Archivo</h1>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <div>
            {loading ? (
              <Spinner color="white" />
            ) : !selectedEmployee ? (
              <Table>
                <thead>
                  <tr
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                    }}
                  >
                    <th>Usuario</th>
                    <th>ID</th>
                    <th>Documentos</th>
                  </tr>
                </thead>
                <tbody>
                  {activeEmployees.map((employee, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          setSelectedEmployee(employee);
                        }}
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 400,
                          color: "white",
                        }}
                      >
                        <td>
                          {employee.user.firstName} {employee.user.lastName}
                        </td>
                        <td>
                          {employee.user.identificationType}{" "}
                          {employee.user.identification}
                        </td>
                        <td>{employee.documents}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <EmployeeFiles
                employee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(Archive);
