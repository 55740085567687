"use client"

import { useState, useRef, useEffect } from "react"
import MessageInput from "./MessageInput"
import ChatMessage from "./ChatMessage"
import ChatSkeleton from "./ChatSkeleton"
import ProgressiveLoader from "../Loader/ProgressiveLoader"
import { MoreHorizontal, Edit2, Star, Trash2 } from "lucide-react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu"
 
const ChatBox = ({
  messages,
  onSendMessage,
  active,
  showHeader = false,
  isProcessing = false,
  onProcessingComplete,
}) => {
  const messagesEndRef = useRef(null)
  const [showWelcome, setShowWelcome] = useState(true)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [isThinking, setIsThinking] = useState(false)
  const [projectName, setProjectName] = useState("Gestión Documental IA")
  const [isEditingName, setIsEditingName] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, isProcessing])

  // Manejar la transición entre vistas
  const handleSendMessage = (message) => {
    setIsTransitioning(true)
    setIsThinking(true)

    // Primero ocultar la vista de bienvenida
    setTimeout(() => {
      setShowWelcome(false)

      // Luego procesar el mensaje
      setTimeout(() => {
        onSendMessage(message)
        setIsTransitioning(false)
        setIsThinking(false)
      }, 300)
    }, 300)
  }

  const handleChangeName = () => {
    setIsEditingName(true)
  }

  const handleNameSubmit = (e) => {
    e.preventDefault()
    setIsEditingName(false)
  }

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite)
  }

  const handleDelete = () => {
    // Aquí iría la lógica para eliminar el proyecto
    alert("Proyecto eliminado")
  }

  // Componente de encabezado con nombre del proyecto y menú
  const ProjectHeader = () => (
    <div className="flex items-center justify-between p-4 border-b border-gray-100 bg-white shadow-sm">
      <div className="flex items-center">
        {isEditingName ? (
          <form onSubmit={handleNameSubmit} className="flex items-center">
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="border border-gray-300 rounded px-2 py-1 text-sm"
              autoFocus
              onBlur={handleNameSubmit}
            />
          </form>
        ) : (
          <h2 className="text-lg font-medium flex items-center">
            {projectName}
            {isFavorite && <Star className="h-4 w-4 ml-2 fill-yellow-400 text-yellow-400" />}
          </h2>
        )}
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="p-1 rounded-full hover:bg-gray-100 transition-colors">
            <MoreHorizontal className="h-5 w-5 text-gray-500" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={handleChangeName}>
            <Edit2 className="h-4 w-4 mr-2" />
            <span>Cambiar nombre</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleToggleFavorite}>
            <Star className={`h-4 w-4 mr-2 ${isFavorite ? "fill-yellow-400 text-yellow-400" : ""}`} />
            <span>{isFavorite ? "Quitar de favoritos" : "Añadir a favoritos"}</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDelete}>
            <Trash2 className="h-4 w-4 mr-2 text-red-500" />
            <span className="text-red-500">Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )

  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-gray-50">
      {showWelcome ? (
        // Vista inicial con diseño mejorado inspirado en la imagen de referencia
        <div
          className={`flex flex-col items-center justify-center w-full transition-all duration-700 ${
            isTransitioning
              ? "opacity-0 transform -translate-y-10"
              : "opacity-100 animate-in fade-in-0 zoom-in-95 duration-700"
          }`}
        >
          <div className="w-16 h-16 bg-gradient-to-br from-orange-400 via-orange-300 to-yellow-300 rounded-full flex items-center justify-center mb-8 shadow-lg shadow-orange-200">
            <div className="w-10 h-10 text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2z" />
                <path d="M9 9h1" />
                <path d="M9 13h6" />
                <path d="M9 17h6" />
              </svg>
            </div>
          </div>

          <h1 className="text-4xl font-bold text-gray-800 text-center mb-3 tracking-tight">
            Idea a documento en segundos.
          </h1>

          <p className="text-lg text-gray-500 text-center max-w-md leading-relaxed mb-10">
            Estoy aquí para asistirte con la gestión inteligente de tus documentos y optimizar tus flujos de trabajo
          </p>

          <div className="w-[550px] overflow-hidden bg-white rounded-xl shadow-md border border-gray-100 mb-4 transition-all duration-300 hover:shadow-lg">
            <div className="p-3">
              <MessageInput onSendMessage={handleSendMessage} accentColor="orange" />
            </div>
          </div>

          <div className="text-xs text-gray-400 mt-2">Escribe tu consulta y presiona Enter</div>
        </div>
      ) : (
        // Vista de chat a pantalla completa cuando se desplaza a la izquierda
        <div
          className={`flex flex-col w-full h-screen bg-white transition-all duration-500 ${
            isTransitioning ? "opacity-0" : "opacity-100"
          }`}
        >
          {/* Solo mostrar el encabezado si showHeader es true */}
          {showHeader && <ProjectHeader />}

          <div className="flex-1 overflow-y-auto p-4">
            <div className="space-y-4 max-w-full mx-auto">
              {messages.map((message) => (
                <ChatMessage key={message.id} message={message} />
              ))}

              {/* Mostrar el loader progresivo cuando está procesando */}
              {isProcessing && (
                <div className="py-4 animate-in fade-in-0 zoom-in-95">
                  <ProgressiveLoader onComplete={onProcessingComplete} duration={10000} />
                </div>
              )}

              {/* Esqueleto de carga cuando la IA está "pensando" */}
              {isThinking && <ChatSkeleton accentColor="orange" />}

              <div ref={messagesEndRef} />
            </div>
          </div>

          <div className="p-3 border-t border-gray-100 bg-white">
            <div className="max-w-full mx-auto">
              <MessageInput onSendMessage={onSendMessage} disabled={isThinking || isProcessing} accentColor="orange" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatBox

