"use client"

import { useState, useCallback } from "react"
import { Handle, Position, useReactFlow } from "reactflow"
import { Search, Edit2, Trash2, Copy, Settings } from "lucide-react"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuSeparator,
} from "../../../../components/ui/context-menu"
import ActionNodeModal from "../modals/ActionNodeModal"

const ActionNode = ({ id, data, isConnectable }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [label, setLabel] = useState(data.label)
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false)
  const reactFlowInstance = useReactFlow()

  const handleDoubleClick = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsEditing(false)
    data.label = label
  }, [data, label])

  const handleKeyDown = useCallback(
    (evt) => {
      if (evt.key === "Enter") {
        setIsEditing(false)
        data.label = label
      }
    },
    [data, label],
  )

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleDelete = useCallback(() => {
    reactFlowInstance.setNodes((nodes) => nodes.filter((node) => node.id !== id))
    // También eliminar las conexiones asociadas
    reactFlowInstance.setEdges((edges) => edges.filter((edge) => edge.source !== id && edge.target !== id))
  }, [id, reactFlowInstance])

  const handleDuplicate = useCallback(() => {
    const position = reactFlowInstance.getNode(id).position
    const newNode = {
      id: `actionNode-${Date.now()}`,
      type: "actionNode",
      position: { x: position.x + 50, y: position.y + 50 },
      data: {
        label: `${data.label} (copia)`,
        description: data.description,
        config: data.config || {},
      },
    }
    reactFlowInstance.addNodes(newNode)
  }, [id, data, reactFlowInstance])

  const handleOpenConfig = useCallback(() => {
    setIsConfigModalOpen(true)
  }, [])

  const handleSaveConfig = useCallback(
    (updatedData) => {
      // Actualizar los datos del nodo
      const { label: newLabel, description, config } = updatedData

      // Actualizar el estado local
      setLabel(newLabel)

      // Actualizar los datos del nodo en ReactFlow
      reactFlowInstance.setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                label: newLabel,
                description,
                config,
              },
            }
          }
          return node
        }),
      )
    },
    [id, reactFlowInstance],
  )

  return (
    <>
      <ContextMenu>
        <ContextMenuTrigger>
          <div
            className="w-[180px] p-3 rounded-lg border-2 border-amber-300 bg-amber-50 shadow-md hover:shadow-lg transition-shadow"
            onDoubleClick={handleDoubleClick}
          >
            <Handle
              type="target"
              position={Position.Top}
              isConnectable={isConnectable}
              className="w-3 h-3 bg-amber-500 border-2 border-white"
              style={{ zIndex: 10 }}
            />

            <Handle
              type="target"
              position={Position.Left}
              id="left"
              isConnectable={isConnectable}
              className="w-3 h-3 bg-amber-500 border-2 border-white"
              style={{ zIndex: 10 }}
            />

            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center mb-2 text-amber-600">
                <Search className="h-6 w-6" />
              </div>

              {isEditing ? (
                <input
                  type="text"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  className="w-full text-sm text-center bg-white border border-amber-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-amber-500"
                  autoFocus
                />
              ) : (
                <div className="flex items-center justify-center">
                  <div className="text-sm font-medium text-center text-amber-800">{data.label}</div>
                  <Edit2 className="h-3 w-3 ml-1 text-amber-400 opacity-50" />
                </div>
              )}

              {data.description && (
                <div className="mt-1 text-xs text-amber-600/70 text-center max-w-[160px] truncate">
                  {data.description}
                </div>
              )}
            </div>

            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={isConnectable}
              className="w-3 h-3 bg-amber-500 border-2 border-white"
              style={{ zIndex: 10 }}
            />
          </div>
        </ContextMenuTrigger>
        <ContextMenuContent className="w-48">
          <ContextMenuItem onClick={handleEdit}>
            <Edit2 className="h-4 w-4 mr-2" />
            <span>Editar nombre</span>
          </ContextMenuItem>
          <ContextMenuItem onClick={handleOpenConfig}>
            <Settings className="h-4 w-4 mr-2" />
            <span>Configurar</span>
          </ContextMenuItem>
          <ContextMenuItem onClick={handleDuplicate}>
            <Copy className="h-4 w-4 mr-2" />
            <span>Duplicar</span>
          </ContextMenuItem>
          <ContextMenuSeparator />
          <ContextMenuItem onClick={handleDelete} className="text-red-500 focus:text-red-500">
            <Trash2 className="h-4 w-4 mr-2" />
            <span>Eliminar</span>
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>

      <ActionNodeModal
        isOpen={isConfigModalOpen}
        onClose={() => setIsConfigModalOpen(false)}
        nodeData={data}
        onSave={handleSaveConfig}
      />
    </>
  )
}

export default ActionNode

