import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
  Input,
  FormGroup,
  UncontrolledTooltip,
  Label,
  Form,
} from "reactstrap";
import {
  getBusinessFolderById,
  getDocumentSignedUrl,
  getFormatFieldValues,
  moveDocumentsToFolder,
  updateDocument,
} from "../../ApiCalls/mantisBusiness";
import { FaChevronRight } from "react-icons/fa";
import "../styles/Archivo.css";
import { ExternalLink } from "lucide-react";
import { fileToBase64 } from "../../utils/base64";

const DocumentMasterModal = ({
  isOpen,
  toggle,
  document,
  token,
  mantisBusinessId,
  originalFormatName,
  handleUpdate,
  documentFormats,
}) => {
  const [updatingDocument, setUpdatingDocument] = useState(false);
  const [localDocument, setLocalDocument] = useState(document);
  const [gettingUrl, setGettingUrl] = useState(true);
  const [signedUrl, setSignedUrl] = useState("");
  const [extractedFields, setExtractedFields] = useState(
    Object.entries(
      localDocument.extractedFields ? localDocument.extractedFields : {}
    ).map(([key, value]) => {
      return { name: key, value: value };
    }) || []
  );
  const [assigningFormat, setAssigningFormat] = useState(false);
  const [selectedDocumentFormat, setSelectedDocumentFormat] = useState("");
  const [fetchingFormatValues, setFetchingFormatValues] = useState(false);
  const [formatValues, setFormatValues] = useState([]);
  const [suggestedFilename, setSuggestedFilename] = useState("");
  const [suggestedFileLocation, setSuggestedFileLocation] = useState([]);
  const [formatName, setFormatName] = useState(originalFormatName);

  useEffect(() => {
    getSignedUrl();
  }, [localDocument]);

  useEffect(() => {
    if (selectedDocumentFormat) {
      setFormatName(
        documentFormats.find((format) => format._id === selectedDocumentFormat)
          .name
      );
    } else {
      setFormatName(originalFormatName);
    }
  }, [selectedDocumentFormat]);

  const handleGetFormatValues = async () => {
    if (fetchingFormatValues) {
      return;
    }
    setFetchingFormatValues(true);
    let valuesRes = await getFormatFieldValues(
      token,
      mantisBusinessId,
      selectedDocumentFormat,
      "",
      document.name,
      document.sizeInBytes || 0,
      document.type,
      document._id
    );
    if (valuesRes.success) {
      setFormatValues(valuesRes.values.values);
      setSuggestedFilename(valuesRes.values.suggestedFilename);
      if (valuesRes.values.suggestedFileLocation) {
        setSuggestedFileLocation(
          valuesRes.values.suggestedFileLocation.split(">")
        );
      }
    } else {
      alert("Error al obtener los valores del formato");
    }
    setFetchingFormatValues(false);
  };

  const getSignedUrl = async () => {
    if (localDocument.remoteFile.includes("http")) {
      setSignedUrl(localDocument.remoteFile);
      setGettingUrl(false);
    } else {
      const signedUrl = await getDocumentSignedUrl(
        token,
        localDocument._id,
        mantisBusinessId
      );
      if (signedUrl.success) {
        setSignedUrl(signedUrl.signedUrl);
      } else {
        alert("Error al abrir el documento");
      }
    }
    setGettingUrl(false);
  };

  const handleUpdateDocument = async () => {
    setUpdatingDocument(true);
    try {
      let documentData = {
        ...localDocument,
        extractedFields:
          formatValues.length > 0
            ? formatValues.reduce((acc, item) => {
                acc[item.name] = item.value;
                return acc;
              }, {})
            : extractedFields.reduce((acc, item) => {
                acc[item.name] = item.value;
                return acc;
              }, {}),
      };
      if (selectedDocumentFormat) {
        documentData.documentFormatId = selectedDocumentFormat;
      }
      const response = await updateDocument(
        localDocument._id,
        documentData,
        mantisBusinessId,
        token
      );
      if (response.success) {
        setUpdatingDocument(false);
        toggle();
        handleUpdate(response.document);
      } else {
        alert("Error al actualizar el documento");
      }
    } catch (e) {
      alert("Error al actualizar el documento");
    } finally {
      setUpdatingDocument(false);
    }
  };

  const handleOpenDocument = async () => {
    window.open(signedUrl, "_blank");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          if (updatingDocument || fetchingFormatValues) return;
          toggle();
        }}
        className="document-master-modal"
        centered
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            if (updatingDocument || fetchingFormatValues) return;
            toggle();
          }}
          className="modal-header"
        >
          <div className="inline-flex items-center gap-1 w-full">
            <input
              type="text"
              value={localDocument.name}
              onChange={(e) => {
                setLocalDocument({
                  ...localDocument,
                  name: e.target.value,
                });
              }}
              className="document-name-input"
            />
            <div className="flex items-center gap-1">
              <ExternalLink
                className="open-external-link"
                size={20}
                onClick={() => handleOpenDocument()}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="modal-body overflow-hidden">
          <Row className="w-full">
            <Col
              xs={extractedFields.length > 0 || assigningFormat ? 8 : 12}
              className="overflow-scroll h-adjust flex items-center justify-center"
            >
              {signedUrl && (
                <>
                  {localDocument.type.includes("pdf") ? (
                    <iframe
                      src={signedUrl}
                      id="pdf-preview"
                      title="PDF Preview"
                    />
                  ) : localDocument.type.includes("xlsx") ||
                    localDocument.type.includes("xls") ||
                    localDocument.type.includes("sheet") ||
                    localDocument.type.includes("spreadsheet") ||
                    localDocument.type.includes("excel") ? (
                    <iframe
                      src={`https://view.officeapps.live.com/op/embed.aspx?src=${signedUrl}`}
                      id="excel-preview"
                      title="Excel Preview"
                    />
                  ) : (
                    <img
                      src={signedUrl}
                      alt="Document"
                      className="document-image"
                    />
                  )}
                </>
              )}
              {gettingUrl && (
                <div className="loading-container">
                  <Spinner className="main-spinner" />
                </div>
              )}
            </Col>
            {assigningFormat ? (
              <>
                {formatValues.length > 0 ? (
                  <>
                    <Col xs={4} className="file-info overflow-scroll">
                      <div className="format-values-container">
                        <h5 className="modal-subtitle">
                          Valores extraídos del formato
                          {formatName ? `: ${formatName}` : ""}
                        </h5>
                        <Row>
                          {formatValues.map((item, index) => (
                            <Col xs={6} key={item.id}>
                              <FormGroup>
                                <Row>
                                  <Col xs={12}>
                                    <Input
                                      type="text"
                                      defaultValue={item.name}
                                      style={{
                                        color: "white",
                                        display: "inline-block",
                                        marginRight: "10px",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        padding: 0,
                                        height: "auto",
                                        overflow: "visible",
                                      }}
                                      value={item.name}
                                      onChange={(e) => {
                                        let updatedValues = formatValues.map(
                                          (currentItem, currentIndex) => {
                                            if (currentIndex === index) {
                                              return {
                                                ...currentItem,
                                                name: e.target.value,
                                              };
                                            }
                                            return currentItem;
                                          }
                                        );
                                        setFormatValues(updatedValues);
                                      }}
                                    />
                                    <Button
                                      style={{
                                        backgroundColor: "#282828",
                                        color: "white",
                                        width: "28px",
                                        height: "28px",
                                        display: "inline-block",
                                        padding: 0,
                                      }}
                                      id={
                                        "UncontrolledTooltipFormatFieldDelete-" +
                                        index
                                      }
                                      onClick={() => {
                                        let formatValuesCopy = [
                                          ...formatValues,
                                        ];
                                        formatValuesCopy.splice(index, 1);
                                        setFormatValues(formatValuesCopy);
                                      }}
                                    >
                                      <i
                                        className="fas fa-trash"
                                        style={{
                                          fontSize: "10px",
                                          color: "red",
                                        }}
                                      ></i>
                                      <UncontrolledTooltip
                                        style={{
                                          backgroundColor: "black",
                                          color: "white",
                                          fontFamily: "Poppins, sans-serif",
                                        }}
                                        placement="top"
                                        target={
                                          "UncontrolledTooltipFormatFieldDelete-" +
                                          index
                                        }
                                      >
                                        Eliminar campo
                                      </UncontrolledTooltip>
                                    </Button>
                                    <Button
                                      style={{
                                        backgroundColor: "#282828",
                                        color: "white",
                                        width: "28px",
                                        height: "28px",
                                        display: "inline-block",
                                        padding: 0,
                                        marginLeft: "10px",
                                      }}
                                      id={
                                        "UncontrolledTooltipFormatFieldAdd-" +
                                        index
                                      }
                                      onClick={() => {
                                        let formatValuesCopy = [
                                          ...formatValues,
                                        ];
                                        formatValuesCopy.splice(index + 1, 0, {
                                          name: "Nuevo Valor",
                                          value: "",
                                        });
                                        setFormatValues(formatValuesCopy);
                                      }}
                                    >
                                      <i
                                        className="fas fa-plus"
                                        style={{
                                          fontSize: "10px",
                                          color: "white",
                                        }}
                                      ></i>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={
                                          "UncontrolledTooltipFormatFieldAdd-" +
                                          index
                                        }
                                        style={{
                                          backgroundColor: "black",
                                          color: "white",
                                          fontFamily: "Poppins, sans-serif",
                                        }}
                                      >
                                        Agregar campo
                                      </UncontrolledTooltip>
                                    </Button>
                                  </Col>
                                </Row>
                                <Input
                                  type="text"
                                  name={item.name}
                                  id={item.name}
                                  value={item.value}
                                  onChange={(e) => {
                                    let formatValuesCopy = [...formatValues];
                                    formatValuesCopy[index].value =
                                      e.target.value;
                                    setFormatValues(formatValuesCopy);
                                  }}
                                  style={{
                                    marginTop: "5px",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          ))}
                        </Row>
                      </div>
                      <div className="flex flex-col gap-2">
                        <Button
                          className="modal-button primary"
                          onClick={() => {
                            setFormatValues([]);
                            setFetchingFormatValues(false);
                            setSelectedDocumentFormat("");
                          }}
                        >
                          Seleccionar otro formato
                        </Button>
                        <Button
                          className="modal-button secondary"
                          onClick={() => {
                            setSelectedDocumentFormat("");
                            setFetchingFormatValues(false);
                            setAssigningFormat(false);
                            setFormatValues(document.extractedFields || []);
                          }}
                          disabled={fetchingFormatValues}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  <Col xs={4} className="file-info overflow-scroll">
                    <Form>
                      <FormGroup>
                        <Label for="documentFormat" className="modal-label">
                          Seleccionar Formato
                        </Label>

                        <Input
                          type="select"
                          name="documentFormat"
                          id="documentFormat"
                          value={selectedDocumentFormat}
                          onChange={(e) =>
                            setSelectedDocumentFormat(e.target.value)
                          }
                          disabled={fetchingFormatValues}
                          className="modal-input"
                        >
                          <option value="">Seleccione un formato</option>
                          {documentFormats.map((format) => (
                            <option key={format._id} value={format._id}>
                              {format.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <div className="flex flex-col gap-2">
                        <Button
                          className="modal-button primary"
                          onClick={handleGetFormatValues}
                          disabled={
                            !selectedDocumentFormat || fetchingFormatValues
                          }
                        >
                          {fetchingFormatValues ? (
                            <Spinner size={"sm"} />
                          ) : (
                            "Obtener valores"
                          )}
                        </Button>
                        <Button
                          className="modal-button secondary"
                          onClick={() => {
                            setSelectedDocumentFormat("");
                            setAssigningFormat(false);
                            setFormatValues(document.extractedFields || []);
                            setFetchingFormatValues(false);
                          }}
                          disabled={fetchingFormatValues}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </Form>
                  </Col>
                )}
              </>
            ) : (
              <>
                {extractedFields.length > 0 && !assigningFormat && (
                  <Col xs={4} className="file-info overflow-scroll">
                    <div className="format-values-container">
                      <h5 className="modal-subtitle">
                        Valores extraídos del formato
                        {formatName ? `: ${formatName}` : ""}
                      </h5>
                      <Row>
                        {extractedFields.map((value, index) => (
                          <Col xs={6} key={index}>
                            <FormGroup>
                              <Row>
                                <Col xs={12}>
                                  <Input
                                    type="text"
                                    defaultValue={value.name}
                                    style={{
                                      color: "white",
                                      display: "inline-block",
                                      marginRight: "10px",
                                      border: "none",
                                      backgroundColor: "transparent",
                                      padding: 0,
                                      height: "auto",
                                      overflow: "visible",
                                    }}
                                    value={value.name}
                                    onChange={(e) => {
                                      let updatedValues = extractedFields.map(
                                        (item) => {
                                          if (item.name === value.name) {
                                            return {
                                              ...item,
                                              name: e.target.value,
                                            };
                                          }
                                          return item;
                                        }
                                      );
                                      setExtractedFields(updatedValues);
                                    }}
                                  />
                                  <Button
                                    style={{
                                      backgroundColor: "#282828",
                                      color: "white",
                                      width: "28px",
                                      height: "28px",
                                      display: "inline-block",
                                      padding: 0,
                                    }}
                                    id={
                                      "UncontrolledTooltipFormatFieldDelete-" +
                                      index
                                    }
                                    onClick={() => {
                                      let formatValuesCopy = [
                                        ...extractedFields,
                                      ];
                                      formatValuesCopy.splice(index, 1);
                                      setExtractedFields(formatValuesCopy);
                                    }}
                                  >
                                    <i
                                      className="fas fa-trash"
                                      style={{
                                        fontSize: "10px",
                                        color: "red",
                                      }}
                                    ></i>
                                    <UncontrolledTooltip
                                      style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        fontFamily: "Poppins, sans-serif",
                                      }}
                                      placement="top"
                                      target={
                                        "UncontrolledTooltipFormatFieldDelete-" +
                                        index
                                      }
                                    >
                                      Eliminar campo
                                    </UncontrolledTooltip>
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: "#282828",
                                      color: "white",
                                      width: "28px",
                                      height: "28px",
                                      display: "inline-block",
                                      padding: 0,
                                      marginLeft: "10px",
                                    }}
                                    id={
                                      "UncontrolledTooltipFormatFieldAdd-" +
                                      index
                                    }
                                    onClick={() => {
                                      let formatValuesCopy = [
                                        ...extractedFields,
                                      ];
                                      formatValuesCopy.splice(index + 1, 0, {
                                        name: "Nuevo Valor",
                                        value: "",
                                      });
                                      setExtractedFields(formatValuesCopy);
                                    }}
                                  >
                                    <i
                                      className="fas fa-plus"
                                      style={{
                                        fontSize: "10px",
                                        color: "white",
                                      }}
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={
                                        "UncontrolledTooltipFormatFieldAdd-" +
                                        index
                                      }
                                      style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        fontFamily: "Poppins, sans-serif",
                                      }}
                                    >
                                      Agregar campo
                                    </UncontrolledTooltip>
                                  </Button>
                                </Col>
                              </Row>
                              <Input
                                type="text"
                                name={value.name}
                                id={value.name}
                                value={value.value}
                                onChange={(e) => {
                                  let formatValuesCopy = [...extractedFields];
                                  formatValuesCopy[index].value =
                                    e.target.value;
                                  setExtractedFields(formatValuesCopy);
                                }}
                                style={{
                                  marginTop: "5px",
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer justify-between">
          <div className="flex items-center gap-2 justify-start">
            {!assigningFormat && (
              <Button
                disabled={updatingDocument}
                className="modal-button primary"
                onClick={() => {
                  setAssigningFormat(true);
                }}
              >
                {formatName ? "Reasignar formato" : "Asignar formato"}
              </Button>
            )}
          </div>
          <div className="flex gap-2">
            <Button
              disabled={updatingDocument || fetchingFormatValues}
              className="modal-button secondary"
              onClick={toggle}
            >
              Cerrar
            </Button>
            <Button
              className="modal-button primary"
              onClick={() => {
                handleUpdateDocument();
              }}
              disabled={updatingDocument || fetchingFormatValues}
            >
              {updatingDocument ? <Spinner size="sm" /> : "Guardar"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DocumentMasterModal;
