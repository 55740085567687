import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../styles/businessBackofficeStyles.scss";

import Received from "../User/Received";   
import Sent from "../User/Sent";           
import Internal from "../Correspondence/Internal";   

const select = (state) => {
  return {
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function DocumentSection(props) {
  const [tab, setTab] = useState("RECEIVED"); 

  useEffect(() => {
    console.log("BusinessBackoffice mounted");
    console.log("Current activeSection:", props.activeSection);
  }, [props.activeSection]);

  const activeView = tab;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "20px",
        }}
      >
        {/* Tab Sent - Interno */}
        <div
          className={tab === "SENT" ? "active-business-tab" : "business-tab"}
          onClick={() => setTab("SENT")}
        >
          <h4 className="business-viewpanel-tab-title">Interno</h4>
        </div>
        {/* Tab Received - Externo */}
        <div
          className={tab === "RECEIVED" ? "active-business-tab" : "business-tab"}
          onClick={() => setTab("RECEIVED")}
        >
          <h4 className="business-viewpanel-tab-title">Externo</h4>
        </div>
        {/* Tab Internal - Salida */}
        <div
          className={tab === "INTERNAL" ? "active-business-tab" : "business-tab"}
          onClick={() => setTab("INTERNAL")}
        >
          <h4 className="business-viewpanel-tab-title">Salida</h4>
        </div>
      </div>

      {/* Render content depending on the active tab */}
      {tab === "SENT" && <Sent {...props} activeView={activeView}/>}
      {tab === "RECEIVED" && <Received {...props} activeView={activeView}/>}
      {tab === "INTERNAL" && <Internal {...props} activeView={activeView}/>}
    </div>
  );
}

export default connect(select)(DocumentSection);