export const mockFlows = [
  {
    id: "flow-1",
    name: "Invoice Processing",
    description: "Extracts data from invoice documents including vendor information, amounts, and dates.",
    status: "active",
    documentCount: 128,
    data: [
      {
        id: "doc-1",
        documentName: "Invoice-ABC-123.pdf",
        documentUrl: "/documents/invoice-abc-123.pdf",
        vendor: "ABC Corporation",
        invoiceNumber: "INV-2023-001",
        date: "2023-03-15",
        amount: "$1,250.00",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-2",
        documentName: "Invoice-XYZ-456.pdf",
        documentUrl: "/documents/invoice-xyz-456.pdf",
        vendor: "XYZ Industries",
        invoiceNumber: "INV-2023-002",
        date: "2023-03-18",
        amount: "$2,780.50",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-3",
        documentName: "Invoice-DEF-789.pdf",
        documentUrl: "/documents/invoice-def-789.pdf",
        vendor: "DEF Services",
        invoiceNumber: "INV-2023-003",
        date: "2023-03-20",
        amount: "$950.25",
        status: "pending",
        isVerified: false,
        hasErrors: true,
      },
      {
        id: "doc-4",
        documentName: "Invoice-GHI-101.pdf",
        documentUrl: "/documents/invoice-ghi-101.pdf",
        vendor: "GHI Enterprises",
        invoiceNumber: "INV-2023-004",
        date: "2023-03-22",
        amount: "$3,420.75",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-5",
        documentName: "Invoice-JKL-112.pdf",
        documentUrl: "/documents/invoice-jkl-112.pdf",
        vendor: "JKL Corporation",
        invoiceNumber: "INV-2023-005",
        date: "2023-03-25",
        amount: "$1,875.30",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-6",
        documentName: "Invoice-MNO-131.pdf",
        documentUrl: "/documents/invoice-mno-131.pdf",
        vendor: "MNO Solutions",
        invoiceNumber: "INV-2023-006",
        date: "2023-03-28",
        amount: "$2,340.60",
        status: "pending",
        isVerified: false,
        hasErrors: false,
      },
      {
        id: "doc-7",
        documentName: "Invoice-PQR-415.pdf",
        documentUrl: "/documents/invoice-pqr-415.pdf",
        vendor: "PQR Industries",
        invoiceNumber: "INV-2023-007",
        date: "2023-03-30",
        amount: "$4,125.90",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-8",
        documentName: "Invoice-STU-161.pdf",
        documentUrl: "/documents/invoice-stu-161.pdf",
        vendor: "STU Enterprises",
        invoiceNumber: "INV-2023-008",
        date: "2023-04-02",
        amount: "$1,560.45",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-9",
        documentName: "Invoice-VWX-718.pdf",
        documentUrl: "/documents/invoice-vwx-718.pdf",
        vendor: "VWX Corporation",
        invoiceNumber: "INV-2023-009",
        date: "2023-04-05",
        amount: "$3,780.20",
        status: "pending",
        isVerified: false,
        hasErrors: true,
      },
      {
        id: "doc-10",
        documentName: "Invoice-YZA-192.pdf",
        documentUrl: "/documents/invoice-yza-192.pdf",
        vendor: "YZA Services",
        invoiceNumber: "INV-2023-010",
        date: "2023-04-08",
        amount: "$2,950.75",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-11",
        documentName: "Invoice-BCD-203.pdf",
        documentUrl: "/documents/invoice-bcd-203.pdf",
        vendor: "BCD Solutions",
        invoiceNumber: "INV-2023-011",
        date: "2023-04-10",
        amount: "$1,840.35",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-12",
        documentName: "Invoice-EFG-214.pdf",
        documentUrl: "/documents/invoice-efg-214.pdf",
        vendor: "EFG Industries",
        invoiceNumber: "INV-2023-012",
        date: "2023-04-12",
        amount: "$3,260.80",
        status: "pending",
        isVerified: false,
        hasErrors: false,
      },
    ],
  },
  {
    id: "flow-2",
    name: "Contract Analysis",
    description: "Analyzes legal contracts to extract key terms, parties involved, and important dates.",
    status: "processing",
    documentCount: 57,
    data: [
      {
        id: "doc-1",
        documentName: "Contract-Legal-001.pdf",
        documentUrl: "/documents/contract-legal-001.pdf",
        parties: "ABC Corp, XYZ Inc",
        contractType: "Service Agreement",
        effectiveDate: "2023-02-10",
        expirationDate: "2024-02-09",
        value: "$125,000.00",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-2",
        documentName: "Contract-Legal-002.pdf",
        documentUrl: "/documents/contract-legal-002.pdf",
        parties: "DEF Services, GHI Enterprises",
        contractType: "Licensing Agreement",
        effectiveDate: "2023-02-15",
        expirationDate: "2025-02-14",
        value: "$250,000.00",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-3",
        documentName: "Contract-Legal-003.pdf",
        documentUrl: "/documents/contract-legal-003.pdf",
        parties: "JKL Corp, MNO Solutions",
        contractType: "Partnership Agreement",
        effectiveDate: "2023-02-20",
        expirationDate: "2026-02-19",
        value: "$500,000.00",
        status: "pending",
        isVerified: false,
        hasErrors: true,
      },
    ],
  },
  {
    id: "flow-3",
    name: "Resume Parsing",
    description: "Extracts candidate information from resumes including skills, experience, and education.",
    status: "error",
    documentCount: 215,
    data: [
      {
        id: "doc-1",
        documentName: "Resume-John-Doe.pdf",
        documentUrl: "/documents/resume-john-doe.pdf",
        candidateName: "John Doe",
        email: "john.doe@example.com",
        phone: "555-123-4567",
        skills: "JavaScript, React, Node.js",
        experience: "5 years",
        education: "Bachelor's in Computer Science",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-2",
        documentName: "Resume-Jane-Smith.pdf",
        documentUrl: "/documents/resume-jane-smith.pdf",
        candidateName: "Jane Smith",
        email: "jane.smith@example.com",
        phone: "555-987-6543",
        skills: "Python, Django, SQL",
        experience: "3 years",
        education: "Master's in Information Technology",
        status: "complete",
        isVerified: true,
        hasErrors: false,
      },
      {
        id: "doc-3",
        documentName: "Resume-Bob-Johnson.pdf",
        documentUrl: "/documents/resume-bob-johnson.pdf",
        candidateName: "Bob Johnson",
        email: "bob.johnson@example.com",
        phone: "555-456-7890",
        skills: "Java, Spring, Hibernate",
        experience: "7 years",
        education: "PhD in Computer Engineering",
        status: "pending",
        isVerified: false,
        hasErrors: true,
      },
    ],
  },
  {
    id: "flow-4",
    name: "Medical Records",
    description: "Processes medical records to extract patient information, diagnoses, and treatments.",
    status: "active",
    documentCount: 89,
    data: [],
  },
  {
    id: "flow-5",
    name: "Financial Reports",
    description: "Analyzes financial reports to extract key metrics, trends, and forecasts.",
    status: "active",
    documentCount: 42,
    data: [],
  },
  {
    id: "flow-6",
    name: "Legal Documents",
    description: "Processes legal documents to extract clauses, terms, and conditions.",
    status: "processing",
    documentCount: 31,
    data: [],
  },
]

