import axios from "axios";
import { backUrl } from "../utils/backURL";
const API_URL = backUrl;

export const getFlows = async (mantisBusinessId) => {
  try {
    // Enviar evento para mostrar esqueleto
    const event = new CustomEvent('flowsLoading', { detail: true });
    window.dispatchEvent(event);

    const response = await axios.get(`${API_URL}/workflows/${mantisBusinessId}`);
    
    // Enviar evento para ocultar esqueleto
    const endEvent = new CustomEvent('flowsLoading', { detail: false });
    window.dispatchEvent(endEvent);
    
    return response.data;
  } catch (error) {
    // Enviar evento para ocultar esqueleto en caso de error
    const endEvent = new CustomEvent('flowsLoading', { detail: false });
    window.dispatchEvent(endEvent);
    
    console.error("Error al obtener los flujos:", error.response?.data || error);
    throw error;
  }
};

export const getWorkflowResults = async (clientId, workflowId) => {
  try {
    // Enviar evento para mostrar esqueleto
    const event = new CustomEvent('resultsLoading', { detail: true });
    window.dispatchEvent(event);

    const response = await axios.get(`${API_URL}/workflows/${clientId}/results/${workflowId}`);
    
    // Enviar evento para ocultar esqueleto
    const endEvent = new CustomEvent('resultsLoading', { detail: false });
    window.dispatchEvent(endEvent);
    
    return response.data;
  } catch (error) {
    // Enviar evento para ocultar esqueleto en caso de error
    const endEvent = new CustomEvent('resultsLoading', { detail: false });
    window.dispatchEvent(endEvent);
    
    console.error("Error al obtener los resultados del workflow:", error.response?.data || error);
    throw error;
  }
};

// Función para obtener opciones de filtrado de un workflow específico
export const getWorkflowFilterOptions = async (clientId, workflowId) => {
  console.log("API Call: Iniciando getWorkflowFilterOptions", { clientId, workflowId });
  
  try {
    const url = `${API_URL}/workflows/${clientId}/filters/${workflowId}`;
    console.log("API Call: Realizando petición a:", url);
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log("API Call: Estado de respuesta:", response.status);
    
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log("API Call: Datos recibidos:", data);
    return data;
  } catch (error) {
    console.error('API Call: Error en getWorkflowFilterOptions:', error);
    throw error;
  }
};
