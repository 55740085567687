"use client"

import { useState, useRef, useEffect, useCallback } from "react"
import ChatBox from "../../Sections/FlowCreator/Chat/ChatBox"
import FlowVisualization from "../../Sections/FlowCreator/FlowVisualization/FlowVisualization"
import DynamicLoader from "../../Sections/FlowCreator/Loader/DynamicLoader"

export default function FlowCreator() {
  const [chatActive, setChatActive] = useState(true)
  const [flowActive, setFlowActive] = useState(false)
  const [messages, setMessages] = useState([])
  const [chatWidth, setChatWidth] = useState(30) // Porcentaje inicial ajustado a 30%
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [flowReady, setFlowReady] = useState(false)
  const containerRef = useRef(null)
  const isDraggingRef = useRef(false)

  const handleSendMessage = (message) => {
    // Add user message
    const newMessages = [...messages, { id: Date.now(), content: message, sender: "user" }]
    setMessages(newMessages)

    // Iniciar el proceso de carga progresiva
    setIsProcessing(true)

    // Activate flow visualization with animation
    if (!flowActive) {
      setTimeout(() => {
        setFlowActive(true)
      }, 500)
    }
  }

  // Función para manejar la finalización del loader progresivo
  const handleProcessingComplete = () => {
    setIsProcessing(false)
    setFlowReady(true)

    // Añadir mensaje de respuesta de la IA
    const aiResponseId = Date.now() + 1
    setMessages((prev) => [
      ...prev,
      {
        id: aiResponseId,
        content:
          "He procesado tu solicitud. El flujo de documentos ha sido generado y optimizado según tus necesidades.",
        sender: "ai",
      },
    ])
  }

  // Función para manejar el redimensionamiento
  const handleResizeStart = useCallback((e) => {
    e.preventDefault()
    isDraggingRef.current = true
    document.addEventListener("mousemove", handleResize)
    document.addEventListener("mouseup", handleResizeEnd)
  }, [])

  const handleResize = useCallback((e) => {
    if (!isDraggingRef.current) return

    const container = containerRef.current
    if (!container) return

    const containerRect = container.getBoundingClientRect()
    const newChatWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100

    // Limitar el ancho entre 20% y 50%
    if (newChatWidth >= 20 && newChatWidth <= 50) {
      // Redondear para evitar micro-actualizaciones
      const roundedWidth = Math.round(newChatWidth * 10) / 10

      setChatWidth((prev) => {
        if (Math.abs(prev - roundedWidth) > 0.1) {
          return roundedWidth
        }
        return prev
      })
    }
  }, [])

  const handleResizeEnd = useCallback(() => {
    isDraggingRef.current = false
    document.removeEventListener("mousemove", handleResize)
    document.removeEventListener("mouseup", handleResizeEnd)
  }, [handleResize])

  // Limpiar event listeners al desmontar
  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleResize)
      document.removeEventListener("mouseup", handleResizeEnd)
    }
  }, [handleResize, handleResizeEnd])

  return (
    <main className="flex h-screen w-screen overflow-hidden bg-gray-50">
      <DynamicLoader isLoading={isLoading} />

      <div ref={containerRef} className="flex w-full h-full transition-all duration-500 ease-in-out">
        <div
          className="transition-all duration-500 ease-in-out h-full"
          style={{ width: flowActive ? `${chatWidth}%` : "100%" }}
        >
          <ChatBox
            messages={messages}
            onSendMessage={handleSendMessage}
            active={chatActive}
            showHeader={flowActive}
            isProcessing={isProcessing}
            onProcessingComplete={handleProcessingComplete}
          />
        </div>

        {flowActive && (
          <>
            {/* Divisor redimensionable con diseño mejorado */}
            <div
              className="w-1 bg-gradient-to-b from-gray-100 via-gray-200 to-gray-100 hover:bg-gradient-to-b hover:from-orange-100 hover:via-orange-200 hover:to-orange-100 cursor-col-resize h-full flex items-center justify-center transition-colors duration-300"
              onMouseDown={handleResizeStart}
            >
              <div className="w-6 h-20 flex flex-col items-center justify-center">
                <div className="w-1 h-8 bg-gradient-to-b from-gray-300 to-gray-400 rounded-full"></div>
              </div>
            </div>

            <div
              className="h-full transition-all duration-500 ease-in-out"
              style={{ width: `calc(100% - ${chatWidth}% - 4px)` }}
            >
              <FlowVisualization isReady={flowReady} />
            </div>
          </>
        )}
      </div>
    </main>
  )
}

