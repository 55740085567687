import React, {useState} from "react";
import {
  ModalBody,
  Button,
  Row,
  Col,
  Input,
  Label
} from "reactstrap";
import {FaTrash, FaPlus, FaUser, FaPhone} from "react-icons/fa";
import { toast } from "react-toastify";
import { updateMultipleWhatsappContacts } from "../../../ApiCalls/mantisBusiness";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from "../../components/ui/dialog";
import { RefreshCw } from "lucide-react";

const FlowWhatsappContactsManager = ({
  isOpen,
  toggle,
  whatsappContacts,
  token,
  mantisBusinessId,
  flow,
  onSave
}) => {
  const [contacts, setContacts] = useState(whatsappContacts || []);
  const [savingContacts, setSavingContacts] = useState(false);
  const [expandedContact, setExpandedContact] = useState(null);

  const handleEditContact = (index, field, value) => {
    let expandedContactCopy = { ...expandedContact };
    expandedContactCopy[field] = value;
    setExpandedContact(expandedContactCopy);
    
    const updatedContacts = contacts.map((contact, i) => {
      if (i === index) {
        return expandedContactCopy;
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
    if (expandedContact === contacts[index]) {
      setExpandedContact(null);
    }
  };

  const handleSave = async () => {
    setSavingContacts(true);
    try {
      const invalidContacts = contacts.filter(
        (contact) =>
          !contact.phoneNumber ||
          !contact.name ||
          contact.additionalInfo.some((info) => !info.key || !info.value)
      );
      
      if (invalidContacts.length > 0) {
        toast.error("Por favor, complete todos los campos antes de guardar");
        setSavingContacts(false);
        return;
      }
      
      const res = await updateMultipleWhatsappContacts(
        flow._id,
        contacts,
        mantisBusinessId,
        token
      );
      
      if (res.success) {
        toast.success("Contactos de WhatsApp actualizados correctamente");
        setSavingContacts(false);
        onSave(res.flow);
        setExpandedContact(null);
        setContacts(res.flow.whatsappContacts);
        toggle();
      } else {
        toast.error("Error al actualizar los contactos de WhatsApp");
        setSavingContacts(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar los contactos de WhatsApp");
      setSavingContacts(false);
    }
  };

  const handleAddNewContact = () => {
    const newContact = {
      name: "",
      phoneNumber: "",
      additionalInfo: [],
    };
    setContacts([...contacts, newContact]);
    setExpandedContact(newContact);
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggle}>
      <DialogContent className="whatsapp-dialog">
        <DialogHeader>
          <DialogTitle className="dialog-title">
            Administrar Contactos de WhatsApp
          </DialogTitle>
          <DialogDescription className="dialog-description">
            Añade o elimina contactos para enviar reportes por WhatsApp.
          </DialogDescription>
        </DialogHeader>
        <ModalBody className="modal-body">
          {contacts && contacts.length > 0 ? (
            contacts.map((contact, index) => (
              <div key={index} className="contact-container">
                <div
                  className="contact-row"
                  onClick={() => setExpandedContact(contact === expandedContact ? null : contact)}
                >
                  <div>
                    <strong>{contact.name || "Nuevo Contacto"}</strong>
                    {contact.phoneNumber && (
                      <div className="text-sm text-gray-500">{contact.phoneNumber}</div>
                    )}
                  </div>
                  <Button
                    color="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveContact(index);
                    }}
                    className="delete-button"
                  >
                    <FaTrash />
                  </Button>
                </div>
                
                {expandedContact === contact && (
                  <div className="contact-details">
                    <div className="contact-field">
                      <Label className="contact-label">Nombre</Label>
                      <Input
                        type="text"
                        value={expandedContact.name || ""}
                        onChange={(e) => handleEditContact(index, "name", e.target.value)}
                        placeholder="Nombre del contacto"
                      />
                    </div>
                    
                    <div className="contact-field">
                      <Label className="contact-label">Número de WhatsApp</Label>
                      <Input
                        type="text"
                        value={expandedContact.phoneNumber || ""}
                        onChange={(e) => handleEditContact(index, "phoneNumber", e.target.value)}
                        placeholder="Ej: 5491112345678"
                      />
                    </div>
                    
                    <div>
                      <div className="additional-info-title">Información Adicional</div>
                      
                      {expandedContact.additionalInfo && expandedContact.additionalInfo.map((info, subIndex) => (
                        <div key={subIndex} className="additional-info-row">
                          <Input
                            type="text"
                            value={info.key}
                            onChange={(e) => {
                              const updatedAdditionalInfo = expandedContact.additionalInfo.map(
                                (item, i) => {
                                  if (i === subIndex) {
                                    return {
                                      key: e.target.value,
                                      value: item.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              handleEditContact(index, "additionalInfo", updatedAdditionalInfo);
                            }}
                            placeholder="Nombre del campo"
                          />
                          
                          <Input
                            type="text"
                            value={info.value}
                            onChange={(e) => {
                              const updatedAdditionalInfo = expandedContact.additionalInfo.map(
                                (item, i) => {
                                  if (i === subIndex) {
                                    return {
                                      key: item.key,
                                      value: e.target.value,
                                    };
                                  }
                                  return item;
                                }
                              );
                              handleEditContact(index, "additionalInfo", updatedAdditionalInfo);
                            }}
                            placeholder="Valor del campo"
                          />
                          
                          <Button
                            color="danger"
                            onClick={() => {
                              const updatedAdditionalInfo = expandedContact.additionalInfo.filter(
                                (_, i) => i !== subIndex
                              );
                              handleEditContact(index, "additionalInfo", updatedAdditionalInfo);
                            }}
                            className="delete-button"
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      ))}
                      
                      <Button
                        className="add-info-button"
                        onClick={() => {
                          const updatedAdditionalInfo = [...expandedContact.additionalInfo];
                          updatedAdditionalInfo.push({ key: "", value: "" });
                          handleEditContact(index, "additionalInfo", updatedAdditionalInfo);
                        }}
                      >
                        <FaPlus className="mr-2" /> Agregar Información Adicional
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="no-contacts-message">
              No hay contactos configurados. Agrega uno nuevo.
            </div>
          )}
          
          <Button
            className="add-contact-button"
            onClick={handleAddNewContact}
          >
            <FaUser className="mr-2" /> Agregar Nuevo Contacto
          </Button>
        </ModalBody>
        
        <DialogFooter>
          <Button variant="outline" onClick={toggle} className="cancel">
            Cancelar
          </Button>
          <Button onClick={handleSave} disabled={savingContacts} className="save">
            {savingContacts ? (
              <>
                <RefreshCw className="h-4 w-4 animate-spin mr-2" />
                Guardando...
              </>
            ) : (
              "Guardar Contactos"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FlowWhatsappContactsManager;
