"use client"

import { useState, useCallback, useRef, useEffect } from "react"
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  MiniMap,
  addEdge,
  useNodesState,
  useEdgesState,
  MarkerType,
} from "reactflow"
import "reactflow/dist/style.css"
import { PlusCircle, FileText, Cog, FolderOpen, Search, FileOutput, Loader2 } from "lucide-react"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu"
import SaveFlowButton from "./DataExport/SaveFlowButton"

// Definición de los tipos de nodos personalizados
import DocumentNode from "./nodes/DocumentNode"
import ProcessNode from "./nodes/ProcessNode"
import StorageNode from "./nodes/StorageNode"
import ActionNode from "./nodes/ActionNode"
import OutputNode from "./nodes/OutputNode"

// Registrar los tipos de nodos personalizados
const nodeTypes = {
  documentNode: DocumentNode,
  processNode: ProcessNode,
  storageNode: StorageNode,
  actionNode: ActionNode,
  outputNode: OutputNode,
}

const initialNodes = [
  {
    id: "1",
    type: "documentNode",
    data: { label: "Documentos de Entrada" },
    position: { x: 100, y: 100 },
  },
  {
    id: "2",
    type: "processNode",
    data: { label: "Procesamiento" },
    position: { x: 100, y: 250 },
  },
  {
    id: "3",
    type: "processNode",
    data: { label: "Clasificación" },
    position: { x: 100, y: 400 },
  },
  {
    id: "4",
    type: "storageNode",
    data: { label: "Almacenamiento" },
    position: { x: 300, y: 400 },
  },
  {
    id: "5",
    type: "actionNode",
    data: { label: "Búsqueda" },
    position: { x: 300, y: 250 },
  },
  {
    id: "6",
    type: "outputNode",
    data: { label: "Resultados" },
    position: { x: 300, y: 100 },
  },
]

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
  {
    id: "e5-6",
    source: "5",
    target: "6",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
  {
    id: "e2-5",
    source: "2",
    target: "5",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#ff9800" },
  },
]

const FlowCanvas = ({ isReady = false }) => {
  const reactFlowWrapper = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [visibleNodes, setVisibleNodes] = useState([])
  const [visibleEdges, setVisibleEdges] = useState([])

  // Efecto para mostrar los nodos y conexiones secuencialmente cuando isReady cambia a true
  useEffect(() => {
    if (isReady && nodes.length > 0 && visibleNodes.length === 0) {
      // Mostrar nodos secuencialmente
      const nodeIds = nodes.map((node) => node.id)
      let currentNodeIndex = 0

      const nodeInterval = setInterval(() => {
        if (currentNodeIndex < nodeIds.length) {
          setVisibleNodes((prev) => [...prev, nodeIds[currentNodeIndex]])
          currentNodeIndex++
        } else {
          clearInterval(nodeInterval)

          // Después de mostrar todos los nodos, mostrar las conexiones
          let currentEdgeIndex = 0
          const edgeIds = edges.map((edge) => edge.id)

          const edgeInterval = setInterval(() => {
            if (currentEdgeIndex < edgeIds.length) {
              setVisibleEdges((prev) => [...prev, edgeIds[currentEdgeIndex]])
              currentEdgeIndex++
            } else {
              clearInterval(edgeInterval)
            }
          }, 300)
        }
      }, 400)

      return () => {
        clearInterval(nodeInterval)
      }
    }
  }, [isReady, nodes, edges, visibleNodes.length])

  // Manejar la conexión de nodos
  const onConnect = useCallback(
    (params) => {
      // Crear un ID único para la nueva conexión
      const newEdgeId = `e${params.source}-${params.target}-${Date.now()}`

      // Añadir la nueva conexión con los estilos y marcadores adecuados
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            id: newEdgeId,
            animated: true,
            markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20 },
            style: { stroke: "#ff9800", strokeWidth: 2 },
            type: "smoothstep", // Usar un tipo de conexión más suave
          },
          eds,
        ),
      )

      // Añadir inmediatamente la conexión a las visibles
      setVisibleEdges((prev) => [...prev, newEdgeId])
    },
    [setEdges, setVisibleEdges],
  )

  // Configurar la instancia de ReactFlow
  const onInit = useCallback((instance) => {
    setReactFlowInstance(instance)
  }, [])

  // Manejar el arrastrar y soltar desde el panel de herramientas
  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }, [])

  const onDrop = useCallback(
    (event) => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const type = event.dataTransfer.getData("application/reactflow")

      // Verificar si el tipo es válido
      if (typeof type === "undefined" || !type) {
        return
      }

      // Obtener la posición del cursor en el espacio de ReactFlow
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      })

      // Generar un ID único
      const newId = `${type}-${Date.now()}`

      // Crear un nuevo nodo según el tipo
      const newNode = {
        id: newId,
        type,
        position,
        data: { label: getDefaultLabel(type) },
      }

      // Añadir el nuevo nodo
      setNodes((nds) => nds.concat(newNode))
      // Añadir a visibles inmediatamente
      setVisibleNodes((prev) => [...prev, newId])
    },
    [reactFlowInstance, setNodes],
  )

  // Función para obtener la etiqueta predeterminada según el tipo
  const getDefaultLabel = (type) => {
    switch (type) {
      case "documentNode":
        return "Nuevo Documento"
      case "processNode":
        return "Nuevo Proceso"
      case "storageNode":
        return "Nuevo Almacenamiento"
      case "actionNode":
        return "Nueva Acción"
      case "outputNode":
        return "Nuevo Resultado"
      default:
        return "Nuevo Nodo"
    }
  }

  // Función para iniciar el arrastre desde el panel de herramientas
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType)
    event.dataTransfer.effectAllowed = "move"
  }

  // Función para añadir un nuevo nodo desde el menú
  const addNewNode = (type) => {
    // Generar un ID único
    const newId = `${type}-${Date.now()}`

    // Calcular posición en el centro del área visible
    const centerX = reactFlowInstance.getViewport().x + window.innerWidth / 2 - 75
    const centerY = reactFlowInstance.getViewport().y + window.innerHeight / 2 - 40

    // Crear un nuevo nodo
    const newNode = {
      id: newId,
      type,
      position: { x: centerX, y: centerY },
      data: { label: getDefaultLabel(type) },
    }

    // Añadir el nuevo nodo
    setNodes((nds) => nds.concat(newNode))
    // Añadir a visibles inmediatamente
    setVisibleNodes((prev) => [...prev, newId])
  }

  // Modificar la configuración por defecto de las conexiones
  const defaultEdgeOptions = {
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: "#ff9800",
    },
    style: {
      stroke: "#ff9800",
      strokeWidth: 2,
    },
    type: "smoothstep",
  }

  // Asegurar que las conexiones existentes tengan el estilo correcto
  useEffect(() => {
    if (isReady && edges.length > 0) {
      // Actualizar el estilo de todas las conexiones
      setEdges(
        edges.map((edge) => ({
          ...edge,
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: "#ff9800",
          },
          style: {
            ...edge.style,
            stroke: "#ff9800",
            strokeWidth: 2,
          },
          type: "smoothstep",
        })),
      )
    }
  }, [isReady, setEdges])

  // Modificar los nodos para aplicar visibilidad
  const visibleNodesData = nodes.map((node) => ({
    ...node,
    hidden: !visibleNodes.includes(node.id),
    style: {
      opacity: visibleNodes.includes(node.id) ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    },
  }))

  // Modificar las conexiones para aplicar visibilidad
  const visibleEdgesData = edges.map((edge) => ({
    ...edge,
    hidden: !visibleEdges.includes(edge.id),
    style: {
      ...edge.style,
      opacity: visibleEdges.includes(edge.id) ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    },
  }))

  // Preparar datos del flujo para exportación
  const flowData = {
    nodes: visibleNodesData,
    edges: visibleEdgesData,
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-between p-4 border-b border-gray-100 bg-white shadow-sm">
        <div className="flex items-center space-x-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button
                className="flex items-center px-3 py-1.5 text-sm bg-orange-500/10 hover:bg-orange-500/20 text-orange-600 rounded-md transition-colors"
                disabled={!isReady}
              >
                <PlusCircle className="h-4 w-4 mr-1" />
                Añadir componente
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuItem onClick={() => addNewNode("documentNode")}>
                <FileText className="h-4 w-4 mr-2 text-blue-600" />
                <span>Documento de Entrada</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => addNewNode("processNode")}>
                <Cog className="h-4 w-4 mr-2 text-purple-600" />
                <span>Proceso</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => addNewNode("storageNode")}>
                <FolderOpen className="h-4 w-4 mr-2 text-green-600" />
                <span>Almacenamiento</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => addNewNode("actionNode")}>
                <Search className="h-4 w-4 mr-2 text-amber-600" />
                <span>Acción</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => addNewNode("outputNode")}>
                <FileOutput className="h-4 w-4 mr-2 text-red-600" />
                <span>Resultado</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <div className="text-sm text-gray-500 flex items-center">
            {!isReady ? (
              <div className="flex items-center">
                <Loader2 className="h-3 w-3 mr-2 text-orange-500 animate-spin" />
                <span>Preparando visualización...</span>
              </div>
            ) : (
              "Arrastra para conectar nodos • Doble clic para editar • Click derecho para opciones"
            )}
          </div>
        </div>

        {/* Botón de Guardar Flujo */}
        <SaveFlowButton flowData={flowData} isReady={isReady} />
      </div>

      <div className="flex-1 relative" ref={reactFlowWrapper}>
        {!isReady && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/80 z-10">
            <div className="text-center">
              <Loader2 className="h-10 w-10 text-orange-500 animate-spin mx-auto mb-4" />
              <p className="text-gray-600">Generando visualización de flujo...</p>
            </div>
          </div>
        )}

        <ReactFlowProvider>
          <ReactFlow
            nodes={visibleNodesData}
            edges={visibleEdgesData}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            fitView
            attributionPosition="bottom-right"
            deleteKeyCode="Delete"
            minZoom={0.2}
            maxZoom={4}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionLineStyle={{ stroke: "#ff9800", strokeWidth: 2 }}
            connectionLineType="smoothstep"
          >
            <Controls />
            <MiniMap
              nodeStrokeColor={(n) => {
                if (n.type === "documentNode") return "#3b82f6"
                if (n.type === "processNode") return "#8b5cf6"
                if (n.type === "storageNode") return "#10b981"
                if (n.type === "actionNode") return "#f59e0b"
                if (n.type === "outputNode") return "#ef4444"
                return "#6b7280"
              }}
              nodeColor={(n) => {
                if (n.type === "documentNode") return "#dbeafe"
                if (n.type === "processNode") return "#ede9fe"
                if (n.type === "storageNode") return "#d1fae5"
                if (n.type === "actionNode") return "#fef3c7"
                if (n.type === "outputNode") return "#fee2e2"
                return "#f3f4f6"
              }}
            />
            <Background color="#f97316" gap={16} size={1} />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </div>
  )
}

export default FlowCanvas

