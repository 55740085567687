import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Collapse, Spinner } from "reactstrap";
import {
  getAssisstantConversationsHistory,
  getAssistantConversationById,
} from "../ApiCalls/mantisBusiness";
import { brand } from "../config";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function ChatHistory(props) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [loadingConversation, setLoadingConversation] = useState(false);

  useEffect(() => {
      getData();
  }, [props.userId, props.token]);

  useEffect(() => {
    if (selectedConversation) {
      if (!Object.keys(selectedConversation).includes("responses") > 0) {
        getConversationMessages();
      }
    }
  }, [selectedConversation]);

  const getData = async () => {
    getConversations();
  };

  const getConversationMessages = async () => {
    try {
      setLoadingConversation(true);
      const res = await getAssistantConversationById(
        selectedConversation._id,
        props.mantisBusiness._id,
        props.user._id,
        props.token
      );
      if (res.success) {
        setSelectedConversation(res.conversation);
        let conversationsCopy = [...conversations];
        let index = conversationsCopy.findIndex(
          (conversation) => conversation._id === res.conversation._id
        );
        conversationsCopy[index] = res.conversation;
        setConversations(conversationsCopy);
      } else {
        console.error("Error al obtener las conversaciones");
      }
      setLoadingConversation(false);
    } catch (e) {
      setLoadingConversation(false);
      console.error("Error al obtener las conversaciones", e);
    }
  };

  const getConversations = async () => {
    try {
      setLoading(true);
      const res = await getAssisstantConversationsHistory(
        props.user._id,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        setConversations(res.conversations);
      } else {
        console.error("Error al obtener las conversaciones");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("Error al obtener las conversaciones", e);
    }
  };

  return (
    <div className="conversation-history">
      <h4 className="assistant-sidebar-title">
        Hola {props.user?.email?.split("@")[0]}, Soy{" "}
        {brand === "MANTIS" ? "Mantis" : "Sandy"}!
      </h4>
      <h4 className="assistant-sidebar-title">Historial de Conversaciones</h4>

      <div className="assistant-sidebar-functionalities-container">
        {loading ? (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <>
            {conversations.map((conversation, index) => {
              return (
                <div key={index} className="conversation-container">
                  <div
                    className="conversation-date"
                    onClick={() => {
                      if (selectedConversation?._id !== conversation._id) {
                        setSelectedConversation(conversation);
                      } else {
                        setSelectedConversation(null);
                      }
                    }}
                  >
                    <h5 className="assistant-sidebar-functionality-title">
                      {new Date(conversation.dateCreated).toLocaleDateString(
                        "es"
                      )}
                    </h5>
                    {selectedConversation?._id === conversation._id ? (
                      <i className="fas fa-chevron-up"></i>
                    ) : (
                      <i className="fas fa-chevron-down"></i>
                    )}
                  </div>
                  <Collapse
                    isOpen={selectedConversation?._id === conversation._id}
                  >
                    <div className="conversation-messages">
                      {loadingConversation ? (
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        <>
                          {selectedConversation?.responses?.map(
                            (response, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    response.sender === "User"
                                      ? "user-message-wrapper"
                                      : "mantis-message-wrapper"
                                  }
                                >
                                  <div
                                    className={
                                      response.sender === "User"
                                        ? "assistant-user-message"
                                        : "assistant-mantis-message"
                                    }
                                  >
                                    {response.message}
                                  </div>
                                  <div
                                    className={
                                      response.sender === "User"
                                        ? "assistant-user-message-timestamp"
                                        : "assistant-mantis-message-timestamp"
                                    }
                                  >
                                    {new Date(
                                      response.timestamp
                                    ).toLocaleTimeString("es", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default connect(select)(ChatHistory);
