import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import "../../styles/businessBackofficeStyles.scss";
import "../../styles/Integrations.css";
import bambooLogo from "../../../Assets/img/icons/b2b/integrationLogos/bambooHRLogo.png";
import gmailLogo from "../../../Assets/img/icons/b2b/integrationLogos/gmailLogo.png";
import outlookLogo from "../../../Assets/img/icons/b2b/integrationLogos/outlookLogo.png";
import {
  deleteGmailConnection,
  getGmailConnections,
} from "../../../ApiCalls/mantisBusiness";
import { backUrl } from "../../../utils/backURL";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessIntegrations(props) {
  const [loading, setLoading] = useState(false);
  const [gmailConnections, setGmailConnections] = useState([]);
  const [deletingGmailConnection, setDeletingGmailConnection] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const response = await getGmailConnections(
      props.token,
      props.mantisBusiness._id
    );
    console.log(response);
    if (response.success) {
      setGmailConnections(response.connections);
    }
    setLoading(false);
  };

  const handleDeleteGmailConnection = async (connectionId) => {
    setDeletingGmailConnection(true);
    const res = await deleteGmailConnection(
      props.token,
      connectionId,
      props.mantisBusiness._id
    );
    if (res.success) {
      getData();
    } else {
      alert("Error al eliminar la conexión");
    }
    setDeletingGmailConnection(false);
  };

  return (
    <div>
      <Row>
        <Col xs={11} className="mx-auto">
          <Row>
            <Col xs={6}>
              <h1 className="business-viewpanel-title">Fuentes de datos</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={11} className="mx-auto">
          <h2 className="business-viewpanel-subtitle">
            Conecta tus fuentes de información
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={11} className="mx-auto">
          {/* <Col xs={2}>
          <div className="business-integrations-integration-container">
            <img
              src={bambooLogo}
              alt="bambooHR"
              className="business-integrations-integration-logo"
            />
            <h3 className="business-integrations-integration-title">
              Bamboo HR
            </h3>
          </div>
        </Col> */}
          <Col xs={2}>
            <div
              className="business-integrations-integration-container"
              onClick={() => {
                window.open(
                  `${backUrl}/googleOAuth/auth?mantisBusinessId=${props.mantisBusiness._id}`
                );
              }}
            >
              <img
                src={gmailLogo}
                alt="Gmail"
                className="business-integrations-integration-logo"
              />
              <h3 className="business-integrations-integration-title">Gmail</h3>
            </div>
          </Col>
          {/* <Col xs={2}>
          <div className="business-integrations-integration-container">
            <img
              src={outlookLogo}
              alt="Outlook"
              className="business-integrations-integration-logo"
            />
            <h3 className="business-integrations-integration-title">Outlook</h3>
          </div>
        </Col> */}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={11} className="mx-auto">
          {loading ? (
            <div className="spinner-container">
              <Spinner className="main-spinner" />
            </div>
          ) : (
            <Col xs={12}>
              <h3 className="integration-section-title">Cuentas conectadas</h3>
              {gmailConnections.length > 0 ? (
                <Table className="integration-table" responsive>
                  <thead>
                    <tr className="integration-table-header">
                      <th></th>
                      <th>Nombre</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {gmailConnections.map((connection) => {
                      let expiryDate = new Date(connection.expiryDate);
                      expiryDate.setDate(expiryDate.getDate() + 7);
                      let isExpired = new Date() >= expiryDate;
                      return (
                        <tr key={connection._id} className="integration-table-row">
                          <td>
                            <img
                              src={gmailLogo}
                              alt="Gmail"
                              className="integration-logo-small"
                            />
                          </td>
                          <td>{connection.email}</td>
                          <td>
                            <span 
                              className={`connection-status ${isExpired ? 'expired' : 'active'}`}
                              onClick={() => {
                                if (isExpired) {
                                  window.open(
                                    `https://services.mantisapp.co/api/googleOAuth/auth?mantisBusinessId=${props.mantisBusiness._id}`
                                  );
                                }
                              }}
                            >
                              {isExpired ? "Expirado" : "Activo"}
                            </span>
                          </td>
                          <td>
                            <button 
                              className="delete-connection-button"
                              onClick={() => handleDeleteGmailConnection(connection._id)}
                              disabled={deletingGmailConnection}
                            >
                              {deletingGmailConnection ? (
                                <div className="button-spinner-container">
                                  <Spinner size="sm" className="button-spinner" />
                                </div>
                              ) : (
                                <i className="fas fa-trash-alt"></i>
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no-connections-message">
                  <p>No hay cuentas conectadas</p>
                </div>
              )}
            </Col>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(BusinessIntegrations);
