import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import { Row, Col, Spinner, Form, FormGroup, Label, Input } from "reactstrap";
import "../../styles/Documents.css";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function BusinessDashboard(props) {
  //const { idDashboard } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urls = props.mantisBusiness.dashboardUrls || [];  

  const searchParams = new URLSearchParams(location.search);
  const idDashboard = searchParams.get('idDashboard') || '1';

  const [loadingLookerDashboard, setLoadingLookerDashboard] = useState(true);
  const isDemoAccount = props.mantisBusiness._id === "664527fdef749d6dbbabeecc";
  const [demoDashboardTab, setDemoDashboardTab] = useState("CREDIT");
  const [demoUrl, setDemoUrl] = useState(
    "https://lookerstudio.google.com/u/0/embed/reporting/af45f32c-9901-40e1-9977-249d6c570ce6/page/wqy5D?s=jwqop9QiRqQ"
  );
  const [selectedDashboard, setSelectedDashboard] = useState(
    urls.length > 0 ? urls[idDashboard ? parseInt(idDashboard) - 1 : 0] : null
  );

  useEffect(() => {
    if (urls.length > 0) {
      const index = parseInt(idDashboard) - 1;
      if (index >= 0 && index < urls.length) {
        setSelectedDashboard(urls[index]);
      } else {
        navigate("/business/backoffice/dashboard/1", { replace: true });
      }
    }
  }, [idDashboard, urls, navigate]);

  //Scroll to top
  window.onload = function () {
    setTimeout(func1, 2000);
  };

  useEffect(() => {
    if (demoDashboardTab === "CREDIT") {
      setDemoUrl(
        "https://lookerstudio.google.com/u/0/embed/reporting/af45f32c-9901-40e1-9977-249d6c570ce6/page/wqy5D?s=jwqop9QiRqQ"
      );
    } else if (demoDashboardTab === "DEBT") {
      setDemoUrl(
        "https://lookerstudio.google.com/embed/reporting/498452f9-1b41-4771-9a49-635197ea9851/page/tHo3D"
      );
    } else if (demoDashboardTab === "SALES") {
      setDemoUrl(
        "https://lookerstudio.google.com/embed/reporting/01e4ec5c-db06-4b48-8fc3-1a36d85998c0/page/oFE4D"
      );
    }
  }, [demoDashboardTab]);

  function func1() {
    document.getElementById("looker_div").style.display = "visible";
  }

  const handleDashboardChange = (index) => {
    setSelectedDashboard(urls[index]);
    navigate(`/business/backoffice/dashboard?idDashboard=${index + 1}`, { replace: true });
  };

  return (
    <div>
      <Row style={{ marginTop: "50px" }}>
        <Col xs={11} style={{ margin: "auto" }}>
          <Row>
            <Col xs={4}>
              <Form>
                <FormGroup>
                  <Label
                    for="dashboard"
                    className="business-extraction-filter-label"
                  >
                    Selecciona el dashboard
                  </Label>
                  <Input
                    type="select"
                    name="dashboard"
                    id="dashboard"
                    className="dashboard-select-input"
                    onChange={(e) => handleDashboardChange(parseInt(e.target.value))}
                    value={selectedDashboard ? urls.indexOf(selectedDashboard) : 0}
                  >
                    {urls.map((url, index) => (
                      <option
                        key={index}
                        value={index}
                        className="dashboard-select-option"
                      >
                        {url.title}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row
        style={{
          marginTop: "50px",
        }}
      >
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          <Row>
            <Col xs={6}>
              <h1 className="business-viewpanel-title">
                {selectedDashboard.title || "Dashboard"}
              </h1>
            </Col>
          </Row>
        </Col>
      </Row> */}
      {isDemoAccount && (
        <>
          <Row>
            <Col xs={11} style={{ margin: "auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                  flexDirection: "row",
                }}
              >
                <div
                  style={
                    demoDashboardTab === "CREDIT"
                      ? {
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f5f5f5",
                        }
                      : {
                          padding: "10px",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => setDemoDashboardTab("CREDIT")}
                >
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                    }}
                  >
                    Ahorro y Crédito
                  </p>
                </div>
                <div
                  style={
                    demoDashboardTab === "DEBT"
                      ? {
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f5f5f5",
                        }
                      : {
                          padding: "10px",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => setDemoDashboardTab("DEBT")}
                >
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                    }}
                  >
                    Deuda Proveedores
                  </p>
                </div>
                <div
                  style={
                    demoDashboardTab === "SALES"
                      ? {
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f5f5f5",
                        }
                      : {
                          padding: "10px",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => setDemoDashboardTab("SALES")}
                >
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                    }}
                  >
                    Ventas Anuales
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={11} style={{ margin: "auto" }}>
              {loadingLookerDashboard && <Spinner color="white" />}
              <div
                className="business-dashboard-looker-container"
                id="looker_div"
                style={{
                  display: "hidden",
                }}
              >
                {
                  <iframe
                    onLoad={() => {
                      setLoadingLookerDashboard(false);
                    }}
                    title="Dashboard"
                    width="100%"
                    height="1000"
                    src={demoUrl}
                    frameborder="0"
                    //style="border:0"
                    allowFullscreen
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                  ></iframe>
                }
              </div>
            </Col>
          </Row>
        </>
      )}
      {selectedDashboard && !isDemoAccount && (
        <Row>
          <Col xs={11} style={{ margin: "auto" }}>
            {loadingLookerDashboard && urls.length > 0 && (
              <Spinner color="white" />
            )}
            <div
              className="business-dashboard-looker-container"
              id="looker_div"
              style={{
                display: "hidden",
              }}
            >
              {
                <iframe
                  onLoad={() => {
                    setLoadingLookerDashboard(false);
                  }}
                  title="Dashboard"
                  width="100%"
                  height="1000"
                  src={selectedDashboard.url}
                  frameborder="0"
                  //style="border:0"
                  allowfullscreen
                  sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                ></iframe>
              }
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default connect(select)(BusinessDashboard);
