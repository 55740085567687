"use client"

import { useState } from "react"
import { Button } from "../../../components/ui/button"
import { Columns as ColumnsIcon, CheckSquare, Square, Eye, EyeOff } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu"
import { Checkbox } from "../../../components/ui/checkbox"
import { Label } from "../../../components/ui/label"

export default function Columns({ 
  allColumns = [], 
  visibleColumns = [], 
  onColumnToggle,
  columnLabels = {}
}) {
  const [open, setOpen] = useState(false)

  const handleColumnToggle = (column) => {
    onColumnToggle(column)
  }

  const isColumnVisible = (column) => {
    return visibleColumns.includes(column)
  }

  // Obtener el nombre formateado de la columna
  const getColumnLabel = (column) => {
    return columnLabels[column] || column
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          size="sm" 
          className="flex items-center gap-1 bg-white dark:bg-slate-800 border-slate-300 dark:border-slate-600 hover:bg-slate-100 dark:hover:bg-slate-700 shadow-sm rounded-md"
        >
          <ColumnsIcon className="h-4 w-4 text-slate-700 dark:text-slate-300" />
          <span className="hidden sm:inline text-slate-700 dark:text-slate-300">Columnas</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 shadow-md rounded-lg">
        <DropdownMenuLabel className="text-slate-800 dark:text-slate-200">Columnas visibles</DropdownMenuLabel>
        <DropdownMenuSeparator className="bg-slate-200 dark:bg-slate-700" />
        <div className="max-h-[300px] overflow-y-auto p-1">
          {allColumns.map((column) => (
            <div key={column} className="flex items-center space-x-2 p-2 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-md">
              <Checkbox
                id={`column-${column}`}
                checked={isColumnVisible(column)}
                onCheckedChange={() => handleColumnToggle(column)}
                className="data-[state=checked]:bg-orange-500 data-[state=checked]:border-orange-500 border-slate-400 dark:border-slate-500"
              />
              <Label
                htmlFor={`column-${column}`}
                className="flex-1 cursor-pointer text-sm text-slate-700 dark:text-slate-300"
              >
                {getColumnLabel(column)}
              </Label>
            </div>
          ))}
        </div>
        {allColumns.length > 5 && (
          <>
          
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
