import Cookies from "js-cookie";
import { logout } from "../ApiCalls/authentication";
import { setInStorage } from "./storage";

export async function fetchWrapper(url, options = {}, timeout = 60000) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });

    if (response.status === 401) {
      handleSessionExpiration();
      return;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error en fetchWrapper:", error);
    throw error;
  } finally {
    clearTimeout(timeoutId);
  }
}

function handleSessionExpiration() {
  localStorage.setItem("sessionExpired", true);
  window.location.href = '/'; 
  logout(); 
  Cookies.remove("csrfToken");
  setInStorage("mantis_app", null);
}