const ChatSkeleton = ({ accentColor = "blue" }) => {
  const getAccentColorClasses = () => {
    switch (accentColor) {
      case "orange":
        return {
          bg: "bg-orange-50",
          dot: "bg-orange-300",
          line: "bg-orange-200",
        }
      default:
        return {
          bg: "bg-gray-50",
          dot: "bg-gray-300",
          line: "bg-gray-200",
        }
    }
  }

  const colorClasses = getAccentColorClasses()

  return (
    <div className="flex justify-start">
      <div className={`max-w-[80%] p-3 rounded-lg ${colorClasses.bg} text-gray-800 shadow-sm`}>
        <div className="flex items-center space-x-2">
          <div className={`w-2 h-2 ${colorClasses.dot} rounded-full animate-pulse`}></div>
          <div
            className={`w-2 h-2 ${colorClasses.dot} rounded-full animate-pulse`}
            style={{ animationDelay: "0.2s" }}
          ></div>
          <div
            className={`w-2 h-2 ${colorClasses.dot} rounded-full animate-pulse`}
            style={{ animationDelay: "0.4s" }}
          ></div>
        </div>
        <div className="mt-2 space-y-2">
          <div className={`h-4 ${colorClasses.line} rounded w-3/4 animate-pulse`}></div>
          <div className={`h-4 ${colorClasses.line} rounded w-1/2 animate-pulse`}></div>
          <div className={`h-4 ${colorClasses.line} rounded w-5/6 animate-pulse`}></div>
        </div>
      </div>
    </div>
  )
}

export default ChatSkeleton

