import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  UncontrolledTooltip,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {ChevronLeft, Search, ChevronDown, ChevronUp, X} from "lucide-react";
import PhoneInput from "react-phone-input-2";
import pdfIcon from "../../../Assets/img/icons/pdf.png";
import documentIcon from "../../../Assets/img/icons/document.png";
import excelIcon from "../../../Assets/img/icons/excel.png";
import imageIcon from "../../../Assets/img/icons/image.png";
import defaultProfilePicture from "../../../Assets/img/icons/b2b/defaultUser.png";
import EmojiPicker from "emoji-picker-react";

import mantisLogo from "../../../Assets/img/mantisBlackLogo.png";
import {
  createBusinessCompressedFolder,
  createBusinessDocument,
  createBusinessFolder,
  createEmployee,
  createEmployeeDocument,
  createEmployeeFolder,
  deleteDocument,
  deleteFolder,
  getActiveEmployees,
  getBusinessFolderById,
  getBusinessFolders,
  getDocumentFormats,
  getDocumentSignedUrl,
  getEmployeeFolderById,
  getEmployeeFolders,
  getEmployees,
  getFormatFieldValues,
  updateDocument,
  updateFolder,
  searchInArchive,
} from "../../../ApiCalls/mantisBusiness";
import { shortenText } from "../../../utils/formatText";
import FilesDragAndDrop from "../../../Extras/DragAndDrop/FilesDragAndDrop";
import ArchiveContextMenu from "../../../Extras/ContextMenus/ArchiveContextMenu";
import { fileUploadLimit } from "../../../config";
import { checkMantisBusinessUserPermission } from "../../../utils/businessUtils";
import SelectFolderModal from "../../Extras/SelectFolderModal"; // Import the new component
import "../../styles/Archivo.css";
import { 
  Folder, 
  FolderOpen, 
  FileText, 
  Image, 
  FileSpreadsheet, 
  File,
  Star,
  Briefcase,
  Building,
  Users,
  FileCheck,
  Clock,
  LightbulbIcon
} from "lucide-react";
import DocumentMasterModal from "../../Extras/DocumentMasterModal";
import { fileToBase64 } from "../../../utils/base64";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessArchive(props) {
  const resultsPerPage = 30;
  const searchResultsPerType = 5;
  const maxFilesToUpload = fileUploadLimit;
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [creatingNewFolder, setCreatingNewFolder] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [newFolderState, setNewFolderState] = useState({
    name: "",
    icon: "",
    logoUrl: "",
  });
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [newDocumentState, setNewDocumentState] = useState({
    name: "",
  });
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showingEmojiPicker, setShowingEmojiPicker] = useState(true);
  const [page, setPage] = useState(1);

  //Right click menu
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [elementRightClicked, setElementRightClicked] = useState(null);
  const [elementRightClickedType, setElementRightClickedType] = useState("");

  //Alerts & Errors
  const [errorMessage, setErrorMessage] = useState("");

  //CRUD
  const [showEditElementModal, setShowEditElementModal] = useState(false);
  const [showDeleteElementModal, setShowDeleteElementModal] = useState(false);
  const [editingElement, setEditingElement] = useState(false);
  const [deletingElement, setDeletingElement] = useState(false);

  //Document formats
  const [documentFormats, setDocumentFormats] = useState([]);
  const [loadingDocumentFormats, setLoadingDocumentFormats] = useState(true);
  const [showAssignFormatModal, setShowAssignFormatModal] = useState(false);
  const [selectedDocumentFormat, setSelectedDocumentFormat] = useState(null);
  const [fetchingFormatValues, setFetchingFormatValues] = useState(false);
  const [formatValues, setFormatValues] = useState([]);
  const [suggestedFilename, setSuggestedFilename] = useState("");
  const [useSuggestedFilename, setUseSuggestedFilename] = useState(true);
  const [suggestedFileLocation, setSuggestedFileLocation] = useState([]);
  const [useSuggestedFileLocation, setUseSuggestedFileLocation] =
    useState(true);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState("");

  //Search
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState({
    folders: [],
    documentsByName: [],
    documentsByIndexation: [],
  });
  const [searching, setSearching] = useState(false);
  const [visibleResults, setVisibleResults] = useState({
    folders: searchResultsPerType,
    documentsByName: searchResultsPerType,
    documentsByIndexation: searchResultsPerType,
  });

  //Document selection
  const [selectingDocuments, setSelectingDocuments] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [showSelectFolderModal, setShowSelectFolderModal] = useState(false);
  const [targetFolder, setTargetFolder] = useState(null);

  //Document master modal
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDocumentMasterModal, setShowDocumentMasterModal] = useState(false);

  useEffect(() => {
    if (filesToUpload.length === 1) {
      if (filesToUpload[0].type.includes("pdf")) {
        setPdfPreviewUrl(URL.createObjectURL(filesToUpload[0]) + "#view=FitH");
      }
    }
  }, [filesToUpload]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleClick = () => setClicked(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      let selectedFolderCopy = { ...selectedFolder };
      selectedFolderCopy.documents = [];
      setSelectedFolder(selectedFolderCopy);
      getFolderFromId(selectedFolder._id);
    }
  }, [page]);

  useEffect(() => {
    if (!showEditElementModal) {
      setElementRightClicked(null);
      setElementRightClickedType("");
      setEditingElement(false);
    }
  }, [showEditElementModal]);

  useEffect(() => {
    if (!showDeleteElementModal) {
      setElementRightClicked(null);
      setElementRightClickedType("");
      setDeletingElement(false);
    }
  }, [showDeleteElementModal]);

  async function getData() {
    await fetchFolders();
    fetchDocumentFormats();
    setLoading(false);
  }

  const search = async () => {
    try {
      if (searchTerm.length === 0) {
        setSearchResults({
          folders: [],
          documentsByName: [],
          documentsByIndexation: [],
        });
        return;
      }
      setSearching(true);
      const res = await searchInArchive(
        props.token,
        props.mantisBusiness._id,
        searchTerm
      );
      if (res.success) {
        setSearchResults(res.results);
      }
      setSearching(false);
    } catch (e) {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      setSearchResults({
        folders: [],
        documentsByName: [],
        documentsByIndexation: [],
      });
    } else {
      const delayDebounceFn = setTimeout(() => {
        search();
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  const closeSearchResults = () => {
    setSearchTerm("");
    setSearchResults({
      folders: [],
      documentsByName: [],
      documentsByIndexation: [],
    });
    setVisibleResults({
      folders: searchResultsPerType,
      documentsByName: searchResultsPerType,
      documentsByIndexation: searchResultsPerType,
    });
  };

  const closeUpdateElementModal = () => {
    setShowEditElementModal(false);
  };

  const closeDeleteElementModal = () => {
    setShowDeleteElementModal(false);
  };

  const closeAssignFormatModal = () => {
    setShowAssignFormatModal(false);
    if (!fetchingFormatValues && !uploadingDocuments) {
      setFormatValues([]);
      setSelectedDocumentFormat(null);
      setFilesToUpload([]);
      setSuggestedFilename("");
      setUseSuggestedFilename(true);
      setSuggestedFileLocation([]);
      setUseSuggestedFileLocation(false);
      setFetchingFormatValues(false);
    }
  };

  const handleNameChange = () => {
    setShowEditElementModal(true);
  };

  const handleDeleteElement = () => {
    setShowDeleteElementModal(true);
  };

  const handleSelectElement = () => {
    setSelectingDocuments(true);
    setSelectedDocuments([elementRightClicked._id]);
  };

  const handleCancelSelection = () => {
    setSelectingDocuments(false);
    setSelectedDocuments([]);
  };

  const handleEmojiClick = (emojiObject) => {
    setNewFolderState({
      ...newFolderState,
      icon: emojiObject.emoji,
    });
  };

  const getFolderFromId = async (folderId) => {
    setLoading(true);
    const res = await getBusinessFolderById(
      folderId,
      props.token,
      props.mantisBusiness._id,
      page
    );
    if (res.success) {
      setSelectedFolder(res.folder);
    }
    setLoading(false);
  };

  const uploadBusinessFile = async (
    base64,
    name,
    type,
    date,
    size,
    documentFormatId,
    formatValues,
    suggestedFileLocation
  ) => {
    const res = await createBusinessDocument(
      selectedFolder ? selectedFolder._id : "",
      base64,
      name,
      type,
      date,
      size,
      props.mantisBusiness._id,
      props.userId,
      props.token,
      documentFormatId,
      formatValues,
      suggestedFileLocation
    );
    return res;
  };

  const uploadBusinessCompressedFolder = async (base64, name, date) => {
    const res = await createBusinessCompressedFolder(
      selectedFolder ? selectedFolder._id : "",
      base64,
      name,
      props.mantisBusiness._id,
      props.userId,
      selectedFolder ? selectedFolder.level + 1 : 0,
      props.token,
      date
    );
    return res;
  };

  const onUpload = (files) => {
    if (files.length > maxFilesToUpload) {
      alert(`Solo puedes subir hasta ${maxFilesToUpload} archivos`);
      return;
    }
    //convert files to array
    files = Array.from(files);
    setFilesToUpload(files);
  };

  const handleUploadFiles = async () => {
    setUploadingDocuments(true);
    let files = {};
    let uploadedFilesTemp = [];
    let renavigateFolderId = "";
    for (let i = 0; i < filesToUpload.length; i++) {
      const file = filesToUpload[i];
      let filename = file.name;
      if (i === 0 && useSuggestedFilename && suggestedFilename !== "") {
        filename =
          suggestedFilename.replace(/\./g, "") + "." + file.type.split("/")[1];
      }
      let result = await fileToBase64(file);

      if (file.type.includes("zip")) {
        const uploadRes = await uploadBusinessCompressedFolder(
          result.split(",")[1],
          file.name,
          new Date(file.lastModified)
        );
        uploadedFilesTemp.push(uploadRes.folder);
        setUploadedFiles(uploadedFilesTemp);
        if (selectedFolder) {
          let selectedFolderCopy = { ...selectedFolder };
          selectedFolderCopy.subFolders.push(uploadRes.folder);
          setSelectedFolder(selectedFolderCopy);
        }
      } else {
        const uploadRes = await uploadBusinessFile(
          result.split(",")[1],
          filename,
          file.type,
          new Date(file.lastModified),
          file.size,
          selectedDocumentFormat ? selectedDocumentFormat : null,
          formatValues,
          useSuggestedFileLocation ? suggestedFileLocation : []
        );
        uploadedFilesTemp.push(uploadRes.document);
        setUploadedFiles(uploadedFilesTemp);
        if (selectedFolder && !useSuggestedFileLocation) {
          let selectedFolderCopy = { ...selectedFolder };
          selectedFolderCopy.documents.push(uploadRes.document);
          setSelectedFolder(selectedFolderCopy);
        }
        if (uploadRes.folderId) {
          renavigateFolderId = uploadRes.folderId;
        }
      }
    }

    setUploadingDocuments(false);
    closeUploadFileModal();
    closeAssignFormatModal();
    if (renavigateFolderId !== "") {
      getFolderFromId(renavigateFolderId);
    }
  };

  async function createNewFolder(event) {
    event.preventDefault();
    if (creatingNewFolder) {
      return;
    }
    setCreatingNewFolder(true);
    let parentFolderId = "";
    let folderData = {};
    if (
      !newFolderState.name ||
      newFolderState.name === "" ||
      newFolderState.icon === "" ||
      !newFolderState.icon
    ) {
      alert("Por favor llena todos los campos");
      setCreatingNewFolder(false);
      return;
    }

    if (selectedFolder) {
      parentFolderId = selectedFolder._id;
      folderData = {
        name: newFolderState.name,
        icon: newFolderState.icon,
        level: selectedFolder.level + 1,
      };
    } else {
      parentFolderId = "";
      folderData = {
        name: newFolderState.name,
        icon: newFolderState.icon,
        level: 0,
      };
    }
    const res = await createBusinessFolder(
      props.token,
      parentFolderId,
      folderData,
      props.mantisBusiness._id,
      props.userId
    );
    if (res.success) {
      if (!selectedFolder) {
        let foldersCopy = [...folders];
        foldersCopy.push(res.folder);
        setFolders(foldersCopy);
      } else {
        let selectedFolderCopy = { ...selectedFolder };
        selectedFolderCopy.subFolders.push(res.folder);
        setSelectedFolder(selectedFolderCopy);
      }
      closeCreateFolderModal();
    }
  }

  async function editFolder(event) {
    event.preventDefault();
    if (editingElement) {
      return;
    }
    setEditingElement(true);
    try {
      let folderData = {};
      if (
        !elementRightClicked.name ||
        elementRightClicked.name === "" ||
        elementRightClicked.icon === "" ||
        !elementRightClicked.icon
      ) {
        alert("Por favor llena todos los campos");
        setEditingElement(false);
        return;
      }

      folderData = {
        name: elementRightClicked.name,
        icon: elementRightClicked.icon,
        _id: elementRightClicked._id,
      };

      const res = await updateFolder(
        folderData._id,
        folderData,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        if (!selectedFolder) {
          let foldersCopy = [...folders];
          const index = folders.findIndex(
            (folder) => folder._id === res.folder._id
          );
          foldersCopy[index] = res.folder;
          setFolders(foldersCopy);
        } else {
          let selectedFolderCopy = { ...selectedFolder };
          const index = selectedFolder.subFolders.findIndex(
            (folder) => folder._id === res.folder._id
          );
          selectedFolderCopy.subFolders[index] = res.folder;
          setSelectedFolder(selectedFolderCopy);
        }
      } else {
        alert("Error al editar la carpeta");
      }
      closeUpdateElementModal();
    } catch (e) {
      alert("Error al editar la carpeta");
      setEditingElement(false);
    }
  }

  async function editDocument(event) {
    event.preventDefault();
    if (editingElement) {
      return;
    }
    setEditingElement(true);
    try {
      let documentData = {};
      if (!elementRightClicked.name || elementRightClicked.name === "") {
        alert("Por favor llena todos los campos");
        setEditingElement(false);
        return;
      }

      documentData = {
        name: elementRightClicked.name,
        _id: elementRightClicked._id,
      };
      const res = await updateDocument(
        documentData._id,
        documentData,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        let documentsCopy = [...selectedFolder.documents];
        const index = selectedFolder.documents.findIndex(
          (document) => document._id === res.document._id
        );
        documentsCopy[index] = res.document;
        let selectedFolderCopy = { ...selectedFolder };
        selectedFolderCopy.documents = documentsCopy;
        setSelectedFolder(selectedFolderCopy);
      } else {
        alert("Error al editar el documento");
      }
      closeUpdateElementModal();
    } catch (e) {
      alert("Error al editar el documento");
      setEditingElement(false);
    }
  }

  async function handleDeleteFolder(event) {
    event.preventDefault();
    if (deletingElement) {
      return;
    }
    setDeletingElement(true);
    try {
      const res = await deleteFolder(
        elementRightClicked._id,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        if (!selectedFolder) {
          let foldersCopy = [...folders];
          const index = folders.findIndex(
            (folder) => folder._id === elementRightClicked._id
          );
          foldersCopy.splice(index, 1);
          setFolders(foldersCopy);
        } else {
          let selectedFolderCopy = { ...selectedFolder };
          const index = selectedFolder.subFolders.findIndex(
            (folder) => folder._id === elementRightClicked._id
          );
          selectedFolderCopy.subFolders.splice(index, 1);
          setSelectedFolder(selectedFolderCopy);
        }
      } else {
        alert("Error al eliminar la carpeta");
      }
      closeDeleteElementModal();
    } catch (e) {
      alert("Error al eliminar la carpeta");
      setDeletingElement(false);
    }
  }

  async function handleDeleteDocument(event) {
    event.preventDefault();
    if (deletingElement) {
      return;
    }
    setDeletingElement(true);
    try {
      const res = await deleteDocument(
        elementRightClicked._id,
        props.mantisBusiness._id,
        props.token
      );
      if (res.success) {
        let documentsCopy = [...selectedFolder.documents];
        const index = selectedFolder.documents.findIndex(
          (document) => document._id === elementRightClicked._id
        );
        documentsCopy.splice(index, 1);
        let selectedFolderCopy = { ...selectedFolder };
        selectedFolderCopy.documents = documentsCopy;
        setSelectedFolder(selectedFolderCopy);
      } else {
        alert("Error al eliminar el documento");
      }
      closeDeleteElementModal();
    } catch (e) {
      alert("Error al eliminar el documento");
      setDeletingElement(false);
    }
  }

  async function closeUploadFileModal() {
    if (!uploadingDocuments) {
      setShowUploadFileModal(false);
      setNewDocumentState({
        name: "",
      });
      setFilesToUpload([]);
      setUploadedFiles([]);
    }
  }

  function closeCreateFolderModal() {
    if (!creatingNewFolder) {
      setCreatingNewFolder(false);
      setShowCreateFolderModal(false);
      setNewFolderState({
        name: "",
        icon: "",
        logoUrl: "",
      });
    }
  }

  async function fetchFolders() {
    const res = await getBusinessFolders(props.mantisBusiness._id, props.token);
    if (res.success) {
      setFolders(res.folders);
    }
  }

  async function fetchDocumentFormats() {
    const res = await getDocumentFormats(props.token, props.mantisBusiness._id);
    if (res.success) {
      setDocumentFormats(res.formats);
    }
    setLoadingDocumentFormats(false);
  }

  const handleGetFormatValues = async () => {
    if (fetchingFormatValues) {
      return;
    }
    setFetchingFormatValues(true);
    const fileBase64 = await fileToBase64(filesToUpload[0]);
    let valuesRes = await getFormatFieldValues(
      props.token,
      props.mantisBusiness._id,
      selectedDocumentFormat,
      fileBase64.split(",")[1],
      filesToUpload[0].name,
      filesToUpload[0].size,
      filesToUpload[0].type
    );
    if (valuesRes.success) {
      setFormatValues(valuesRes.values.values);
      setSuggestedFilename(valuesRes.values.suggestedFilename);
      if (valuesRes.values.suggestedFileLocation) {
        setSuggestedFileLocation(
          valuesRes.values.suggestedFileLocation.split(">")
        );
      }
    } else {
      alert("Error al obtener los valores del formato");
    }
    setFetchingFormatValues(false);
  };

  const toggleResults = (type) => {
    if (visibleResults[type] === searchResultsPerType) {
      setVisibleResults({
        ...visibleResults,
        [type]: searchResults[type].length,
      });
    } else {
      setVisibleResults({
        ...visibleResults,
        [type]: searchResultsPerType,
      });
    }
  };

  const handleDocumentSelect = (documentId) => {
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(documentId)) {
        return prevSelected.filter((id) => id !== documentId);
      } else {
        return [...prevSelected, documentId];
      }
    });
  };

  const handleMoveDocuments = () => {
    setShowSelectFolderModal(true);
  };

  const handleSelectFolder = (folder) => {
    setTargetFolder(folder);
    setShowSelectFolderModal(false);
    // Implement logic to move selected documents to the target folder
  };

  const handleSelectDocument = (document) => {
    setSelectedDocument(document);
    setShowDocumentMasterModal(true);
  };

  // Función auxiliar para determinar qué icono mostrar según el tipo de carpeta
  const getIconForFolder = (folder) => {
    // Puedes personalizar esta lógica según tus necesidades
    const iconName = folder.icon?.toLowerCase() || "";

    switch (iconName) {
      case "star":
        return <Star className="folder-icon" />;
      case "briefcase":
        return <Briefcase className="folder-icon" />;
      case "building":
        return <Building className="folder-icon" />;
      case "users":
        return <Users className="folder-icon" />;
      case "filecheck":
        return <FileCheck className="folder-icon" />;
      case "clock":
        return <Clock className="folder-icon" />;
      default:
        return <Folder className="folder-icon" />;
    }
  };

  return (
    <div>
      <div className="archive-header-container">
        {selectedFolder && (
          <div className="archive-folder-header">
            <div className="archive-folder-navigation">
              <button
                className="archive-back-button"
                onClick={() => {
                  if (selectedFolder.parentFolderId !== "") {
                    getFolderFromId(selectedFolder.parentFolderId);
                  } else {
                    setSelectedFolder(null);
                    fetchFolders();
                  }
                  handleCancelSelection();
                  setPage(1);
                }}
              >
                <ChevronLeft size={20} />
              </button>
            </div>

            {selectedFolder.logoUrl ? (
              <div className="archive-folder-title-with-logo">
                <img
                  alt="logo de la carpeta"
                  src={selectedFolder.logoUrl}
                  className="employee-folder-logo-mini"
                />
                <h2 className="employee-files-subtitle">
                  {selectedFolder.name}
                </h2>
              </div>
            ) : (
              <div className="archive-folder-title">
                <span className="archive-folder-icon">
                  {getIconForFolder(selectedFolder)}
                </span>
                <h2 className="employee-files-subtitle">
                  {selectedFolder.name}
                </h2>
              </div>
            )}
          </div>
        )}

        <div className="archive-actions-wrapper">
          <div className="archive-search-container">
            <Search className="archive-search-icon" />
            <input
              type="text"
              placeholder="Buscar archivos o carpetas..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="archive-search-bar"
            />
            {searching && (
              <div className="archive-search-spinner">
                <Spinner size={"sm"} />
              </div>
            )}

            {searchTerm.length > 0 && (
              <button
                className="archive-search-clear-button"
                onClick={() => setSearchTerm("")}
                aria-label="Limpiar búsqueda"
              >
                <X size={16} />
              </button>
            )}

            {/* Resultados de búsqueda */}
            {!searching && searchTerm.length > 0 && (
              <div className="search-results-dropdown">
                {/* Documentos por nombre */}
                {searchResults.documentsByName.length > 0 && (
                  <div className="search-results-section">
                    <h3 className="search-results-title">
                      Documentos por nombre
                    </h3>
                    <div className="search-results-list">
                      {searchResults.documentsByName
                        .slice(0, visibleResults.documentsByName)
                        .map((document, index) => {
                          let icon = documentIcon;
                          if (document && document.type) {
                            if (document.type.includes("pdf")) {
                              icon = pdfIcon;
                            } else if (document.type.includes("image")) {
                              icon = imageIcon;
                            } else if (
                              document.type.includes("excel") ||
                              document.type.includes("sheet")
                            ) {
                              icon = excelIcon;
                            }
                          }
                          return (
                            <div
                              key={index}
                              className="search-result-item"
                              onClick={() => {
                                handleSelectDocument(document);
                              }}
                            >
                              <img
                                alt="icono del documento"
                                src={icon}
                                className="search-result-icon"
                              />
                              <div className="search-result-name">
                                {document.name
                                  .split(new RegExp(`(${searchTerm})`, "gi"))
                                  .map((part, index) =>
                                    part.toLowerCase() ===
                                    searchTerm.toLowerCase() ? (
                                      <span
                                        key={index}
                                        className="search-highlight"
                                      >
                                        {part}
                                      </span>
                                    ) : (
                                      <span key={index}>{part}</span>
                                    )
                                  )}
                              </div>
                              {document.embedded && (
                                <LightbulbIcon className="document-embedded-icon" />
                              )}
                            </div>
                          );
                        })}
                    </div>
                    {searchResults.documentsByName.length >
                      searchResultsPerType && (
                      <button
                        className="search-results-toggle"
                        onClick={() => toggleResults("documentsByName")}
                      >
                        {visibleResults.documentsByName ===
                        searchResultsPerType ? (
                          <>
                            Ver más <ChevronDown size={14} />
                          </>
                        ) : (
                          <>
                            Ver menos <ChevronUp size={14} />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                )}

                {/* Documentos por indexación */}
                {searchResults.documentsByIndexation.length > 0 && (
                  <div className="search-results-section">
                    <h3 className="search-results-title">
                      Documentos por contenido
                    </h3>
                    <div className="search-results-list">
                      {searchResults.documentsByIndexation
                        .slice(0, visibleResults.documentsByIndexation)
                        .map((document, index) => {
                          let icon = documentIcon;
                          if (document && document.type) {
                            if (document.type.includes("pdf")) {
                              icon = pdfIcon;
                            } else if (document.type.includes("image")) {
                              icon = imageIcon;
                            } else if (
                              document.type.includes("excel") ||
                              document.type.includes("sheet")
                            ) {
                              icon = excelIcon;
                            }
                          }
                          return (
                            <div
                              key={index}
                              className="search-result-item"
                              onClick={() => {
                                handleSelectDocument(document);
                              }}
                            >
                              <img
                                alt="icono del documento"
                                src={icon}
                                className="search-result-icon"
                              />
                              <div className="search-result-name">
                                {document.name}
                              </div>
                              {document.embedded && (
                                <LightbulbIcon className="document-embedded-icon" />
                              )}
                            </div>
                          );
                        })}
                    </div>
                    {searchResults.documentsByIndexation.length >
                      searchResultsPerType && (
                      <button
                        className="search-results-toggle"
                        onClick={() => toggleResults("documentsByIndexation")}
                      >
                        {visibleResults.documentsByIndexation ===
                        searchResultsPerType ? (
                          <>
                            Ver más <ChevronDown size={14} />
                          </>
                        ) : (
                          <>
                            Ver menos <ChevronUp size={14} />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                )}

                {/* Carpetas */}
                {searchResults.folders.length > 0 && (
                  <div className="search-results-section">
                    <h3 className="search-results-title">Carpetas</h3>
                    <div className="search-results-list">
                      {searchResults.folders
                        .slice(0, visibleResults.folders)
                        .map((folder, index) => {
                          return (
                            <div
                              key={index}
                              className="search-result-item"
                              onClick={() => {
                                getFolderFromId(folder._id);
                                closeSearchResults();
                              }}
                            >
                              <Folder className="search-result-icon" />
                              <div className="search-result-name">
                                {folder.folderPath
                                  .split(new RegExp(`(${searchTerm})`, "gi"))
                                  .map((part, index) =>
                                    part.toLowerCase() ===
                                    searchTerm.toLowerCase() ? (
                                      <span
                                        key={index}
                                        className="search-highlight"
                                      >
                                        {part}
                                      </span>
                                    ) : (
                                      <span key={index}>{part}</span>
                                    )
                                  )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {searchResults.folders.length > searchResultsPerType && (
                      <button
                        className="search-results-toggle"
                        onClick={() => toggleResults("folders")}
                      >
                        {visibleResults.folders === searchResultsPerType ? (
                          <>
                            Ver más <ChevronDown size={14} />
                          </>
                        ) : (
                          <>
                            Ver menos <ChevronUp size={14} />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                )}

                {/* Mensaje de no resultados */}
                {searchResults.documentsByName.length +
                  searchResults.documentsByIndexation.length +
                  searchResults.folders.length ===
                  0 && (
                  <div className="no-results-message">
                    No se encontraron resultados
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="order-folders-container">
            <label>Ordenar carpetas:</label>
            <select
              onChange={(e) => {
                const sortOrder = e.target.value;
                if (selectedFolder) {
                  const sortedSubFolders = [...selectedFolder.subFolders].sort(
                    (a, b) => {
                      if (sortOrder === "asc") {
                        return a.name.localeCompare(b.name);
                      } else {
                        return b.name.localeCompare(a.name);
                      }
                    }
                  );
                  let selectedFolderCopy = { ...selectedFolder };
                  selectedFolderCopy.subFolders = sortedSubFolders;
                  setSelectedFolder(selectedFolderCopy);
                } else {
                  const sortedFolders = [...folders].sort((a, b) => {
                    if (sortOrder === "asc") {
                      return a.name.localeCompare(b.name);
                    } else {
                      return b.name.localeCompare(a.name);
                    }
                  });
                  setFolders(sortedFolders);
                }
              }}
              className="order-folders-select-input"
            >
              <option value="asc">A-Z</option>
              <option value="desc">Z-A</option>
            </select>
          </div>

          {checkMantisBusinessUserPermission(props.user, "CREATE_FOLDERS") && (
            <button
              className="create-folder-button"
              onClick={() => {
                setShowCreateFolderModal(true);
              }}
            >
              <i className="fas fa-folder-plus create-folder-icon"></i>
              Crear carpeta
            </button>
          )}
        </div>
      </div>
      <Col xs={8}>
        {selectingDocuments && (
          <div className="document-selection-actions">
            <Button
              className="archive-action-button select-all"
              onClick={() => {
                if (
                  selectedDocuments.length === selectedFolder.documents.length
                ) {
                  setSelectedDocuments([]); // Deselect all if all are selected
                } else {
                  setSelectedDocuments(
                    selectedFolder.documents.map((doc) => doc._id)
                  ); // Select all
                }
              }}
            >
              <i className="fas fa-check" />
              {selectedDocuments.length === selectedFolder.documents.length
                ? "Deseleccionar Todos"
                : "Seleccionar Todos"}
            </Button>
            <Button
              className="archive-action-button cancel"
              onClick={() => {
                handleCancelSelection();
              }}
            >
              <i className="fas fa-times" />
              Cancelar selección
            </Button>

            <Button
              className="archive-action-button move"
              onClick={handleMoveDocuments}
            >
              <i className="fas fa-folder-open" />
              Mover documentos
            </Button>
          </div>
        )}
      </Col>
      <Row>
        {loading && (
          <Col xs={12}>
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </Col>
        )}
        {selectedFolder ? (
          <>
            {selectedFolder.subFolders.length +
              selectedFolder.documents.length ===
              0 &&
              !loading && (
                <h2 className="employee-files-no-content-message text-center">
                  ¡Aún no hay nada en esta carpeta!
                </h2>
              )}
            <Row
              style={{
                marginTop: "20px",
              }}
            >
              {selectedFolder.subFolders.map((subfolder, index) => {
                return (
                  <Col key={index} xs={2}>
                    <div
                      className="employee-folder-card"
                      onClick={() => {
                        getFolderFromId(subfolder._id);
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setClicked(true);
                        setPoints({ x: e.clientX, y: e.clientY });
                        setElementRightClicked(subfolder);
                        setElementRightClickedType("FOLDER");
                      }}
                    >
                      {subfolder.logoUrl ? (
                        <img
                          alt="logo de la carpeta"
                          src={subfolder.logoUrl}
                          className="employee-folder-logo"
                        />
                      ) : (
                        <div className="employee-folder-icon">
                          {getIconForFolder(subfolder)}
                        </div>
                      )}
                      <h3 className="employee-folder-name">{subfolder.name}</h3>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row>
              {selectedFolder.documents.map((document, index) => {
                let icon = documentIcon;

                if (document && document.type) {
                  if (document.type.includes("pdf")) {
                    icon = pdfIcon;
                  } else if (document.type.includes("image")) {
                    icon = imageIcon;
                  } else if (
                    document.type.includes("excel") ||
                    document.type.includes("sheet")
                  ) {
                    icon = excelIcon;
                  }
                } else {
                  console.warn(
                    `Documento con extension desconocida: ${JSON.stringify(
                      document
                    )}`
                  );
                }

                return (
                  <Col key={index} xs={4}>
                    <div
                      className="employee-document-card"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setClicked(true);
                        setPoints({ x: e.clientX, y: e.clientY });
                        setElementRightClicked(document);
                        setElementRightClickedType("DOCUMENT");
                      }}
                      onClick={() => {
                        handleSelectDocument(document);
                      }}
                    >
                      <img
                        alt="icono del documento"
                        src={icon}
                        className="employee-document-icon"
                      />

                      <div
                        className="document-name-container"
                        style={{
                          width: "80%",
                          textAlign: "left",
                        }}
                      >
                        <h3
                          className="employee-document-name"
                          id={"UncontrolledTooltipDocument" + index}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            transition: "all 0.3s ease",
                          }}
                          onMouseEnter={(e) => {
                            if (e.target.offsetWidth < e.target.scrollWidth) {
                              e.target.style.overflow = "auto";
                              e.target.style.textOverflow = "clip";
                            }
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.overflow = "hidden";
                            e.target.style.textOverflow = "ellipsis";
                          }}
                        >
                          {document.name}
                        </h3>
                        <UncontrolledTooltip
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            fontFamily: "Poppins, sans-serif",
                          }}
                          placement="top"
                          target={"UncontrolledTooltipDocument" + index}
                        >
                          {document.name}
                        </UncontrolledTooltip>
                      </div>
                      {selectingDocuments && (
                        <div
                          style={{
                            width: "20%",
                            overflow: "hidden",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedDocuments.includes(document._id)}
                            onClick={(e) => e.stopPropagation()} // Prevent the click from bubbling up to the parent div
                            onChange={() => handleDocumentSelect(document._id)} // Handle selection
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      )}
                      {!selectingDocuments && (
                        <div
                          style={{
                            width: "20%",
                            overflow: "hidden",
                          }}
                        >
                          {document.embedded && (
                            <>
                              <LightbulbIcon
                                id={"UncontrolledTooltipExample-" + index}
                                className="document-embedded-icon"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={"UncontrolledTooltipExample-" + index}
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                ¡Puedes consultar info de este documento!
                              </UncontrolledTooltip>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
            {selectedFolder.documents.length > 0 && (
              <Row>
                <Col>
                  <div className="archive-pagination-container">
                    {page > 1 && (
                      <div
                        className="archive-pagination-button"
                        onClick={() => {
                          handleCancelSelection();
                          setPage(page - 1);
                        }}
                      >
                        <i className="fa fa-chevron-left"></i>
                      </div>
                    )}
                    <div className="archive-pagination-page-number-container">
                      <span className="archive-pagination-page-number">
                        {page}
                      </span>
                    </div>
                    {selectedFolder &&
                      selectedFolder.documents.length === resultsPerPage && (
                        <div
                          className="archive-pagination-button"
                          onClick={() => {
                            handleCancelSelection();
                            setPage(page + 1);
                          }}
                        >
                          <i className="fa fa-chevron-right"></i>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <>
            {folders.length === 0 && !loading && (
              <>
                <h2
                  style={{
                    margin: "auto",
                    fontSize: "1.5em",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  ¡Aún no hay nada por aquí!
                </h2>
              </>
            )}
            {folders.map((folder, index) => {
              return (
                <Col key={index} xs={2}>
                  <div
                    className="employee-folder-card"
                    onClick={() => {
                      getFolderFromId(folder._id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClicked(true);
                      setPoints({ x: e.clientX, y: e.clientY });
                      setElementRightClicked(folder);
                      setElementRightClickedType("FOLDER");
                    }}
                  >
                    {folder.logoUrl ? (
                      <img
                        alt="logo de la carpeta"
                        src={folder.logoUrl}
                        className="employee-folder-logo"
                      />
                    ) : (
                      <div className="employee-folder-icon">
                        {getIconForFolder(folder)}
                      </div>
                    )}
                    <h3 className="employee-folder-name">
                      {shortenText(folder.name, 26)}
                    </h3>
                  </div>
                </Col>
              );
            })}
          </>
        )}
      </Row>
      {selectedFolder &&
        checkMantisBusinessUserPermission(props.user, "UPLOAD_FILES") && (
          <div
            onClick={() => {
              handleCancelSelection();
              setShowUploadFileModal(true);
            }}
            className="employee-add-files-button"
          >
            Arrastra o sube archivos
          </div>
        )}
      <Modal
        isOpen={showCreateFolderModal}
        toggle={() => {
          closeCreateFolderModal();
        }}
        className="create-folder-modal"
      >
        <ModalHeader
          toggle={() => {
            closeCreateFolderModal();
          }}
          className="modal-header"
        >
          Nueva carpeta
        </ModalHeader>
        <ModalBody className="modal-body">
          <Form onSubmit={createNewFolder}>
            <FormGroup>
              <Label
                for="folderIcon"
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Icono de la carpeta
              </Label>
              {newFolderState.icon ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <h3>{newFolderState.icon}</h3>
                  <Button
                    style={{
                      backgroundColor: "#282828",
                      color: "white",
                      marginLeft: "10px",
                      height: "30px",
                      fontFamily: "Poppins, sans-serif",
                      padding: 0,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    onClick={() => {
                      setNewFolderState({
                        ...newFolderState,
                        icon: "",
                      });
                    }}
                  >
                    Cambiar
                  </Button>
                </div>
              ) : (
                <EmojiPicker
                  emojiStyle="native"
                  open={showingEmojiPicker}
                  searchPlaceholder="Icono de la carpeta"
                  reactionsDefaultOpen={true}
                  reactions={[
                    "1f4c1",
                    "1f4ec",
                    "1f4ca",
                    "1f5c4-fe0f",
                    "1f4dd",
                    "1f4b0",
                    "1f468-200d-1f4bb",
                    "2699-fe0f",
                  ]}
                  onEmojiClick={(emojiObject) => {
                    handleEmojiClick(emojiObject);
                  }}
                />
              )}
            </FormGroup>
            <FormGroup>
              <Label
                for="folderName"
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Nombre de la carpeta
              </Label>
              <Input
                type="text"
                name="folderName"
                id="folderName"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 300,
                }}
                value={newFolderState.name}
                onChange={(e) => {
                  setNewFolderState({
                    ...newFolderState,
                    name: e.target.value,
                  });
                }}
              />
            </FormGroup>
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button type="submit" className="archive-button primary">
                  {creatingNewFolder ? <Spinner size={"sm"} /> : "Crear"}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showEditElementModal}
        toggle={() => {
          if (!editingElement) {
            closeUpdateElementModal();
          }
        }}
        className="create-folder-modal"
      >
        <ModalHeader
          toggle={() => {
            if (!editingElement) {
              closeUpdateElementModal();
            }
          }}
          style={{
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {elementRightClickedType === "FOLDER" && "Editar carpeta"}
          {elementRightClickedType === "DOCUMENT" && "Editar documento"}
        </ModalHeader>
        <ModalBody>
          {elementRightClickedType === "FOLDER" && (
            <Form onSubmit={editFolder}>
              <FormGroup>
                <Label
                  for="folderIcon"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Icono de la carpeta
                </Label>
                {elementRightClicked.icon ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <h3>{elementRightClicked.icon}</h3>
                    <Button
                      style={{
                        backgroundColor: "#282828",
                        color: "white",
                        marginLeft: "10px",
                        height: "30px",
                        fontFamily: "Poppins, sans-serif",
                        padding: 0,
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                      onClick={() => {
                        setElementRightClicked({
                          ...elementRightClicked,
                          icon: "",
                        });
                      }}
                    >
                      Cambiar
                    </Button>
                  </div>
                ) : (
                  <EmojiPicker
                    emojiStyle="native"
                    open={showingEmojiPicker}
                    searchPlaceholder="Icono de la carpeta"
                    reactionsDefaultOpen={true}
                    reactions={[
                      "1f4c1",
                      "1f4ec",
                      "1f4ca",
                      "1f5c4-fe0f",
                      "1f4dd",
                      "1f4b0",
                      "1f468-200d-1f4bb",
                      "2699-fe0f",
                    ]}
                    onEmojiClick={(emojiObject) => {
                      setElementRightClicked({
                        ...elementRightClicked,
                        icon: emojiObject.emoji,
                      });
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="folderName"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Nombre de la carpeta
                </Label>
                <Input
                  type="text"
                  name="folderName"
                  id="folderName"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 300,
                  }}
                  value={elementRightClicked.name}
                  onChange={(e) => {
                    setElementRightClicked({
                      ...elementRightClicked,
                      name: e.target.value,
                    });
                  }}
                />
              </FormGroup>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button type="submit" className="archive-button primary">
                    {editingElement ? <Spinner size={"sm"} /> : "Guardar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          {elementRightClickedType === "DOCUMENT" && (
            <Form onSubmit={editDocument}>
              <FormGroup>
                <Label
                  for="documentName"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Nombre del documento
                </Label>
                <Input
                  type="text"
                  name="documentName"
                  id="documentName"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 300,
                  }}
                  value={elementRightClicked.name}
                  onChange={(e) => {
                    setElementRightClicked({
                      ...elementRightClicked,
                      name: e.target.value,
                    });
                  }}
                />
              </FormGroup>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button type="submit" className="archive-button primary">
                    {editingElement ? <Spinner size={"sm"} /> : "Guardar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showDeleteElementModal}
        toggle={() => {
          if (!deletingElement) {
            closeDeleteElementModal();
          }
        }}
        className="create-folder-modal"
      >
        <ModalHeader
          toggle={() => {
            if (!deletingElement) {
              closeDeleteElementModal();
            }
          }}
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "14px",
          }}
        >
          {elementRightClickedType === "FOLDER" &&
            `¿Estás seguro de que quieres eliminar la carpeta ${elementRightClicked.name}?`}
          {elementRightClickedType === "DOCUMENT" &&
            `¿Estás seguro de que quieres eliminar el documento ${elementRightClicked.name}?`}
        </ModalHeader>
        <ModalBody>
          {elementRightClickedType === "FOLDER" && (
            <Form onSubmit={handleDeleteFolder}>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <i
                    className="fas fa-exclamation-triangle"
                    style={{
                      color: "red",
                      fontSize: "2em",
                    }}
                  ></i>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    Todo el contenido incluyendo subcarpetas y documentos se
                    perderá. Esta acción no se puede deshacer.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button type="submit" className="archive-button primary">
                    {deletingElement ? <Spinner size={"sm"} /> : "Eliminar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          {elementRightClickedType === "DOCUMENT" && (
            <Form onSubmit={handleDeleteDocument}>
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button type="submit" className="archive-button primary">
                    {deletingElement ? <Spinner size={"sm"} /> : "Eliminar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showUploadFileModal}
        toggle={() => {
          closeUploadFileModal();
        }}
        className="create-folder-modal"
      >
        <ModalHeader
          toggle={() => {
            closeUploadFileModal();
          }}
          style={{
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Subir archivos
        </ModalHeader>
        <ModalBody>
          <Row>
            {!uploadingDocuments ? (
              <>
                <Col
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FilesDragAndDrop
                    onUpload={onUpload}
                    count={maxFilesToUpload}
                    formats={[
                      ".pdf",
                      ".jpeg",
                      ".jpg",
                      ".png",
                      ".xls",
                      ".xlsx",
                      ".zip",
                    ]}
                  >
                    <div className="FilesDragAndDrop__area">
                      Arrastra los archivos aquí
                    </div>
                  </FilesDragAndDrop>
                </Col>
                <Col
                  xs={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Input
                    type="file"
                    name="file"
                    id="fileUpload"
                    style={{
                      display: "none",
                    }}
                    multiple
                    onChange={(e) => {
                      const allowedFormats = [
                        ".pdf",
                        ".jpeg",
                        ".jpg",
                        ".png",
                        ".xls",
                        ".xlsx",
                        ".zip",
                      ];
                      const files = Array.from(e.target.files);
                      const invalidFiles = files.filter(
                        (file) =>
                          !allowedFormats.includes(
                            file.name
                              .substring(file.name.lastIndexOf("."))
                              .toLowerCase()
                          )
                      );

                      if (invalidFiles.length > 0) {
                        setErrorMessage(
                          `Error: El formato de archivo ${invalidFiles
                            .map((f) => f.name)
                            .join(", ")} no es permitido. Intente de nuevo  `
                        );
                      } else {
                        setErrorMessage("");
                        onUpload(files);
                      }
                    }}
                    accept=".pdf, .jpeg, .jpg, .png, .xls, .xlsx, .zip"
                  />
                  <label htmlFor="fileUpload">
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        padding: "50px",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        flexFlow: "column nowrap",
                        fontSize: "16px",
                        color: "#555555",
                        border: "2px #c3c3c3 dashed",
                        borderRadius: "12px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      o selecciona archivos
                    </div>
                  </label>
                </Col>
              </>
            ) : (
              <Col
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner size={"md"} />
                <h4
                  style={{
                    marginLeft: "10px",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                  }}
                >
                  Subiendo {uploadedFiles.length} de {filesToUpload.length}{" "}
                  archivos...
                </h4>
              </Col>
            )}
          </Row>
          {errorMessage && (
            <Row>
              <Col
                xs={12}
                style={{ textAlign: "center", color: "red", marginTop: "10px" }}
              >
                {errorMessage}
              </Col>
            </Row>
          )}
          {filesToUpload.length > 0 && (
            <>
              <Row>
                <Col xs={12}>
                  <h3 className="files-to-upload-subtitle">
                    Archivos a subir:
                  </h3>
                  <ul>
                    {filesToUpload.map((file, index) => {
                      return <li key={index}>{file.name}</li>;
                    })}
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={filesToUpload.length === 1 ? 6 : 12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className="archive-button primary"
                    onClick={handleUploadFiles}
                  >
                    {uploadingDocuments ? <Spinner size={"sm"} /> : "Subir"}
                  </Button>
                </Col>
                {filesToUpload.length === 1 && !uploadingDocuments && (
                  <Col
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="archive-button primary"
                      onClick={() => {
                        setShowAssignFormatModal(true);
                        setShowUploadFileModal(false);
                      }}
                    >
                      Asignar formato
                    </button>
                  </Col>
                )}
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>

      {clicked && (
        <ArchiveContextMenu
          top={points.y}
          left={points.x}
          handleNameChange={handleNameChange}
          handleDeleteElement={handleDeleteElement}
          elementType={elementRightClickedType}
          handleSelectElement={handleSelectElement}
        />
      )}

      <Modal
        isOpen={showAssignFormatModal}
        toggle={() => closeAssignFormatModal()}
        className="assign-format-modal"
        size="xl"
      >
        <ModalHeader
          toggle={() => closeAssignFormatModal()}
          className="modal-header"
        >
          <span className="modal-title">Asignar Formato</span>
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row>
            <Col xs={8} className="file-preview">
              {filesToUpload.length > 0 && (
                <>
                  {filesToUpload[0].type.includes("pdf") ? (
                    <iframe
                      src={pdfPreviewUrl}
                      id="pdf-preview"
                      title="PDF Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: "400px",
                      }}
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(filesToUpload[0])}
                      alt="File Preview"
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                </>
              )}
            </Col>
            {formatValues.length === 0 ? (
              <Col xs={4}>
                <Form>
                  <FormGroup>
                    <Label for="documentFormat" className="modal-label">
                      Seleccionar Formato
                    </Label>
                    {loadingDocumentFormats ? (
                      <Spinner color="primary" />
                    ) : (
                      <Input
                        type="select"
                        name="documentFormat"
                        id="documentFormat"
                        value={selectedDocumentFormat}
                        onChange={(e) =>
                          setSelectedDocumentFormat(e.target.value)
                        }
                        className="modal-input"
                      >
                        <option value="">Seleccione un formato</option>
                        {documentFormats.map((format) => (
                          <option key={format._id} value={format._id}>
                            {format.name}
                          </option>
                        ))}
                      </Input>
                    )}
                  </FormGroup>
                  <Button
                    className="modal-button primary"
                    onClick={handleGetFormatValues}
                    disabled={!selectedDocumentFormat || fetchingFormatValues}
                  >
                    {fetchingFormatValues ? (
                      <Spinner size={"sm"} />
                    ) : (
                      "Obtener valores"
                    )}
                  </Button>
                </Form>
              </Col>
            ) : (
              <Col xs={4}>
                <>
                  <h5 className="modal-subtitle">Nombre sugerido</h5>
                  <Col xs={12} className="mb-3">
                    <FormGroup check>
                      <Label check className="modal-label">
                        <Input
                          type="checkbox"
                          checked={useSuggestedFilename}
                          onChange={(e) =>
                            setUseSuggestedFilename(e.target.checked)
                          }
                          className="modal-checkbox"
                        />
                        Usar nombre sugerido
                      </Label>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        value={suggestedFilename}
                        onChange={(e) => {
                          setSuggestedFilename(e.target.value);
                        }}
                        className="modal-input mt-2"
                      />
                      {useSuggestedFilename && !suggestedFilename && (
                        <div className="modal-error">
                          Debes ingresar un nombre para el archivo
                        </div>
                      )}
                    </FormGroup>
                    <hr className="modal-divider" />
                  </Col>
                </>

                {suggestedFileLocation.length > 0 && (
                  <>
                    <h5 className="modal-subtitle">Ubicación sugerida</h5>
                    <Col xs={12} className="mb-3">
                      <FormGroup check>
                        <Label check className="modal-label">
                          <Input
                            type="checkbox"
                            checked={useSuggestedFileLocation}
                            onChange={(e) =>
                              setUseSuggestedFileLocation(e.target.checked)
                            }
                            className="modal-checkbox"
                          />
                          Usar ubicación sugerida
                        </Label>
                      </FormGroup>
                      {suggestedFileLocation.map((location, index) => (
                        <div key={index}>
                          <div className="location-item">
                            <span className="location-icon">📁</span>
                            <Input
                              type="text"
                              value={location}
                              onChange={(e) => {
                                const updatedLocations =
                                  suggestedFileLocation.map((loc, locIndex) => {
                                    if (locIndex === index) {
                                      return e.target.value;
                                    }
                                    return loc;
                                  });
                                setSuggestedFileLocation(updatedLocations);
                              }}
                              className="location-input"
                            />
                            <Button
                              color="danger"
                              size="sm"
                              className="location-delete-btn"
                              onClick={() => {
                                const updatedLocations =
                                  suggestedFileLocation.filter(
                                    (loc, locIndex) => locIndex !== index
                                  );
                                setSuggestedFileLocation(updatedLocations);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </div>

                          <div className="location-separator">
                            <i className="fas fa-chevron-down"></i>
                          </div>
                        </div>
                      ))}
                      <div className="location-add-container">
                        <Button
                          className="location-add-btn"
                          size="sm"
                          onClick={() => {
                            setSuggestedFileLocation([
                              ...suggestedFileLocation,
                              "Nueva carpeta",
                            ]);
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </div>
                      <hr className="modal-divider" />
                    </Col>
                  </>
                )}

                <h5 className="modal-subtitle">
                  Valores extraídos del formato
                </h5>
                <div className="format-values-container">
                  <Row>
                    {formatValues.map((value, index) => (
                      <Col xs={6} key={index}>
                        <FormGroup>
                          <Row>
                            <Col xs={12}>
                              <Input
                                type="text"
                                defaultValue={value.name}
                                style={{
                                  color: "white",
                                  display: "inline-block",
                                  marginRight: "10px",
                                  border: "none",
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  height: "auto",
                                  overflow: "visible",
                                }}
                                value={value.name}
                                onChange={(e) => {
                                  let updatedValues = formatValues.map(
                                    (item) => {
                                      if (item.name === value.name) {
                                        return {
                                          ...item,
                                          name: e.target.value,
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                  setFormatValues(updatedValues);
                                }}
                              />
                              <Button
                                style={{
                                  backgroundColor: "#282828",
                                  color: "white",
                                  width: "28px",
                                  height: "28px",
                                  display: "inline-block",
                                  padding: 0,
                                }}
                                id={
                                  "UncontrolledTooltipFormatFieldDelete-" +
                                  index
                                }
                                onClick={() => {
                                  let formatValuesCopy = [...formatValues];
                                  formatValuesCopy.splice(index, 1);
                                  setFormatValues(formatValuesCopy);
                                }}
                              >
                                <i
                                  className="fas fa-trash"
                                  style={{
                                    fontSize: "10px",
                                    color: "red",
                                  }}
                                ></i>
                                <UncontrolledTooltip
                                  style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                  placement="top"
                                  target={
                                    "UncontrolledTooltipFormatFieldDelete-" +
                                    index
                                  }
                                >
                                  Eliminar campo
                                </UncontrolledTooltip>
                              </Button>
                              <Button
                                style={{
                                  backgroundColor: "#282828",
                                  color: "white",
                                  width: "28px",
                                  height: "28px",
                                  display: "inline-block",
                                  padding: 0,
                                  marginLeft: "10px",
                                }}
                                id={
                                  "UncontrolledTooltipFormatFieldAdd-" + index
                                }
                                onClick={() => {
                                  let formatValuesCopy = [...formatValues];
                                  formatValuesCopy.splice(index + 1, 0, {
                                    name: "Nuevo Valor",
                                    value: "",
                                  });
                                  setFormatValues(formatValuesCopy);
                                }}
                              >
                                <i
                                  className="fas fa-plus"
                                  style={{
                                    fontSize: "10px",
                                    color: "white",
                                  }}
                                ></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={
                                    "UncontrolledTooltipFormatFieldAdd-" + index
                                  }
                                  style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  Agregar campo
                                </UncontrolledTooltip>
                              </Button>
                            </Col>
                          </Row>
                          <Input
                            type="text"
                            name={value.name}
                            id={value.name}
                            value={value.value}
                            onChange={(e) => {
                              let formatValuesCopy = [...formatValues];
                              formatValuesCopy[index].value = e.target.value;
                              setFormatValues(formatValuesCopy);
                            }}
                            style={{
                              marginTop: "5px",
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Row className="w-100 justify-content-end">
            <Button
              disabled={fetchingFormatValues || uploadingDocuments}
              onClick={() => {
                setShowAssignFormatModal(false);
                setSelectedDocumentFormat(null);
                setShowUploadFileModal(true);
              }}
              className="modal-button secondary"
            >
              Volver
            </Button>
            <Button
              disabled={
                fetchingFormatValues ||
                uploadingDocuments ||
                (useSuggestedFilename && !suggestedFilename)
              }
              onClick={handleUploadFiles}
              className="modal-button primary ml-2"
            >
              {uploadingDocuments ? <Spinner size={"sm"} /> : "Subir"}
            </Button>
          </Row>
        </ModalFooter>
      </Modal>

      <SelectFolderModal
        isOpen={showSelectFolderModal}
        toggle={() => setShowSelectFolderModal(false)}
        folders={folders} // Pass the existing folders
        onSelect={() => {
          setShowSelectFolderModal(false);
          setSelectingDocuments(false);
          getFolderFromId(selectedFolder._id);
        }}
        token={props.token}
        mantisBusinessId={props.mantisBusiness._id}
        originFolder={selectedFolder}
        documentsToMove={selectedDocuments}
      />

      {selectedDocument && (
        <DocumentMasterModal
          isOpen={showDocumentMasterModal}
          toggle={() => {
            setShowDocumentMasterModal(false);
            setSelectedDocument(null);
          }}
          document={selectedDocument}
          token={props.token}
          mantisBusinessId={props.mantisBusiness._id}
          originalFormatName={
            documentFormats.find(
              (format) => format._id === selectedDocument.documentFormatId
            )?.name
          }
          handleUpdate={(updatedDocument) => {
            let updatedDocuments = selectedFolder.documents.map((doc) => {
              if (doc._id === updatedDocument._id) {
                return updatedDocument;
              }
              return doc;
            });
            setSelectedFolder({
              ...selectedFolder,
              documents: updatedDocuments,
            });
          }}
          documentFormats={documentFormats}
          handleGetFormatValues={handleGetFormatValues}
        />
      )}
    </div>
  );
}

export default connect(select)(BusinessArchive);
