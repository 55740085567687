"use client"

import { useState, useEffect } from "react"

const ChatMessage = ({ message }) => {
  const isUser = message.sender === "user"
  const [displayText, setDisplayText] = useState("")
  const [isTyping, setIsTyping] = useState(!isUser)
  const fullText = message.content
  const typingSpeed = 30 // milisegundos por caracter

  useEffect(() => {
    // Solo aplicar efecto de escritura a mensajes de la IA
    if (isUser) {
      setDisplayText(fullText)
      return
    }

    const currentIndex = 0
    setDisplayText("")
    setIsTyping(true)

    // Función para simular escritura palabra por palabra
    const typeNextWord = () => {
      const words = fullText.split(" ")

      const typeWord = (wordIndex, charIndex) => {
        if (wordIndex >= words.length) {
          setIsTyping(false)
          return
        }

        const currentWord = words[wordIndex]

        if (charIndex <= currentWord.length) {
          setDisplayText(
            (prev) => prev + (charIndex === 0 ? (wordIndex > 0 ? " " : "") : "") + currentWord.charAt(charIndex - 1),
          )
          setTimeout(() => typeWord(wordIndex, charIndex + 1), typingSpeed)
        } else {
          setTimeout(() => typeWord(wordIndex + 1, 0), typingSpeed * 2)
        }
      }

      typeWord(0, 0)
    }

    // Iniciar la animación después de un pequeño retraso
    const timer = setTimeout(typeNextWord, 500)

    return () => clearTimeout(timer)
  }, [fullText, isUser])

  return (
    <div className={`flex flex-col ${isUser ? "items-end" : "items-start"} mb-3`}>
      <div
        className={`max-w-[80%] p-3 rounded-lg ${
          isUser ? "bg-orange-500 text-white" : "bg-gray-100 text-gray-800"
        } shadow-sm transition-all duration-300 hover:shadow-md`}
      >
        {displayText}
        {isTyping && <span className="inline-block w-1.5 h-4 ml-1 bg-orange-300 animate-pulse"></span>}
      </div>
    </div>
  )
}

export default ChatMessage

