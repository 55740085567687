import React, { useEffect, useState } from "react";
import { Sun, Moon } from "lucide-react";
import "../styles/Navbar.css";

const ThemeToggle = ({ onThemeChange }) => {
  const [isDark, setIsDark] = useState(false);

  const toggleTheme = () => {
    const root = document.documentElement;
    const newIsDark = !root.classList.contains("dark");
    
    if (newIsDark) {
      root.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      root.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
    
    setIsDark(newIsDark);
    
    // Notificar el cambio de tema
    if (onThemeChange) {
      onThemeChange(newIsDark);
    }
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    const isDarkMode = storedTheme === "dark" || 
      (!storedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
    
    setIsDark(isDarkMode);
    document.documentElement.classList.toggle("dark", isDarkMode);
    
    if (onThemeChange) {
      onThemeChange(isDarkMode);
    }
  }, [onThemeChange]);
  
  return (
    <button 
      onClick={toggleTheme}
      className="theme-toggle-button"
      aria-label={isDark ? "Cambiar a modo claro" : "Cambiar a modo oscuro"}
    >
      {isDark ? (
        <Sun className="theme-toggle-icon" />
      ) : (
        <Moon className="theme-toggle-icon" />
      )}
    </button>
  );
};

export default ThemeToggle;
