"use client"

import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { Check, Sparkles, FileCheck, LayoutDashboard } from "lucide-react"
import { Button } from "../../../../components/ui/button"

const SuccessAnimation = ({ flowName, onClose }) => {
  const [showButtons, setShowButtons] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButtons(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="flex flex-col items-center justify-center py-8 px-4">
      <div className="relative mb-6">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="w-24 h-24 bg-gradient-to-br from-orange-400 to-orange-600 rounded-full flex items-center justify-center"
        >
          <Check className="h-12 w-12 text-white" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
          className="absolute -top-2 -right-2"
        >
          <Sparkles className="h-8 w-8 text-yellow-400" />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
          className="absolute -bottom-1 -left-1"
        >
          <Sparkles className="h-6 w-6 text-yellow-400" />
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="text-center"
      >
        <h2 className="text-2xl font-bold text-gray-900 mb-2">¡Flujo guardado con éxito!</h2>
        <div className="flex items-center justify-center gap-2 mb-4">
          <FileCheck className="h-5 w-5 text-orange-500" />
          <p className="text-lg text-gray-600 font-medium">{flowName}</p>
        </div>
        <p className="text-gray-500 max-w-md mx-auto">
          Tu flujo de trabajo ha sido guardado correctamente y está listo para ser utilizado.
        </p>
      </motion.div>

      {showButtons && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mt-8 flex flex-col items-center gap-4"
        >
          <Button className="bg-orange-500 hover:bg-orange-600 text-white px-6" onClick={onClose} size="lg">
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              transition={{
                duration: 0.5,
                repeat: Number.POSITIVE_INFINITY,
                repeatType: "reverse",
              }}
              className="flex items-center gap-2"
            >
              <LayoutDashboard className="h-5 w-5" />
              <span className="font-medium">Ir al Panel de Control</span>
            </motion.div>
          </Button>
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
        className="absolute inset-0 pointer-events-none"
        style={{ zIndex: -1 }}
      >
        {Array.from({ length: 40 }).map((_, i) => (
          <motion.div
            key={i}
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              scale: 0,
              opacity: 0,
            }}
            animate={{
              y: [null, Math.random() * -150 - 50],
              scale: [0, Math.random() * 1.2 + 0.8],
              opacity: [0, 0.9, 0],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              delay: Math.random() * 1.5,
              repeat: Number.POSITIVE_INFINITY,
              repeatDelay: Math.random() * 2,
            }}
            className={`absolute rounded-full ${
              i % 3 === 0 ? "w-4 h-4 bg-orange-400" : i % 3 === 1 ? "w-3 h-3 bg-yellow-400" : "w-5 h-5 bg-orange-300"
            }`}
          />
        ))}
      </motion.div>
    </div>
  )
}

export default SuccessAnimation

