"use client"

import { useState, useEffect } from "react"
import FlowCanvas from "./FlowCanvas"

const FlowVisualization = ({ isReady = false }) => {
  const [visible, setVisible] = useState(false)
  const [flowKey, setFlowKey] = useState(Date.now()) // Clave única para forzar re-renderizado

  useEffect(() => {
    // Animación de entrada
    setTimeout(() => {
      setVisible(true)
    }, 100)

    // Forzar re-renderizado cuando isReady cambia a true
    if (isReady) {
      setFlowKey(Date.now())
    }
  }, [isReady])

  return (
    <div
      className={`h-full bg-white transition-all duration-500 ${visible ? "opacity-100" : "opacity-0"} flex flex-col`}
    >
      <FlowCanvas key={flowKey} isReady={isReady} />
    </div>
  )
}

export default FlowVisualization

