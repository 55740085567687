"use client"

import { useState, useEffect } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../../components/ui/dialog"
import { Button } from "../../../../components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../components/ui/tabs"
import { Label } from "../../../../components/ui/label"
import { Input } from "../../../../components/ui/input"
import { Textarea } from "../../../../components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select"
import { Switch } from "../../../../components/ui/switch"
import { FolderOpen } from "lucide-react"

const StorageNodeModal = ({ isOpen, onClose, nodeData, onSave }) => {
  const [formData, setFormData] = useState({})
  const [activeTab, setActiveTab] = useState("general")

  // Inicializar formData cuando se abre el modal o cambia el nodo
  useEffect(() => {
    if (isOpen && nodeData) {
      setFormData({
        label: nodeData.label || "",
        description: nodeData.description || "",
        ...nodeData.config,
      })
    }
  }, [isOpen, nodeData])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSwitchChange = (name, checked) => {
    setFormData((prev) => ({ ...prev, [name]: checked }))
  }

  const handleSelectChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = () => {
    // Separar los datos básicos de los de configuración
    const { label, description, ...config } = formData
    onSave({ label, description, config })
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <FolderOpen className="h-5 w-5 text-green-600 mr-2" />
            Configuración de Almacenamiento
          </DialogTitle>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid grid-cols-3 mb-4">
            <TabsTrigger value="general">General</TabsTrigger>
            <TabsTrigger value="specific">Específico</TabsTrigger>
            <TabsTrigger value="advanced">Avanzado</TabsTrigger>
          </TabsList>

          {/* Pestaña General */}
          <TabsContent value="general" className="space-y-4">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="label">Nombre</Label>
                <Input id="label" name="label" value={formData.label || ""} onChange={handleInputChange} />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="description">Descripción</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description || ""}
                  onChange={handleInputChange}
                  rows={3}
                />
              </div>
            </div>
          </TabsContent>

          {/* Pestaña Específica para Almacenamiento */}
          <TabsContent value="specific" className="space-y-4">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="storageType">Tipo de Almacenamiento</Label>
                <Select
                  value={formData.storageType || "database"}
                  onValueChange={(value) => handleSelectChange("storageType", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleccionar tipo" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="database">Base de Datos</SelectItem>
                    <SelectItem value="fileSystem">Sistema de Archivos</SelectItem>
                    <SelectItem value="cloud">Nube</SelectItem>
                    <SelectItem value="blockchain">Blockchain</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="retentionPolicy">Política de Retención</Label>
                <Select
                  value={formData.retentionPolicy || "30days"}
                  onValueChange={(value) => handleSelectChange("retentionPolicy", value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Seleccionar política" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="7days">7 días</SelectItem>
                    <SelectItem value="30days">30 días</SelectItem>
                    <SelectItem value="90days">90 días</SelectItem>
                    <SelectItem value="1year">1 año</SelectItem>
                    <SelectItem value="5years">5 años</SelectItem>
                    <SelectItem value="permanent">Permanente</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center justify-between">
                <Label htmlFor="encrypted">Cifrado</Label>
                <Switch
                  id="encrypted"
                  checked={formData.encrypted || false}
                  onCheckedChange={(checked) => handleSwitchChange("encrypted", checked)}
                />
              </div>
            </div>
          </TabsContent>

          {/* Pestaña Avanzada */}
          <TabsContent value="advanced" className="space-y-4">
            <div className="grid gap-4">
              <div className="flex items-center justify-between">
                <Label htmlFor="isRequired">Obligatorio</Label>
                <Switch
                  id="isRequired"
                  checked={formData.isRequired || false}
                  onCheckedChange={(checked) => handleSwitchChange("isRequired", checked)}
                />
              </div>
              <div className="flex items-center justify-between">
                <Label htmlFor="isVisible">Visible</Label>
                <Switch
                  id="isVisible"
                  checked={formData.isVisible !== false} // Por defecto true
                  onCheckedChange={(checked) => handleSwitchChange("isVisible", checked)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="customId">ID Personalizado</Label>
                <Input
                  id="customId"
                  name="customId"
                  value={formData.customId || ""}
                  onChange={handleInputChange}
                  placeholder="Dejar en blanco para ID automático"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="notes">Notas</Label>
                <Textarea
                  id="notes"
                  name="notes"
                  value={formData.notes || ""}
                  onChange={handleInputChange}
                  rows={3}
                  placeholder="Notas adicionales sobre este nodo"
                />
              </div>
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={handleSave}>Guardar Cambios</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default StorageNodeModal

